import React, { useCallback } from 'react';

const Subheader = ({ activeTab, setActiveTab, tabs, containerStyle = {}, tabContainerStyle = {} }) => {

  const tabStyle = { padding: '8px 24px', borderRadius: '.3rem', fontSize: '14px', fontWeight: '600', cursor: 'pointer', transition: 'all 0.2s ease', border: 'none', backgroundColor: 'transparent', ...tabContainerStyle };
  const activeTabStyle = { ...tabStyle, backgroundColor: '#ffffff', color: '#000000', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)' };
  const inactiveTabStyle = { ...tabStyle, color: 'var(--color-text4)' };

  const isStringTabs = typeof Object.values(tabs)[0] === 'string';
  const isObjectTabs = typeof Object.values(tabs)[0] === 'object';

  return (
    <div style={{ display: 'flex', padding: '4px', backgroundColor: '#F4F4F5', borderRadius: '.4rem', width: 'fit-content', marginBottom: '1rem', ...containerStyle }}>
      {Object.entries(tabs).map(([key, tab]) => {
        // If tabs are objects with _id and title
        if (isObjectTabs) {
          return (
            <button
              style={activeTab?._id === tab?._id ? activeTabStyle : inactiveTabStyle}
              key={key}
              onClick={() => setActiveTab(tab)}
            >
              {tab?.title || key}
            </button>
          );
        }
        
        // If tabs are simple strings
        return (
          <button
            style={activeTab === tab ? activeTabStyle : inactiveTabStyle}
            key={key}
            onClick={() => setActiveTab(tab)}
          >
            {tab || key}
          </button>
        );
      })}
    </div>
  );
};


export default Subheader;







// const Subheader = ({ tabs, activeTab, setActiveTab }) => {

//   const Tile = ({ item, index, isActive, }) => {
//     return (
//       <button style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', padding: '0.75rem 0px', textAlign: 'center', cursor: 'pointer', backgroundColor: isActive ? 'var(--color-backgroundSecondary)' : 'transparent', color: isActive ? 'var(--color-primary)' : 'var(--color-text3)', borderRadius: '.5rem', transition: '.3s ease-in-out', fontSize: '1rem' }} 
//         onClick={() => setActiveTab(item)}
//         >{item}
//       </button>
//     )
//   }

//   return (
//     <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'var(--color-background)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', marginBottom: '1rem'}}>
//       { Object.values(tabs).map((item, index) => <Tile key={index} item={item} index={index} isActive={activeTab === item} />) }
//     </div>
//   );  
// }
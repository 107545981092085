import React, { useState, useContext, useEffect, useRef } from 'react';
import { Check, CheckCircle2, Circle, Lock, Sparkles, X, } from 'lucide-react';
import { UserContext } from '../contexts/UserProvider';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { API } from '../misc/constants';
import Chat from '../components/Chat';
import Member1 from '../assets/paywall0.webp';
import Member2 from '../assets/paywall1.webp';
import Member3 from '../assets/paywall2.webp';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const tabs = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
}

const prices = [
  // {
  //   // _id: 'price_1QRFwzLkURBDGvXa0K22m6ns',
  //   _id: "price_1Q7NVpLkURBDGvXaXAm9xT0s",
  //   frequency: tabs.weekly,
  //   basePrice: 3.99,
  //   title: "1 Week",
  //   description: 'Get full access to all features for 1 week'
  // },
  {
    // _id: 'price_1QRFwVLkURBDGvXa2BfNPl1j',
    _id: "price_1Q7NVpLkURBDGvXaXAm9xT0s",
    frequency: tabs.monthly,
    basePrice: 9.99,
    tag: 'Most Popular',
    title: 'High Flexibility',
    description: 'Billed monthly'
  },
  {
    // _id: 'price_1QSXc1LkURBDGvXaAjCE37zW',
    _id: "price_1Q7NVpLkURBDGvXaXAm9xT0s",
    frequency: tabs.yearly,
    basePrice: 99.00,
    tag: 'Best Deal',
    title: 'Top Value',
    description: 'Billed annually at $99'
  }
]

const benefits = [
  "Access to all features",
  "In-class note-taking",
  "File & text uploads",
  "Download your files",
  "Assignment auto-solve",
  "Practice tests",
  "Flashcards & guides",
  "Essay writing help",
  "Essay evaluations",
  "AI detection",
  // "Cancel anytime",
  // "Priority support",
  // "Referral rewards",
  // "Early access to features",
]
// const benefits = [
//   "Full access to all features",
//   "In-class note-taking",
//   "File & text uploads",
//   "Downloadable note files",
//   "Assignment solutions",
//   "Quizzes, flashcards, and more",
//   "Essay writing & evaluations",
// ]


const CheckoutForm = ({ handleClose }) => {
  const { isMobileAndBelow, isTabletAndBelow } = useBreakpoint();
  const stripe = useStripe();
  const elements = useElements();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ message, setMessage ] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000/app/checkout-complete",
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") { // this will only be triggered if there is an immediate error when confirming the payment, otherwise the user will be redirected to the return_url
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <>

      { !isMobileAndBelow && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '.5rem', marginBottom: '1rem', }}>
          <Chat styleOverride={{ position: "relative", backgroundColor: "transparent", border: "none", boxShadow: "none", color: "var(--color-text3)", fontWeight: "500", bottom: null, right: null, padding: '.25rem', height: "100%", fontSize: '1rem'}} />


          <button style={{ borderRadius: '50%', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'all 200ms ease-in-out',  }}
            onClick={async () => await handleClose()}
            onMouseEnter={(e) => e.target.style.opacity = '0.7'}
            onMouseLeave={(e) => e.target.style.opacity = '1'}
          >
            <X style={{ width: '1.5rem', height: '1.5rem', color: 'var(--color-text3)' }} />
          </button>
        </div>
      )}


    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
      <PaymentElement />
      <button style={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', padding: '1rem', borderRadius: '.5rem', fontSize: '1rem', fontWeight: '600', transition: 'all 300ms ease-in-out', position: 'relative', marginTop: '1.5rem' }}
        type="submit"
        disabled={!stripe || isLoading || !elements}
        onMouseEnter={(e) => e.target.style.opacity = '0.7'} 
        onMouseLeave={(e) => e.target.style.opacity = '1'}
        // onClick={handleSubmit}
        >
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: isLoading ? 0 : 1 }}>
            Get School Goat PLUS
            <Sparkles style={{ width: '1rem', height: '1rem', color: 'var(--color-textButton)', marginLeft: '1rem', marginBottom: '2px'  }} />
          </span>
          { isLoading && (
            <div style={{ display: 'flex', position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <div style={{ width: '20px', height: '20px', border: '3px solid var(--color-text1)', borderTop: '3px solid var(--color-textButton)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
              <style>{`@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}`}</style>
            </div>
          )}
      </button>
      { message ? 
        <div style={{  color: 'rgb(105, 115, 134)', fontSize: '1rem', lineHeight: '1.3', textAlign: 'center', marginTop: '.5rem' }}
          >{message}
        </div>
        :
        <div style={{ color: 'var(--color-text3)', fontSize: '.9rem', lineHeight: '1.3', textAlign: 'center', marginTop: '.5rem' }}
          >
            <Lock style={{ width: '.9rem', height: '.9rem', color: 'var(--color-text3)', marginRight: '.5rem' }} />
            Secure payment with Stripe, cancel anytime
        </div>
      }
    </form>
    
    </>
  );
};


const CheckoutModal = ({  }) => {
  const { user, setUser, showCheckoutModal, setShowCheckoutModal } = useContext(UserContext);
  const { isMobileAndBelow, isTabletAndBelow } = useBreakpoint();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  // const [selectedPlan, setSelectedPlan] = useState(prices[1]);
  
  const [ clientSecret, setClientSecret ] = useState('');
  const [ selectedPlan, setSelectedPlan ] = useState(prices[1]);

  useEffect(() => {
    if (showCheckoutModal) {
      setTimeout(() => { setIsVisible(true) }, 50);
    }
    else {
      setIsVisible(false);
    }
  }, [showCheckoutModal]);

  useEffect(() => {
    
    const getClientSecret = async () => {
      try {
        const result = await axios.post(`${API}/stripe/create-payment-intent`, { priceId: 'price_1Q7NVpLkURBDGvXaXAm9xT0s', userEmail: user.email });
        console.log(result.data);
        setClientSecret(result.data.clientSecret);
      } 
      catch (error) {
        console.error('Error:', error);
      }
    };

    if (showCheckoutModal) {
      getClientSecret();
    }
  }, [showCheckoutModal, ]);
// }, [showCheckoutModal, selectedPlan]);

  const handleClose = async () => {
    setShowCheckoutModal(false);
    if ( !user.checkoutAbandoned ) {
      try {
        await axios.post(`${API}/email/abandoned-checkout`, { userEmail: user.email });
        setUser({ ...user, checkoutAbandoned: true });
      }
      catch (error) {
        console.error('Error:', error);
      }
    }
  }

  const handlePlanChange = async (plan) => {
    try {
      setSelectedPlan(plan);
      const paymentIntentId = clientSecret.split('_')[0] + '_' + clientSecret.split('_')[1];
      const result = await axios.post(`${API}/stripe/update-payment-intent`, { 
        paymentIntentId: paymentIntentId, 
        priceId: plan._id,
        userEmail: user.email
      });
      const results = result.data;
      const newClientSecret = results.clientSecret;
      setClientSecret(newClientSecret);
    } 
    catch (error) {
      console.error('Error:', error);
    }
  }

  // const testClientSecret = 'pi_3OvZUxKZ6a6DaWZL1gNrXPE4_secret_yr7DJkZjBvXzgvXj0YsCvkpjk';

  const getEffectivePrice = (basePrice, baseFrequency, outputFrequency) => {
    const basePricePerYear = basePrice * (baseFrequency === tabs.yearly ? 1 : baseFrequency === tabs.monthly ? 12 : 52);
    const outputDivider = outputFrequency === tabs.yearly ? 1 : outputFrequency === tabs.monthly ? 12 : 52;
    const effectivePrice = basePricePerYear / outputDivider;
    const formattedPrice = effectivePrice.toFixed(2);
    return formattedPrice;
  }



  if (!showCheckoutModal) return null;
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1500, opacity: isVisible ? 1 : 0, transition: 'opacity 300ms ease-in-out', overflowY: 'auto' }}>
      
      <div style={{ display: "flex", width: '1000px', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', overflow: 'auto', 
        ...(isTabletAndBelow && { width: "100%", height: "100%", borderRadius: "0", backgroundColor: 'var(--color-backgroundSecondary)' }),
        ...(isMobileAndBelow && { flexDirection: "column", }),
      }}>

        <div style={{ flex: 1, display: "flex", flexDirection: 'column', backgroundColor: 'var(--color-background)', padding: '1.8rem',  
          ...(isMobileAndBelow && { backgroundColor: 'var(--color-backgroundSecondary)', paddingBottom: '0' }),
        }}>

          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', marginBottom: '12px', justifyContent: 'space-between' }}>
            { !isMobileAndBelow ? (
              <Sparkles style={{ color: 'var(--color-primary)', width: '20px', height: '20px' }} />
            ) :
              <Chat styleOverride={{ position: "relative", backgroundColor: "transparent", border: "none", boxShadow: "none", color: "var(--color-text3)", fontWeight: "500", bottom: null, right: null, padding: '.25rem', height: "100%", fontSize: '1rem'}} />
            }

            { isMobileAndBelow && (
              <button style={{ borderRadius: '50%', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'all 200ms ease-in-out',  }}
                onClick={async () => await handleClose()}
                onMouseEnter={(e) => e.target.style.opacity = '0.7'}
                onMouseLeave={(e) => e.target.style.opacity = '1'}
              >
                <X style={{ width: '1.5rem', height: '1.5rem', color: 'var(--color-text3)' }} />
              </button>
            )}
          </div>

          <div style={{ marginBottom: '2rem', }}>
            <h2 style={{ fontSize: '1.5rem', fontWeight: '600', }}>
              Upgrade to School Goat Plus
            </h2>

            <p style={{ fontSize: '1.1rem', fontWeight: '400', color: 'var(--color-text3)', lineHeight: '1.3', marginTop: '.5rem' }}>  
              Maximize your academic potential with full access
            </p>
          </div>

          <div style={{ display: "flex", alignItems: 'center', gap: '.75rem', marginBottom: '1rem' }}>
            { prices.map((price, index) => {
              // const isSelected = price._id === selectedPlan._id;
              const isSelected = price.frequency === selectedPlan.frequency;

              return (
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: 'var(--color-backgroundSecondary)', padding: '1rem', borderRadius: '.5rem', position: 'relative', border: '2px solid var(--color-separatorOpaque)', cursor: 'pointer', opacity: isSelected ? 1 : 0.6, transition: 'all 200ms ease-in-out',
                  ...(isSelected && { border: '2px solid var(--color-text1)' })
                }}
                key={index}
                  onClick={() => handlePlanChange(price)}
                >
                  { price.tag && (
                    <div style={{ position: 'absolute', top: '-12px', right: '5px', fontSize: '.8rem', fontWeight: '400', color: 'var(--color-textButton)', backgroundColor: 'var(--color-text1)', padding: '6px', borderRadius: '.33rem' }}>
                      {price.tag}
                    </div>
                  )}
                  <div style={{ fontSize: '1rem', fontWeight: '600', color: 'var(--color-text1)', marginTop: '.25rem' }}>
                    {price.title}
                  </div>

                  <div style={{ fontSize: '1.4rem', fontWeight: '600', color: 'var(--color-text1)', marginTop: '1rem' }}
                    >${getEffectivePrice(price.basePrice, price.frequency, tabs.monthly)}<span style={{ fontSize: '.8rem', color: 'var(--color-text1)', fontWeight: '600', alignSelf: 'flex-end', marginBottom: '4px',  }}>/mo</span>
                  </div>
                  <div style={{ fontSize: '.9rem', color: 'var(--color-text3)', marginTop: '.6rem', display: 'flex', alignItems: 'flex-start' }}
                    >Typical price: <span style={{ textDecoration: 'line-through', marginLeft: '2px' }}>${getEffectivePrice(price.basePrice*1.5, price.frequency, tabs.monthly)}</span>
                  </div>
                  <div style={{ fontSize: '.9rem', color: 'var(--color-text3)', marginTop: '.4rem', display: 'flex', alignItems: 'flex-start' }}
                    >{price.description} 
                  </div>
                </div>
              )
            })}
          </div>


          { !isMobileAndBelow && (
            <>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: ".5rem", marginTop: "1rem", marginBottom: ".5rem"}}>
                { benefits.map((benefit, index) => {
                  return (
                    <div style={{ display: "flex", backgroundColor: 'var(--color-backgroundTertiary)', padding: '.5rem .5rem', borderRadius: '.5rem', alignItems: 'center', justifyContent: 'center' }}>
                      { !isTabletAndBelow && <Check style={{ color: 'var(--color-text3)', width: '.8rem', height: '.8rem', marginRight: '.25rem' }} /> }
                      <p style={{ fontSize: '.9rem', color: 'var(--color-text3)', lineHeight: '1.3', textAlign: 'center' }}>
                        {benefit}
                      </p>
                    </div>
                  )
                })}
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '.5rem', fontFamily: 'system-ui, -apple-system, sans-serif', backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.5rem', }}>
                <div style={{ display: 'flex', marginRight: '.5rem' }}>
                  {[Member1,Member2,Member3].map((user, index) => (
                    <img style={{ width: '22px', height: '22px', borderRadius: '50%', border: '1px solid white', marginLeft: index > 0 ? '-8px' : '0', backgroundColor: '#f4f4f4' }}
                      key={index}
                      src={user}
                      alt={`User ${index + 1}`}
                    />
                  ))}
                </div>
                <span style={{color: 'var(--color-text3)', fontSize: '.9rem', fontWeight: '400' }}>
                  Join 10,000+ students using School GOAT
                </span>
              </div>
            </>
          )}
        </div>

        <div style={{ flex: 1, backgroundColor: 'var(--color-backgroundSecondary)', padding: '1.8rem',  }}>

          { stripePromise && clientSecret && (
            <Elements 
              stripe={stripePromise} 
              options={{ 
                clientSecret: clientSecret, 
                appearance: {
                  theme: 'stripe',
                  variables: { 
                    colorPrimary: '#000', 
                    // colorText: 'rgba(0,0,0,.8)', 
                    // colorTextSecondary: 'rgba(0,0,0,.6)', 
                    // colorTextPlaceholder: 'rgba(0,0,0,0.439)', 
                    borderRadius: '.5rem', 
                    fontFamily: 'system-ui', 
                  },
                }, 
                loader: 'auto',
                
              }}
            >
              <CheckoutForm handleClose={handleClose} />
            </Elements>
          )}
        </div>
      </div>

  </div>
  )

};

export default CheckoutModal;





          {/* <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: 'var(--color-backgroundSecondary)', padding: '1rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px', borderBottom: '1px solid var(--color-separatorOpaque)',  marginBottom: '1rem', justifyContent: 'space-between', paddingLeft: '.5rem', paddingRight: '.5rem', paddingBottom: '.5rem', }}>
              <p style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text1)', lineHeight: '1.3' }}>
                Benefits
              </p>
              <p style={{ fontSize: '1rem', fontWeight: '400', color: 'var(--color-text1)', lineHeight: '1.3', width: "90px", textAlign: 'center', }}>
                Goat PLUS
              </p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              { benefits.map((feature, index) => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px', justifyContent: 'space-between', paddingLeft: '.5rem', paddingRight: '.5rem' }}
                    key={index}
                  >
                    <span style={{ fontSize: '1rem', color: 'var(--color-text3)' }}>
                      {feature}
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center', width: '90px', justifyContent: 'center' }}>
                      <Check style={{ color: 'var(--color-primary)', width: '20px', height: '20px' }} />
                    </div>
                  </div>
                )
              })}
            </div>
          </div> */}
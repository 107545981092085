import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Box, IconButton, Button } from '@mui/material';
import { Close as CloseIcon, Bolt as BoltIcon } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#8b5cf6' },
    background: { paper: '#1c1c1e' },
  },
});

const AudioUploadZeroBytesModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

    return (
      <ThemeProvider theme={darkTheme}>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 0 }}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >

        <Box style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
            <Typography variant="h4" component="div" style={{ color: '#8b5cf6', fontWeight: 'bold' }}
              >Warning
            </Typography>
          </Box>
          <Box style={{ borderRadius: '8px', marginBottom: '16px' }}>
            <Typography variant="body1" style={{ color: 'white', }}>
              <ol style={{ paddingLeft: '30px', listStyleType: 'decimal', lineHeight: '2' }}>
                <li>The audio your device sent over the last two minutes was blank</li>
                <li>We can't generate notes from blank audio</li>
                <li>This is often caused by another app using your microphone</li>
                <li>Please close other apps that might be using the microphone</li>
                <li>Try recording again after closing other apps</li>
              </ol>
            </Typography>
          </Box>
          <Button variant="contained" fullWidth style={{ backgroundColor: '#8b5cf6', color: 'white', padding: '12px', borderRadius: '8px', marginBottom: '16px' }}
            onClick={() => handleClose()}
            >Ok
          </Button>

        </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  
};

export default AudioUploadZeroBytesModal;
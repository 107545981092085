'use client';
import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { API, COLOR_ARRAY, S3_URL_PREFIX, PAYWALL_TYPES } from '../misc/constants';
import { ArrowUpIcon, PaperclipIcon, Upload, RotateCcw, FileCheck, Camera } from 'lucide-react';
import ScreenHeader from '../components/ScreenHeader2';
import SolveMessage from '../components/SolveMessage';
import { Command, Fullscreen } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENTS, dbRefs, OPENAI_CHAT_ROLES } from '../misc/constants';
import { v4 as uuidv4 } from 'uuid';
import { getSignedS3Urls } from '../misc/utils';
import SolverDemoModal from '../modals/SolverDemoModal';

const MAX_SIZE_MB = .8; // Adjust this to match your server limit
const BYTE_TO_MB = 1024 * 1024;
const operatingSystemTypes = {
  macOS: {
    _id: 'macOS',
    shortcut: `⌘ + Shift + 4`
  },
  windows: {
    _id: 'windows',
    shortcut: `Win + Shift + 4`
  },
  linux: {
    _id: 'linux',
    shortcut: `Shift + PrtSc`
  },
  unknown: {
    _id: 'unknown',
    shortcut: ''
  }
}

const SolveScreen = ({  }) => {
  const { user, checkUserPermission } = useContext(UserContext);

  const fileInputRef = useRef(null);
  const fileInputMobileRef = useRef(null);
  const messagesEndRef = useRef(null);

  const { isPhoneAndBelow } = useBreakpoint();  
  
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/webp'];
  const validImageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];

  const [ chatInput, setChatInput ] = useState('');
  const [ chatMessages, setChatMessages ] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [ loadingResponse, setLoadingResponse ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ openSolverDemoModal, setOpenSolverDemoModal ] = useState(false);
  const [ operatingSystemType, setOperatingSystemType ] = useState(null);
  const buttonIsLoadingRef = useRef(false)

  useEffect(() => {
    const platform = navigator.platform.toLowerCase();
    
    if (platform.includes('mac')) {
      setOperatingSystemType(operatingSystemTypes.macOS);
    } else if (platform.includes('win')) {
      setOperatingSystemType(operatingSystemTypes.windows);
    } else if (platform.includes('linux')) {
      setOperatingSystemType(operatingSystemTypes.linux);
    } else {
      setOperatingSystemType(operatingSystemTypes.unknown);
    }
  }, []);

  /////////////////////////////////////// FILE HANDLING ///////////////////////////////////////

  const checkFileTypeAndSize = (file) => {
    if ( !validImageTypes.includes(file.type) && !validImageExtensions.some(ext => file.name.toLowerCase().endsWith(ext)) ) {
      alert(`Invalid file type. Please select one of the following: ${validImageExtensions.join(', ')}. Contact support if you need more file types.`);
      return false
    }
    else if (file.size > 100 * 1024 * 1024) {
      alert('File is too large (max 100 MB). Let us know if you need more storage.');
      return false
    }
    else {
      return true
    }
  }

  
  const processImage = (file, maxDimension = 800, quality = 0.7) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
      
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        let newWidth, newHeight;
        
        if (img.width > img.height) {
          newWidth = Math.min(maxDimension, img.width);
          newHeight = Math.round(newWidth / aspectRatio);
        } else {
          newHeight = Math.min(maxDimension, img.height);
          newWidth = Math.round(newHeight * aspectRatio);
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        const base64String = canvas.toDataURL('image/jpeg', quality);
        
        // Check processed image size
        const processedSize = Math.round((base64String.length * 3) / 4);
        if (processedSize > MAX_SIZE_MB * BYTE_TO_MB) {
          // If still too large, try processing again with lower quality
          if (quality > 0.3) {
            console.log(`Image still too large, retrying with lower quality...`);
            resolve(processImage(file, maxDimension, quality - 0.2));
          } else {
            reject(new Error(`Unable to compress image below ${MAX_SIZE_MB}MB limit. Consider using a smaller image.`));
          }
          return;
        }

        const originalSize = Math.round(file.size / 1024);
        const newSize = Math.round(processedSize / 1024);
        console.log(`Original dimensions: ${img.width}x${img.height}`);
        console.log(`New dimensions: ${newWidth}x${newHeight}`);
        console.log(`Original size: ${originalSize}KB`);
        console.log(`New size: ${newSize}KB`);
        resolve(base64String);
      };

      img.onerror = (error) => reject(new Error('Error loading image'));
    };

    reader.onerror = (error) => reject(new Error('Error reading file'));
    reader.readAsDataURL(file);
  });
  };

  const handleImage = async (file) => {

    const checkFile = checkFileTypeAndSize(file)

    if ( checkFile ) {

      try {
        const base64Image = await processImage(file);

        const contentType = base64Image.match(/^data:([^;]+);base64,/)?.[1] || 'image/jpeg';
        const blobData = await fetch(base64Image).then(res => res.blob());
        const fileName = `${uuidv4()}`
        const storagePath = `messages/${fileName}`
        const url = `${S3_URL_PREFIX}${storagePath}`
        const [signedUrl] = await getSignedS3Urls([storagePath]);
        await axios.put(signedUrl, blobData, { headers: { 'Content-Type': contentType }});

        const newMessage = {
          role: OPENAI_CHAT_ROLES.user,
          content: [{ type: "image_url", image_url: { url: url } }]
        };

        handleSubmit(newMessage, true)
      } 
      catch (error) {
        console.error('Error converting file to base64:', error);
        setError(true);
        throw error;
      }
    }
  };

  const handleMessage = async () => {

    if ( buttonIsLoadingRef.current ) return
    buttonIsLoadingRef.current = true

    try {
      const newMessage = {
        role: OPENAI_CHAT_ROLES.user,
        content: chatInput
      };
  
      await handleSubmit(newMessage)
    }
    catch {
      alert("Something went wrong. Please try again")
    }
    finally {
      buttonIsLoadingRef.current = false
    }
  }

  const handleSubmit = async (newMessage, clearMessages = false) => {
    if ( !await checkUserPermission(PAYWALL_TYPES.solver) ) return;

    let updatedMessages = clearMessages ? [newMessage] : [...chatMessages, newMessage];
    setChatMessages([...updatedMessages, { role: OPENAI_CHAT_ROLES.assistant, content: '' }]);
    setLoadingResponse(true);

    const TIMEOUT_DURATION = 8000; // 30 seconds timeout
    let timeoutId;

    try {
      setChatInput('');

      const response = await fetch(`${API}/streamSolver`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user?._id, messages: updatedMessages }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const message = decoder.decode(value);
          const lines = message.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const json = line.replace('data: ', '');
              if (json.trim() !== '') {
                const data = JSON.parse(json);

                if (data.error) {
                  throw new Error('Server error');
                }

                // Reset the timeout on each received message
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                  throw new Error('Response timeout');
                }, TIMEOUT_DURATION);

                setChatMessages((prev) => {
                  const lastMessage = prev[prev.length - 1];
                  if (lastMessage.role === OPENAI_CHAT_ROLES.assistant) {
                    return [
                      ...prev.slice(0, -1),
                      { ...lastMessage, content: lastMessage.content + (data.content || '') }
                    ];
                  }
                  return prev;
                });
              }
            }
          }
        }
      };

      // Set initial timeout
      timeoutId = setTimeout(() => {
        throw new Error('Initial response timeout');
      }, TIMEOUT_DURATION);

      await processStream();

      await axios.post(`${API}/generalInsertOne`, { 
        doc: {
          _id: uuidv4(),
          user_id: user?._id,
          user_email: user?.email,
          text: typeof newMessage.content === 'string' ? newMessage.content : '',
          imageUrl: newMessage?.content?.[0]?.image_url?.url || '',
          date: new Date()
        }, 
        dbRef: dbRefs.messages 
      });

      mixpanel.track(MIXPANEL_EVENTS.solver, {
        user_id: user?._id,
        user_email: user?.email,
      });
      
    } 
    catch (error) {
      console.error('Error in handleSubmit:', error);
      handleError();
    }
    finally {
      clearTimeout(timeoutId);
      setLoadingResponse(false);
    }
  };

  const handleError = () => {
    setChatMessages((prev) => {
      const updatedMessages = [...prev];
      if (updatedMessages[updatedMessages.length - 1].role === OPENAI_CHAT_ROLES.assistant) {
        updatedMessages.pop();
      }
      return [...updatedMessages];
    });
    setError(true);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault();
        setChatInput(prevInput => prevInput + '\n');
      } 
      else {
        if ( !loadingResponse && chatInput.trim() !== '') {
          e.preventDefault();
          handleMessage();
        }
      }
    }
  }



  /////////////////////////////////////// DRAG AND DROP ///////////////////////////////////////

  const overlayRef = useRef(null);

  const useDocumentFileDrag = () => {
    const dragCounter = useRef(0);
    useEffect(() => {
      const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.types.includes('Files')) {
          setIsDragging(true);
        }
      };
  
      const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };
  
      const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
          setIsDragging(false);
        }
      };
  
      const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          handleImage(e.dataTransfer.files[0])
        }
      };
  
      document.addEventListener('dragenter', handleDragEnter);
      document.addEventListener('dragover', handleDragOver);
      document.addEventListener('dragleave', handleDragLeave);
      document.addEventListener('drop', handleDrop);
  
      return () => {
        document.removeEventListener('dragenter', handleDragEnter);
        document.removeEventListener('dragover', handleDragOver);
        document.removeEventListener('dragleave', handleDragLeave);
        document.removeEventListener('drop', handleDrop);
      };
    }, []);
  
    return isDragging;
  };

  const isFileOver = useDocumentFileDrag();


  /////////////////////////////////////// COMPONENTS ///////////////////////////////////////
  const UploadOverlay = () => {
    if ( isFileOver ) {
      return (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#EEE7FE85', zIndex: 9999, display: 'flex', alignItems: 'center', justifyContent: 'center', transition: 'background-color 0.3s', backdropFilter: 'blur(25px)', WebkitBackdropFilter: 'blur(25px)' }}
          ref={overlayRef}
        >
          <div style={{ padding: '32px', borderRadius: '8px', textAlign: 'center' }}>
            <Upload style={{ margin: '0 auto 16px auto', color: 'var(--color-primary)' }} size={48} />
            <p style={{ fontSize: '20px', fontWeight: 600, color: '#333' }}>Drop your screenshot here</p>
          </div>
        </div>
      )
    }
    else {
      return null
    }
  }

  const ErrorComponent = () => {
    if ( error ) {

      const handleErrorRetry = () => {
        setError(false);
        let updatedMessages = [...chatMessages];
        let lastUserMessage;

        if (updatedMessages[updatedMessages.length - 1].role === OPENAI_CHAT_ROLES.assistant) {
          updatedMessages.pop();
        }

        for (let i = updatedMessages.length - 1; i >= 0; i--) {
          if (updatedMessages[i].role === OPENAI_CHAT_ROLES.user) {
            lastUserMessage = updatedMessages[i];
            updatedMessages.splice(i, 1);
            break;
          }
        }

        setChatMessages(updatedMessages);

        if (lastUserMessage) {
          handleSubmit(lastUserMessage);
        }
      }


      return (
        <button style={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)", display: "flex", width: "15rem", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: '1rem', margin: 0, background: 'none', border: 'none', cursor: 'pointer', color: 'var(--color-text3)', gap: '.5rem', backgroundColor: `${COLOR_ARRAY[1]}33`, borderRadius: '100px' }}
        onClick={handleErrorRetry}
      >
      <p style={{ fontSize: '1rem', color: COLOR_ARRAY[1] }}
        >Error, click to retry
      </p>
        <RotateCcw size={16} color={COLOR_ARRAY[1]}/>
      </button>
      )
    }
  }

  return (

    <div id="wrapper" >

      <UploadOverlay />

      { chatMessages.length == 0 && (
        <div style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", maxWidth: '700px', alignSelf: 'center', width: '100%', margin: "auto", padding: "1rem" }}>
          <h1 style={{ fontSize: '1.4rem', fontWeight: '600', textAlign: 'center', marginBottom: '.5rem', lineHeight: '1.5' }}
            >Assignment solver
          </h1>

          <p style={{ fontSize: '1.1rem', color: 'var(--color-text3)', lineHeight: '1.3', textAlign: 'center' }}
            >Input a question → get the solution + explanation
          </p>

          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1rem', marginTop: '1.5rem', width: "100%",  }}>
            <div  style={{ display: "flex", marginBottom: '20px', width: '100%', background: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: ".5rem", paddingLeft: '1rem', alignItems: 'center', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}
              // onSubmit={handleMessage}
            >
              <input style={{ width: '100%', border: 'none', background: 'transparent', fontSize: '16px', outline: 'none', padding: "0 .4rem", }}
                placeholder="Ask your question here"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                onKeyDown={handleKeyPress}
                aria-label="Search input"
              />
              <button style={{ height: '40px', padding: '0rem 1rem', backgroundColor: 'var(--color-text1)', color: 'white', borderRadius: '.5rem', opacity: chatInput !== '' ? 1 : 0.3, cursor: chatInput !== '' ? 'pointer' : 'not-allowed', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem',  }}
                type="submit"
                aria-label="Submit search"
                disabled={chatInput === ''}
                onClick={handleMessage}
              >
                Solve
              </button>
            </div>
          </div>

          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '.5rem', border: '1px solid var(--color-separatorOpaque)', padding: '1rem', borderRadius: '.5rem', boxShadow: 'var(--borderShadow)', height: "200px", }}>
            
            { !isPhoneAndBelow ? (
              <>
                <Camera size={36} style={{color: 'var(--color-text3)', }} strokeWidth={1.5} />
                <h4 style={{ fontSize: '1rem', fontWeight: '400', color: 'var(--color-text1)', fontWeight: '500', marginTop: '.5rem' }}
                  >Screenshot the question & drop it here
                </h4>
                <span style={{ fontSize: '.9rem', fontFamily: 'monospace', color: 'var(--color-text3)', marginTop: '.25rem' }}
                  >{operatingSystemType?.shortcut}
                </span>
              </>
            ) : 
              <>
                <Camera size={36} style={{color: 'var(--color-text3)', }} strokeWidth={1.5} />
                <h4 style={{ fontSize: '1rem', fontWeight: '400', color: 'var(--color-text1)', fontWeight: '500', marginTop: '.5rem' }}
                  >Take a photo of the question
                </h4>
                <button style={{ backgroundColor: 'var(--color-text1)', color: 'white', borderRadius: '.5rem', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '.75rem 1.5rem', fontSize: '1rem', fontWeight: '600', marginTop: '.5rem' }}
                  type="submit"
                  onClick={() => fileInputMobileRef.current.click()}
                >
                  Take photo
              </button>
              <input
                type="file"
                ref={fileInputMobileRef}
                onChange={(e) => handleImage(e.target.files[0])}
                accept="image/*"
                capture="environment"
                style={{display: 'none'}}
                />
              </>
            }
          </div>

          
        </div>
      )}


      { chatMessages.length > 0 && (
        <>
          <div id="main" style={{ overflowY: 'auto' }}>
            <div className="inner" style={{ }}>
              {chatMessages.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                  {chatMessages.map((message, index) => (
                      <SolveMessage key={index} item={message} index={index} isPhoneAndBelow={isPhoneAndBelow} />
                    ))}
                  </div>
                )}
              <ErrorComponent />
            </div>
          </div>

          <div style={{ width: "100%", paddingBottom: "1rem", backgroundColor: 'var(--color-backgroundSecondary)', paddingTop: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
            <div style={{ maxWidth: "1200px", display: 'flex', alignItems: 'center', padding: ".5rem", backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)',  margin: '0 auto', }}>

              <button style={{ height: "100%", backgroundColor: 'var(--color-background)', color: 'var(--color-text3)', borderRadius: '.5rem', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "0 .6rem", marginRight: "0rem", border: '1px solid var(--color-separatorOpaque)', fontSize: '1rem', height: "40px" }}
                type="button"
                onClick={() => setChatMessages([])}
                >Clear
              </button>

              <input style={{ width: '100%', color: 'var(--color-text1)', padding: '0 .6rem', border: 'none', outline: 'none', backgroundColor: 'transparent', fontSize: isPhoneAndBelow ? '16px' : '1rem' }}
                type="text"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Describe the assignment problem"
              />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImage(e.target.files[0])}
                ref={fileInputRef}
                style={{ display: 'none' }}
                aria-label="Select image file"
              />
              <button style={{ backgroundColor: 'var(--color-text1)', color: 'white', borderRadius: '.5rem', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: loadingResponse || chatInput.length == 0 ? 0.3 : 1, height: "40px", width: "40px" }}
                type="submit"
                onClick={handleMessage}
                disabled={loadingResponse || chatInput.length == 0}
              >
                <ArrowUpIcon size={ isPhoneAndBelow ? 16 : 24 } />
              </button>
            </div>
          </div>
        </>
      )}

      <SolverDemoModal open={openSolverDemoModal} setOpen={setOpenSolverDemoModal} handleSubmit={handleSubmit} />
    </div>

  );
};

export default SolveScreen;



// const OpeningComponent = () => {

//   const containerStyle = { display: 'flex', height: "100%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2rem', 
//     // backgroundColor: '#EEE7FE85', 
//     // border: '2px dashed var(--color-primary)', 
//     borderRadius: '15px', textAlign: 'center', lineHeight: '1.2', width: '100%', position: 'relative' }
//   const headerStyle = { fontSize: '1.6rem', fontWeight: 'bold', color: 'var(--color-text1)', lineHeight: '1.5' }
//   const secondaryTextStyle = { fontSize: '1rem', color: 'var(--color-text3)', marginTop: '2rem' }
//   const shortCutItemContainerStyle = { display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'center', justifyContent: 'center', flexBasis: "200px", padding: "1rem", borderRadius: "1rem", 
//     // border: "1px solid var(--color-separatorOpaque)", boxShadow: "var(--borderShadow)"   
//   }

//   if ( chatMessages.length == 0 ) {

//     if ( !isPhoneAndBelow ) {
//       return (
//         <div style={containerStyle} > 
//           <Fullscreen size={60} style={{color: 'var(--color-primary)', marginBottom: '15px'}} strokeWidth={1.5} />
          
//           <p style={headerStyle}
//             >Assignment solver
//           </p>

//           <p style={{ fontSize: '1.1rem', color: 'var(--color-text3)', lineHeight: '1.3', width: "28rem" }}
//             >Screenshot the problem you are working on and drop it here, or just ask the question below
//           </p>
          
//           <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1rem', marginTop: '1.5rem', width: "100%",  }}>

//             <div style={shortCutItemContainerStyle}>
//               <h4 style={{ fontSize: '1rem', fontWeight: '400', color: 'var(--color-text3)', marginBottom: '4px', textDecoration: 'underline' }}
//                 >Apple screenshot
//               </h4>
//               <span style={{ fontSize: '.9rem', fontFamily: 'monospace', color: 'var(--color-text1)' }}
//                 ><Command size={14} /> + Shift + 4
//               </span>
//             </div>

//             <div style={shortCutItemContainerStyle}>
//               <h4 style={{ fontSize: '1rem', fontWeight: '400', color: 'var(--color-text3)', marginBottom: '4px', textDecoration: 'underline' }}
//                 >Windows screenshot
//               </h4>
//               <span style={{ fontSize: '.9rem', fontFamily: 'monospace', color: 'var(--color-text1)' }}
//                 >Win + Shift + S
//               </span>
//             </div>
//           </div>

//           <button style={{backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', borderRadius: '30px', padding: '1rem 2rem', fontSize: '1rem', fontWeight: '600', cursor: 'pointer', transition: 'background-color 0.2s ease-in-out', marginTop: '2rem'}}
//             onClick={() => setOpenSolverDemoModal(true)}
//             onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-backgroundTertiary)'}
//             onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//             >Watch it in action
//           </button>

//         </div>
//       )
//     }

//     else {
//       return (
//         <div style={containerStyle} >
//           <Fullscreen size={60} style={{color: 'var(--color-primary)', marginBottom: '15px'}} strokeWidth={1.5} />
          
//           <p style={headerStyle}
//             >Assignments solver
//           </p>

//           <p style={{ fontSize: '1.1rem', color: 'var(--color-text3)', lineHeight: '1.3', width: "28rem", maxWidth: "80%" }}
//             >Take a photo of the problem you want solved or just ask the question below
//           </p>


//           <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '2rem' }}>
//             <button style={{backgroundColor: 'var(--color-primary)', color: 'white', border: 'none', borderRadius: '30px', padding: '1rem 2rem', fontSize: '1rem', fontWeight: '600', cursor: 'pointer', transition: 'background-color 0.2s ease-in-out', width: "100%"}}
//               onClick={() => fileInputMobileRef.current.click()}
//             >Get Photo
//           </button>

//           <button style={{backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', borderRadius: '30px', padding: '1rem 2rem', fontSize: '1rem', fontWeight: '600', cursor: 'pointer', transition: 'background-color 0.2s ease-in-out'}}
//             onClick={() => setOpenSolverDemoModal(true)}
//             >Watch it in action
//           </button>

//           </div>


//           <input
//             type="file"
//             ref={fileInputMobileRef}
//             onChange={(e) => handleImage(e.target.files[0])}
//             accept="image/*"
//             capture="environment"
//             style={{display: 'none'}}
//           />
//         </div>
//     )
//   }
//   }
//   else {
//     return null
//   }
// }



      {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '2rem' }}>
        <p style={{ fontSize: '1rem', color: 'var(--color-text3)', marginBottom: '1rem' }}>Try these examples</p>
        
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem', width: '100%', maxWidth: '600px' }}>
          <div style={{ padding: '0.75rem', backgroundColor: '#F4F4F5', borderRadius: '0.5rem', cursor: 'pointer' }}
            onClick={() => setInput("What is the derivative of f(x) = x^2 * sin(x)? Show the steps using the product rule.")}
          >
            <p style={{ fontSize: '0.9rem', color: 'var(--color-text1)' }}>Find the derivative of f(x) = x^2 * sin(x) using the product rule</p>
          </div>

          <div style={{ padding: '0.75rem', backgroundColor: '#F4F4F5', borderRadius: '0.5rem', cursor: 'pointer' }}
            onClick={() => setInput("Solve the system of equations: 2x + y = 5 and x - y = 1")}
          >
            <p style={{ fontSize: '0.9rem', color: 'var(--color-text1)' }}>Solve the system of equations: 2x + y = 5 and x - y = 1</p>
          </div>

          <div style={{ padding: '0.75rem', backgroundColor: '#F4F4F5', borderRadius: '0.5rem', cursor: 'pointer' }}
            onClick={() => setInput("What is the probability of drawing 2 aces from a standard deck of 52 cards without replacement?")}
          >
            <p style={{ fontSize: '0.9rem', color: 'var(--color-text1)' }}>Calculate probability of drawing 2 aces from a deck of cards</p>
          </div>
        </div>
      </div> */}
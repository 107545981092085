import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Ban, CircleAlert, CircleCheck, FileText, Loader2 } from 'lucide-react';
import ScreenHeader from '../components/ScreenHeader2';
import Subheader from '../components/Subheader';
import { API, MIXPANEL_EVENTS } from '../misc/constants';
import mixpanel from 'mixpanel-browser';
import { UserContext } from '../contexts/UserProvider';

const riskLevels = {
  low: {
    value: .33,
    label: 'Low',
    color: 'rgb(16 185 129)',
    colorLight: 'rgb(209 250 229)',
  },
  medium: {
    value: .66,
    label: 'Medium',
    color: 'rgb(245 158 11)',
    colorLight: 'rgb(254 243 199)',
  },
  high: {
    value: 1,
    label: 'High',
    color: 'rgb(239 68 68)',
    colorLight: 'rgb(254 226 226)',
  }
}

const getRiskLevel = (value) => {
  if (value < riskLevels.low.value) return riskLevels.low;
  if (value < riskLevels.medium.value) return riskLevels.medium;
  return riskLevels.high;
}

const Tile_Total = ({ score = .65 }) => {
  const riskLevel = getRiskLevel(score);
  const effectiveScore = Math.round(score * 100);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '1rem', padding: '1.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', textAlign: 'center' }}>
      <div style={{ fontSize: '3rem', fontWeight: 700, color: riskLevel.color, lineHeight: '1' }}>
          {effectiveScore.toFixed(0)}%
        </div>
      <div>
        <h2 style={{ fontSize: '1.25rem', fontWeight: 500, color: 'var(--color-text1)', margin: 0 }}>
          Total AI Probability
        </h2>
        <p style={{ fontSize: '1rem', color: 'var(--color-text3)', marginTop: '.6rem' }}>
          There is a <u>{riskLevel.label} Risk</u> that this was AI-generated
        </p>
      </div>

      {/* <div style={{ width: '100%', height: '8px', backgroundColor: '#f3f4f6', borderRadius: '4px', background: `linear-gradient(to right, ${riskLevel.color} ${effectiveScore}%, #f3f4f6 ${effectiveScore}%)` }} /> */}
    </div>
  );
};

const Tile_Dashboard = ({ item }) => {
  return (
    <div style={{ backgroundColor: 'white', borderRadius: '16px', padding: '24px', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', position: 'relative', }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', alignItems: 'center' }}>
        <span style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text1)' }}
          >{item.name}
        </span>
        {React.cloneElement(item.icon, { style: { color: item.color, width: '1.2rem', height: '1.2rem' }, strokeWidth: 1.7 })}
      </div>
      
      <div style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '.3rem', color: 'var(--color-text1)' }}
        // >{item.value.toLocaleString()}
        >{item.percentage}%
      </div>
      
      <div style={{ fontSize: '1rem', color: 'var(--color-text3)', display: 'flex', alignItems: 'center' }}>
        of tokens in the text
      </div>

      <div style={{ width: '100%', height: '4px', backgroundColor: '#eee', borderRadius: '2px', marginTop: '1rem' }}>
        <div style={{ width: `${item.percentage}%`, height: '100%', backgroundColor: 'var(--color-text1)', borderRadius: '2px', transition: 'width 0.3s ease-in-out' }} />
      </div>

    </div>
  );
};

const Tile_Sentence = ({ item }) => {
  const riskLevel = getRiskLevel(item.score);

  return (
    <span style={{ fontSize: '16px', lineHeight: '1.5', color: '#374151', backgroundColor: riskLevel.colorLight, padding: '2px 0px', borderRadius: '0px', transition: 'background-color 0.2s ease' }}>
      {item.sentence}
    </span>
  );
};




const AIDetectionScreen = () => {
  const { user } = useContext(UserContext);
  const tabs = { input: "Input Essay", evaluation: "Evaluation" }
  const [ activeTab, setActiveTab ] = useState(tabs.input);
  const [ inputText, setInputText ] = useState('');
  const [ data, setData ] = useState(null);
  const textInputStyle = { padding: '1rem 1.2rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', fontSize: '1rem', color: `var(--color-text1)`, display: 'flex', flex: 1, fontWeight: "400", boxShadow: "var(--borderShadow)" }
  const isSubmittable = inputText && inputText.length > 10;
  const [ loading, setLoading ] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(`${API}/ai-detector`, { text: inputText });
      const results = response.data;
      const { data: responseData } = results;

      const sentences = responseData.tokens.map((item, index) => {
        if (!item.includes('<') || !item.includes('>')) {
          return {
            sentence: item,
            score: responseData.token_probs[index],
          };
        }
      }).filter(Boolean);

      const sentenceCount = sentences.length
      let lowRiskCount = 0;
      let mediumRiskCount = 0;
      let highRiskCount = 0;
      
      for (const item of sentences) {
        if (item.score < riskLevels.low.value) {
          lowRiskCount++;
        } else if (item.score < riskLevels.medium.value) {
          mediumRiskCount++;
        } else {
          highRiskCount++;
        }
      }

      const dashboardData = [
        { name: "Low Risk", value: lowRiskCount, percentage: Math.round(lowRiskCount / sentenceCount * 100), icon: <CircleCheck />, color: riskLevels.low.color },
        { name: "Medium Risk", value: mediumRiskCount, percentage: Math.round(mediumRiskCount / sentenceCount * 100), icon: <CircleAlert />, color: riskLevels.medium.color },
        { name: "High Risk", value: highRiskCount, percentage: Math.round(highRiskCount / sentenceCount * 100), icon: <Ban />, color: riskLevels.high.color },
      ]

      const dataObj = {
        score: responseData.score,
        dashboard: dashboardData,
        sentences: sentences,
      }

      console.log(dataObj);

      setData(dataObj);
      setActiveTab(tabs.evaluation);
      mixpanel.track(MIXPANEL_EVENTS.essay_ai_detection, { user_id: user?._id, user_email: user?.email });
    } 
    catch (err) {
      // const { msg } = err.response.data;
      console.log({err: err});
      alert(err);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div id="wrapper">          
      
      <ScreenHeader
        title="AI Detection"
        showBackButton={true}
        icon={<FileText />}
      />

      <div id="main" >
        <div className="inner" >
          <Subheader activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          { activeTab === tabs.input &&
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
              <textarea style={{ ...textInputStyle, flex: null, display: "block", height: "20rem", fontFamily: "inherit", width: '100%', color: 'var(--color-text3)', lineHeight: '1.3' }}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Paste your essay here..."
              />

              <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem', backgroundColor: isSubmittable ? 'var(--color-primary)' : 'var(--color-primaryLight)', color: 'var(--color-textButton)', border: 'none', borderRadius: '30px', cursor: isSubmittable ? 'pointer' : 'not-allowed', fontSize: '1rem', alignSelf: 'center', width: '10rem' }}
                onClick={getData}
                disabled={!isSubmittable || loading}
                type="submit" 
                onMouseEnter={(e) => { if (isSubmittable) { e.target.style.backgroundColor = 'var(--color-primaryLight)'; } }}
                onMouseLeave={(e) => { if (isSubmittable) { e.target.style.backgroundColor = 'var(--color-primary)'; } }}
                >Analyze Essay
              </button>

              { loading && 
                <div style={{ width: '30px', height: '30px', borderRadius: '50%', border: '3px solid rgba(0, 0, 0, 0.1)', borderLeftColor: 'var(--color-primary)', position: 'relative', animation: 'spin 0.8s cubic-bezier(0.42, 0, 0.58, 1) infinite' }}>
                  <style>{`@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }`}</style>
                </div>
              }
            </div>
          }

          { activeTab === tabs.evaluation && data &&

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

              <Tile_Total score={data?.score} />

              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '1.1rem' }}>
                {data?.dashboard.map((item) => (
                  <Tile_Dashboard key={item.name} item={item} />
                ))}
              </div>

              <div style={{ backgroundColor: 'white', borderRadius: '1rem', padding: '1.5rem', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', }}>
                {data?.sentences.map((item, index) => (
                  <Tile_Sentence key={index} item={item} />
                ))}
              </div>

            </div>
          }

        </div>
      </div>
    </div>
  );  
}

export default AIDetectionScreen;






      // const sentenceCount = responseData.sentence_scores.length
      // let lowRiskCount = 0;
      // let mediumRiskCount = 0;
      // let highRiskCount = 0;
      
      // for (const item of responseData.sentence_scores) {
      //   if (item.score < riskLevels.low.value) {
      //     lowRiskCount++;
      //   } else if (item.score < riskLevels.medium.value) {
      //     mediumRiskCount++;
      //   } else {
      //     highRiskCount++;
      //   }
      // }

      // console.log(responseData, responseData?.token_probs)

      // const dashboardData = [
      //   { name: "Low Risk Sentences", value: lowRiskCount, percentage: Math.round(lowRiskCount / sentenceCount * 100), icon: <CircleCheck />, color: 'green' },
      //   { name: "Medium Risk Sentences", value: mediumRiskCount, percentage: Math.round(mediumRiskCount / sentenceCount * 100), icon: <CircleAlert />, color: 'orange' },
      //   { name: "High Risk Sentences", value: highRiskCount, percentage: Math.round(highRiskCount / sentenceCount * 100), icon: <Ban />, color: 'red' },
      // ]
import React from 'react';
import { SquarePen, Settings2, Search } from 'lucide-react';
import { Routes } from '../navigation/routes';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';

const data = [
  { _id: Routes.ESSAY, title: 'Write Essay', icon: <SquarePen />,
    description: "Write better essays with School GOAT. As you write, it will offer suggestions for improvement and help you finish faster.", 
   },
  { _id: Routes.ESSAY_EVALUATION, title: 'Essay Evaluation', icon: <Settings2 />,
    description: "Maximize your essay score. Before turning it in, get it evaluated. You will get an estimated grade along with specific suggestions for improvement.", 
   },
  { _id: Routes.AI_DETECTION, title: 'AI Detection', icon: <Search />,
    description: "Don't get flagged by AI detection software. Use this tool to check whether your essay is likely to trigger AI detection.", 
   },
];

const WritingScreen = () => {
  const { isPhoneAndBelow } = useBreakpoint()
  const navigate = useNavigate();

  return (
    <div id="wrapper">
      <main id="main" >
        <div className="inner">

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', flexWrap: 'wrap', marginBottom: '2rem', maxWidth: "600px", width: "100%",  alignSelf: 'center' }}>

        <h1 style={{ fontSize: '1.4rem', fontWeight: '600', textAlign: 'center', marginBottom: '.5rem', lineHeight: '1.5' }}
            >Writing Tools
          </h1>

          <p style={{ fontSize: '1.1rem', color: 'var(--color-text3)', lineHeight: '1.3', maxWidth: "95%", textAlign: 'center' }}
            >Write essays, get feedback, and check for AI
          </p>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginTop: '1.5rem', width: "100%",  }}>

              { data.map(tab => {
              return (
                <button key={tab.title} style={{ fontSize: '1rem', color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', background: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)', padding: '1.5rem', borderRadius: '.5rem', transition: 'all 0.2s ease-in-out', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'left', cursor: 'pointer', width: "100%",

                  ...(isPhoneAndBelow && { padding: '1rem' }),
                 }}
                  onClick={() => navigate(`/app/${tab._id}`)}
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
                >
                  <div style={{ backgroundColor: 'var(--color-text1)', borderRadius: '50%', padding: '6px', display: 'inline-flex', marginBottom: '1rem' }}>
                    {React.cloneElement(tab.icon, { size: 20, color: 'var(--color-textButton)', style: { } })}
                  </div>
                  <span style={{ fontSize: '1.1rem', fontWeight: '600', marginBottom: '.25rem' }}
                    >{tab.title}
                  </span>
                  <span style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.3' }}>
                    {tab.description}
                  </span>
                </button>
              )
            })}

            </div>
          </div>

        </div>
      </main>
    </div>
  );
};

export default WritingScreen;

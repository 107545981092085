import React from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';

const ScreenHeader = ({ 
  title, 
  icon,
  showBackButton,
  rightContent,
  actions = [],
  onBackClick,
  leftContent,
}) => {

  const navigate = useNavigate();
  const { isMobileAndBelow } = useBreakpoint();

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };

  const renderRightContent = () => {
    if (rightContent) {
      return rightContent;
    }

    return actions.map((action, index) => {
      if (React.isValidElement(action)) {
        return <React.Fragment key={index}>{action}</React.Fragment>;
      }

      return (
        <button
          style={{ padding: '8px', color: '#4b5563', cursor: 'pointer', border: 'none', background: 'none', display: 'flex', alignItems: 'center', gap: '4px' }}
          key={index}
          onClick={action.onClick}
          title={action.label}
        >
          {action.icon}
          {/* {action.label && <span style={{ fontSize: '14px' }}>{action.label}</span>} */}
        </button>
      );
    });
  };

  return (
    <header style={{ borderBottom: '1px solid var(--color-separatorOpaque)', width: '100%', height: '55px', minHeight: '55px', flexShrink: 0 }}>
      <div style={{ maxWidth: '1200px', margin: '0 auto', padding: isMobileAndBelow ? '0 1rem' : '0 2rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {showBackButton && (
            <button style={{ padding: '8px', color: 'var(--color-text3)', cursor: 'pointer', border: 'none', background: 'none', borderRadius: '100px' }}
              onClick={handleBackClick}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundTertiary)'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            >
              <ChevronLeft size={20} />
            </button>
          )}
          {/* {icon && (
            <div style={{ color: 'var(--color-text3)' }}>
              {icon}
            </div>
          )} */}
          {leftContent}
          {title && (
            <h1 style={{ flexShrink: 0, fontSize: '1rem', fontWeight: 500, lineHeight: '55px', margin: 0, flexShrink: 0, display: 'flex', alignItems: 'center' }}
              >{title}
            </h1>
          )}
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {renderRightContent()}
        </div>
      </div>
    </header>
  );
};

export default ScreenHeader;

          
          {/* Default actions */}
          {/* <button style={{ padding: '8px', color: '#4b5563', border: 'none', background: 'none', cursor: 'pointer' }}>
            <Bell size={20} />
          </button>
          <button style={{ padding: '8px', color: '#4b5563', border: 'none', background: 'none', cursor: 'pointer' }}>
            <Settings size={20} />
          </button>
          <div style={{ height: '32px', width: '32px', borderRadius: '50%', backgroundColor: '#e5e7eb' }}></div> */}
        
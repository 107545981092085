import React, { useContext, useEffect, useRef, useState } from 'react';
import { API, dbRefs,  } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader2';
import ReferralsComponent from '../components/ReferralsComponent';
import { Copy, User, DollarSign, HandCoins, Share, Info, Calendar, Gift, Trophy, HelpCircle } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import PartnerProgramTermsModal from '../modals/PartnerProgramTermsModal';
import { generateReferralCode, sendSlackNotification } from '../misc/utils';
import Subheader from '../components/Subheader';
import { SLACK_WEBHOOK_CHANNELS } from '../misc/constants';

const GAP_DISTANCE = '1rem'
const tabs = { yourEarnings: 'Your earnings', howItWorks: 'How it works' }
const dummyData = { 
  email: "Example referral",
  createdAt: Date.now(),
  stripeSubscription: {
    status: "active",
  },
  referralCodeUsedPaid: true
}

const ReferralsScreen = () => {
  const { user, setUser } = useContext(UserContext)
  const [data, setData] = useState([])
  const [ openPartnerProgramTermsModal, setOpenPartnerProgramTermsModal ] = useState(false)
  const [ currentTab, setCurrentTab ] = useState(tabs.yourEarnings)
  const [announcement, setAnnouncement] = useState('');
  const referralLink = 'https://schoolgoat.com/?ref=' + user?.referralCode;
  const subscribers = data.filter(item => item?.email != dummyData.email)
  const totalSubscriberCount = subscribers.length
  const paidSubscriberCount = subscribers.filter(item => item?.stripeSubscription?.status === 'active').length
  const paymentAmount = 10
  const totalEarnings = paidSubscriberCount * paymentAmount
  
  
  useEffect(() => {
    getData()
  }, [])

  ////////////////////////////// FUNCTIONS //////////////////////////////

  const getData = async () => {
    try {

      if ( user?.referralCode ) {
        let result = await axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ referralCodeUsed: user?.referralCode }),
          dbRef: dbRefs.users
        }})
        let results = result.data
        setData(results)
      }
      else {

        if ( user && user?._id ) {
          let referralCode = generateReferralCode()
          setUser({ ...user, referralCode: referralCode })
  
          await axios.put(`${API}/generalUpdateOne`, { 
            matchObj: { _id: user._id },
            updateObj: { referralCode: referralCode },
            dbRef: dbRefs.users,
          })
        }

        setData([dummyData])
      }
    } 
    catch (error) {
      setData([dummyData])
    }
  }

  const copyReferralLink = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(referralLink)
        .then(() => {
          alert('Referral link copied to clipboard!');
          const fullMessage = `Referral link copied\nEmail: ${user?.email}`;
          sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.general);
          setAnnouncement('Referral link copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
          fallbackCopyTextToClipboard(referralLink);
        });
    } 
    else {
      fallbackCopyTextToClipboard(referralLink);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'Referral link copied to clipboard!' : 'Unable to copy referral link';
      alert(msg);

      const fullMessage = `Referral link copied\nEmail: ${user?.email}`;
      sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.general);
      setAnnouncement('Referral link copied to clipboard!');
    } 
    catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      alert('Unable to copy referral link. Please copy it manually.');
    }
    document.body.removeChild(textArea);
  };


  ////////////////////////////// HOW IT WORKS (PAGE 2) //////////////////////////////

  const PartnerProgramAgreement = () => {
    if (user?.termsAcceptedPartnerProgram) {
      return (
        <section aria-labelledby="partner-program-title" style={{ backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', padding: '1rem', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <h2 id="partner-program-title" style={{fontSize: '1.2rem', marginBottom: '0.5rem', fontWeight: '600'}}>Partner Program Agreement</h2>
          <p style={{color: 'var(--color-text3)', fontSize: '1rem', marginBottom: '1rem', lineHeight: '1.5'}}>
            To participate in our referral program and receive payments, please agree to the program terms.
          </p>
          <button
            onClick={() => setOpenPartnerProgramTermsModal(true)}
            style={{backgroundColor: 'var(--color-primary)', color: 'var(--color-white)', border: 'none', borderRadius: '0.5rem', padding: '0.5rem 1rem', cursor: 'pointer', fontSize: '1rem', alignSelf: "center"}}
            aria-haspopup="dialog"
          >
            Agree to Terms
          </button>
        </section>
      );
    }
    return null;
  }

  const HowItWorks = () => {
    const data = [
      {
        title: "Program basics",
        description: `We will pay you to help bring in more users. You have a referral link already available to you. When you share your link and users use it to sign up for a paid subscription, you will get paid.`,
        icon: <Info />
      },
      {
        title: "What you get",
        description: "You get $10 for every person who signs up for a paid subscription using your link! You will be able to see everyone you've referred under 'Your Earnings'.",
        icon: <HandCoins />
      },
      {
        title: "What others get",
        description: "The people you refer will get $5 OFF (50% discount) on their first month of membership when they sign up using your link!",
        icon: <DollarSign />
      },
      {
        title: "Ways to get referrals",
        description: `• Invite your friends\n• Share with classmates & groups\n• Post to your school's Snapchat story\n• Share on your social media\n• Create Tiktoks and Reels\n• Anything you can think of!`,
        icon: <Share />
      },
      {
        title: "When you can start",
        description: "Now! As soon as you agree to the terms, you can start referring and getting paid!",
        icon: <Calendar />
      },
      {
        title: "Early success stories",
        description: `Before this program was even launched, one member earned over $100 by simply posting to her school's Snapchat story.`,
        icon: <Trophy />
      },
      {
        title: "Bonus rewards",
        description: `If you bring on a lot of new users, you will be eligible for additional prizes - such as free membership to the site, additional compensation, and more!`,
        icon: <Gift />
      },
      {
        title: "Questions",
        description: "If you have any questions about the referral program, email ryan@effortlessnotes.com. And similarly, if you want to get more involved with School GOAT - such as creating content on a regular basis - send an email as well.",
        icon: <HelpCircle />
      }
    ];
   
    const Tile = ({ item, index }) => {
      return (
        <article role="listitem" style={{display: 'flex', flexDirection: 'row', backgroundColor: 'var(--color-backgroundSecondary)', borderBottom: '1px solid var(--color-separator)', padding: '1rem', alignItems: "flex-start", position: "relative"}}>
                      {React.cloneElement(item.icon, { style: { color: 'var(--color-primary)', strokeWidth: 1.5 }, 'aria-hidden': 'true' })}

          {/* <div aria-hidden="true" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: `${COLOR_ARRAY[index]}33`, color: 'white', padding: '10px', borderRadius: '.8rem', border: 'none', fontSize: '16px', width: "55px", aspectRatio: "1/1"}}>
          </div> */}
          <div style={{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "flex-start", paddingLeft: "15px", textAlign: "left"}}>
            <h3 style={{fontWeight: '600', fontSize: '1.1rem', margin: 0}}>
              {item.title}
            </h3>
            <p style={{fontSize: '1rem', color: 'var(--color-text3)', marginTop: ".3rem", lineHeight: '1.5', whiteSpace: 'pre-line'}}>
              {item.description}
            </p>
          </div>
        </article>
      );
    };
  
    return (
      <div role="list" aria-label="List of items" style={{display: 'flex', flexDirection: 'column',}}>
        {data.map((item, index) => <Tile key={index} item={item} index={index} /> )}
      </div>
    )
  }

  const headerData = [
    { 
      title: "Total Earnings",
      value: `$${totalEarnings}`,
      icon: <DollarSign />,
    },
    { 
      title: "Referral Count",
      value: totalSubscriberCount,
      icon: <User />,
    },
    { 
      title: "Your Link",
      value: referralLink,
      icon: <Copy />,
      onClick: copyReferralLink,
    }
  ]


  return (
    <div id="wrapper">          
      <ScreenHeader title="Get Paid" icon={<HandCoins />} showBackButton={true} />

      <main id="main" style={{ backgroundColor: "var(--color-backgroundSecondary)"}}>
        <div className="inner" style={{ }}>

          <Subheader activeTab={currentTab} setActiveTab={setCurrentTab} tabs={tabs} />
      
          {currentTab === tabs.yourEarnings && (

          <div style={{ display: 'flex', flexDirection: 'column', gap: GAP_DISTANCE }}>

            <div style={{ display: 'flex', flexDirection: 'row', gap: GAP_DISTANCE, flexWrap: 'wrap' }}>
              { headerData.map((item, index) => (
                <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', padding: '24px', backgroundColor: '#ffffff', borderRadius: '16px', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', flexBasis: "200px", flexGrow: 1, cursor: item.onClick ? 'pointer' : 'default' }}
                  key={index}
                  onClick={item.onClick}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', width: "100%" }}>
                    <p style={{ fontSize: '14px', color: '#64748b', fontWeight: '500', textAlign: 'left' }}
                      >{item.title}
                    </p>
                    <p style={{ fontSize: '14px', color: '#94a3b8' }}
                      >{React.cloneElement(item.icon, { size: 14, style: { color: '#94a3b8', strokeWidth: 1.5 }, 'aria-hidden': 'true' })}
                    </p>
                  </div>
                  <p style={{ fontSize: item.value == referralLink ? '1.2rem' : '1.8rem', fontWeight: '700', }}
                    >{item.value}
                  </p>
                  </div>
                ))}
              </div>

              <ReferralsComponent data={data} setData={setData} />
            </div>
          )}

          {currentTab === tabs.howItWorks && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: GAP_DISTANCE }}>
              <PartnerProgramAgreement />
              <HowItWorks />
            </div>
          )}
          
          <PartnerProgramTermsModal open={openPartnerProgramTermsModal} setOpen={setOpenPartnerProgramTermsModal} />
          <div aria-live="polite" className="sr-only">{announcement}</div>

        </div>
      </main>
    </div>
  );  
}


export default ReferralsScreen;






  // ////////////////////////////// YOUR EARNINGS (PAGE 1) //////////////////////////////

  // const EarningsScoreboard = () => {



  //   const headerData = [
  //     { 
  //       title: "Earnings",
  //       value: `$${totalEarnings}`,
  //       icon: <HandCoins />,
  //     },
  //     { 
  //       title: "Referrals",
  //       value: totalSubscriberCount,
  //       icon: <User />,
  //     },
  //   ]

  //   const Tile = ({ item, index }) => {
  //     return (
  //       <div style={{background: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', padding: '1rem', display: 'flex', width: "100%", alignItems: 'center', textAlign: "left"}}>
  //         <div style={{background: `${COLOR_ARRAY[0]}33`, borderRadius: '50%', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '1rem'}} aria-hidden="true">
  //           {React.cloneElement(item.icon, { color: COLOR_ARRAY[0], strokeWidth: 1.5, size: 24, 'aria-hidden': 'true' })}
  //         </div>
  //         <div>
  //           <h3 style={{color: 'var(--color-text3)', fontSize: '1rem', marginBottom: '.6rem'}}>
  //             {item.title}
  //           </h3>
  //           <p style={{color: 'var(--color-text1)', fontSize: '1.5rem', fontWeight: '600', margin: 0}} aria-live="polite">
  //             {item.value}
  //           </p>
  //         </div>
  //       </div>
  //     );
  //   };

  //   return (
  //     <section aria-labelledby="earnings-summary-title" style={{ maxWidth: "1000px", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: GAP_DISTANCE}}>
  //       <h2 id="earnings-summary-title" className="sr-only">Earnings Summary</h2>
  //       {headerData.map((item, index) => (
  //         <Tile key={index} item={item} index={index} />
  //       ))}
  //     </section>
  //   )
  // }


// const TileLink = ({ item, index }) => {
//   return (
//     <div style={{background: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', padding: '1rem', display: 'flex', width: "100%", alignItems: 'center', textAlign: "left", cursor: 'pointer'}}
//       onClick={copyReferralLink}
//       onKeyPress={(e) => e.key === 'Enter' && copyReferralLink()}
//       tabIndex="0"
//       role="button"
//       aria-label="Copy referral link"
//     >
//       <div style={{background: `${COLOR_ARRAY[0]}33`, borderRadius: '50%', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '1rem'}} aria-hidden="true">
//         <Copy size={24} color={COLOR_ARRAY[0]} strokeWidth={1.5} />
//       </div>
//       <div>
//         <h2 style={{color: 'var(--color-text3)', fontSize: '1rem', marginBottom: '.6rem'}}>
//           Your link (click to copy)
//         </h2>
//         <p style={{color: 'var(--color-text1)', fontSize: '1rem', fontWeight: '600', margin: 0}}>
//           {referralLink}
//         </p>
//       </div>
//     </div>
//   );
// };




// const ReferralProcess = () => { 

//   const subheaderStyle = { color: 'var(--color-text3)', fontSize: '1rem', marginBottom: '.6rem', fontWeight: '600', }
//   const textStyle = { color: 'var(--color-text1)', fontSize: '1rem', lineHeight: '1.5' }
//   const containerStyle = { 
//     // backgroundColor: 'var(--color-background)', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', padding: '1rem', 
//     borderLeft: '3px solid var(--color-separatorOpaque)', padding: '.3rem 1rem'
//   }

//   const containerStyle2 = { 
//     backgroundColor: 'var(--color-background)', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', padding: '.5rem 1rem', 
//   }

//   const TileShareMethods = ({ item, index }) => {
//     return (
//       <div style={{ ...containerStyle2,  }}>
//         <span style={textStyle}
//           >{item.title}
//         </span>
//       </div>
//     );
//   };

//   const shareMethods = [
//     { title: 'Invite friends' },
//     { title: 'Tell classmates' },
//     { title: 'Post to school Snap' },
//     { title: 'Post on TikTok/IG' },
//   ];

  


//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: '1rem', width: "100%", maxWidth: '1000px',  borderRadius: '1rem', textAlign:  "left", gap: '2.2rem',
//       backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', padding: '1.2rem',
      
//     }}>
//       <div>
//         <h3 style={subheaderStyle}
//           >What is the referral program?
//         </h3>
//         <div style={{ ...textStyle, ...containerStyle }}
//           >We will pay you to help bring in more users. You have a referral link already available to you. When you share your link and users use it to sign up for a paid subscription, you will get credit.
//         </div>
//       </div>

//       <div>
//         <h3 style={subheaderStyle}
//           >What do you get?
//         </h3>
//         <div style={{ ...textStyle, ...containerStyle }}
//           >You will get $10 for every person who signs up for a paid subscription using your link!
//         </div>
//       </div>

//       <div>
//         <h3 style={subheaderStyle}
//           >What do your friends get?
//         </h3>
//         <div style={{ ...textStyle, ...containerStyle }}
//           >The people you refer will get a $5 discount on their first purchase when they sign up using your link!
//         </div>
//       </div>

//       <div>
//         <h3 style={subheaderStyle}
//           >Ways to refer
//         </h3>
//         <div style={{ display: 'flex', flexWrap: 'wrap', gap: '.5rem',  }}>
//           {shareMethods.map((item, index) => (
//             <TileShareMethods key={index} item={item} index={index} />
//           ))}
//         </div>
//       </div>

//       {/* <div style={{width: '100%'}}>
//         <h3 style={subheaderStyle}>Your link</h3>

//         <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', cursor: 'pointer', }} 
//           onClick={copyReferralLink}
//         >
//           <input style={{ width: '100%', cursor: 'pointer', ...textStyle, ...containerStyle2, padding: ".7rem" }} 
//             value={referralLink} 
//             readOnly 
//           />
//           <Copy style={{ position: 'absolute', right: '12px', width: '20px', height: '20px', color: 'var(--color-text3)' }} />
//         </div>
//       </div> */}
//     </div>
//   );
// };




// const ReferralProcess = () => {
//   const iconStyle = { width: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#f0f0ff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px', color: COLOR_ARRAY[0] };
//   const stepStyle = { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '33%', position: 'relative' };
//   const titleStyle = { fontSize: '16px', fontWeight: 'bold', color: '#333', marginTop: '10px', marginBottom: '5px' };
//   const descriptionStyle = { fontSize: '14px', color: '#888', textAlign: 'center', maxWidth: '80%' };

//   const data = [
//     {
//       icon: <Share />,
//       title: 'Invite friends',
//       description: 'Send your referral link to friends and tell them how useful Horizon is!',
//     },
//     {
//       icon: <User />,
//       title: 'Post to Social Media',
//       description: 'Let your friends register to our services using your personal referral code!',
//     },
//     {
//       icon: <DollarSign />,
//       title: 'Get Paid',
//       description: 'You and your friends get 2 premium Horizon features for free!',
//     },
    
//   ]


//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: '1rem', width: "100%", maxWidth: '1000px', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '1rem', padding: '1rem', border: '1px solid var(--color-separatorOpaque)',  }}>
//       <h2 style={{color: 'var(--color-text1)', fontSize: '1rem', marginBottom: '.6rem', fontWeight: '600'}}
//         >Make money with Effortless Notes
//       </h2>
//       <div style={{ display: 'flex', justifyContent: 'space-between', color: 'var(--color-text1)', marginBottom: '15px', fontSize: '1rem', lineHeight: '1.5', textAlign: 'left' }}>
//         <div style={{ width: '48%',  }}>
//           <h3 style={{ marginBottom: '10px', color: 'var(--color-text3)' }}>What You Get</h3>
//           <p>You and your friend will get 2 premium features for free when they sign up!</p>
//         </div>
//         <div style={{ width: '48%' }}>
//           <h3 style={{ marginBottom: '10px', color: 'var(--color-text3)',  }}>How to Refer</h3>
//           <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
//             <li>Invite friends directly</li>
//             <li>Post to your school's Snapchat</li>
//             <li>Share on your social media</li>
//           </ul>
//         </div>
//       </div>
//       <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', cursor: 'pointer' }} onClick={copyReferralLink}>
//             <input style={{ width: '100%', padding: '12px', paddingRight: '40px', border: '1px solid var(--color-separatorOpaque)', borderRadius: '4px', backgroundColor: 'var(--color-background)', color: 'var(--color-text1)', fontSize: '0.9rem', cursor: 'pointer' }} 
//               value={referralLink} 
//               readOnly 
//             />
//             <Copy style={{ position: 'absolute', right: '12px', width: '20px', height: '20px', color: 'var(--color-text3)' }} />
//           </div>
//     </div>
//   );
// };

        {/* <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>

        {data.map((item, index) => (
          <div style={stepStyle} key={index}>
            <div style={iconStyle}>{React.cloneElement(item.icon, { color: '#5656ff', strokeWidth: 1.5, size: 24 })}</div>
            <h3 style={titleStyle}>{item.title}</h3>
            <p style={descriptionStyle}>{item.description}</p>
          </div>
        ))}
      </div> */}



          {/* <div style={{ marginBottom: '20px', padding: '20px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: '1000px' }}>
            <p style={{ fontSize: '1rem', color: 'var(--color-text3)', marginBottom: '20px', lineHeight: '1.5', textAlign: 'left' }}
              >For every person who signs up for a membership using your link, you'll receive $10 credit to your account. Your friend will also get a 10% discount on their first purchase!
            </p>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', cursor: 'pointer' }} onClick={copyReferralLink}>
              <input style={{ width: '100%', padding: '12px', paddingRight: '40px', border: '1px solid var(--color-separatorOpaque)', borderRadius: '4px', backgroundColor: 'var(--color-background)', color: 'var(--color-text1)', fontSize: '0.9rem', cursor: 'pointer' }} 
                value={referralLink} 
                readOnly 
              />
              <Copy style={{ position: 'absolute', right: '12px', width: '20px', height: '20px', color: 'var(--color-text3)' }} />
            </div>
          </div> */}


{/* <div style={{ marginBottom: '20px', padding: '20px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', display: 'flex', justifyContent: 'space-between', maxWidth: '1000px' }}>
            <div style={{ textAlign: 'center', flex: 1 }}>
              <h3 style={{ fontSize: '1rem', color: 'var(--color-text1)', marginBottom: '10px' }}
                >Your Earnings
              </h3>
              <p style={{ fontSize: '2rem', fontWeight: 'bold', color: 'var(--color-primary)' }}
                >${data.filter(item => item?.stripeSubscription?.status === 'active').length * 10}
              </p>
            </div>
            <div style={{ textAlign: 'center', flex: 1, borderLeft: '1px solid var(--color-separatorOpaque)' }}>
              <h3 style={{ fontSize: '1rem', color: 'var(--color-text1)', marginBottom: '10px' }}
                >Successful Referrals
              </h3>
              <p style={{ fontSize: '2rem', fontWeight: 'bold', color: 'var(--color-primary)' }}
                >{data.filter(item => item?.stripeSubscription?.status === 'active').length}
              </p>
            </div>
          </div> */}






          {/* <div style={{ marginBottom: '20px', padding: '20px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem' }}>
            <h3 style={{ marginBottom: '10px', color: 'var(--color-text1)' }}
              >Referral Program
            </h3>
            <p style={{ color: 'var(--color-text2)', marginBottom: '15px' }}
                >Share School GOAT with your friends and earn rewards! Here's how it works:
            </p>
            <ul style={{ color: 'var(--color-text2)', paddingLeft: '20px' }}>
              <li style={{ marginBottom: '10px' }}
                >For every friend who signs up using your referral link, you'll receive a 10% discount on your next month's subscription.
              </li>
              <li style={{ marginBottom: '10px' }}
                >Your friend will also get a 10% discount on their first month's subscription.
              </li>
              <li style={{ marginBottom: '10px' }}
                >There's no limit to how many friends you can refer or how many discounts you can earn!
              </li>
            </ul>
            <p style={{ color: 'var(--color-text2)', marginTop: '15px' }}
              >Start sharing your referral link today and help your friends discover the power of AI-assisted note-taking!
            </p>
          </div> */}
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { Upload, X, CheckCircle, ChevronDown } from 'lucide-react';
import axios from 'axios';
import { API, MIXPANEL_EVENTS, PAYWALL_TYPES } from '../misc/constants';
import { ESSAY_PARAMETERS, ESSAY_TYPES } from './Essay_EvaluationParameters';
import { useBreakpoint } from '../misc/useBreakpoint';
import mixpanel from 'mixpanel-browser';


const Essay_EvaluationInput = ({ isActive, responses, setResponses, tabs, setActiveTab }) => {
  const { user, checkUserPermission } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint()
  const [essayText, setEssayText] = useState(``);
  const [ prompt, setPrompt ] = useState('');
  const [ classType, setClassType ] = useState('');
  const [ gradeLevel, setGradeLevel ] = useState('');
  const [ essayTypeId, setEssayTypeId ] = useState(ESSAY_TYPES.general._id);
  const [ evaluationParamsShown, setEvaluationParamsShown ] = useState([]);
  const [ evaluationParamsShowAll, setEvaluationParamsShowAll ] = useState(false);
  const fontSize = isPhoneAndBelow ? '16px' : '1rem'
  const textInputStyle = { padding: '1rem 1.2rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', fontSize: fontSize, color: `var(--color-text1)`, display: 'flex', flex: 1, fontWeight: "400", boxShadow: "var(--borderShadow)" }
  const isSubmittable = essayText.length > 10 && responses.length > 0


  useEffect(() => {
    if ( isActive ) {
      let essayTypeObj = ESSAY_TYPES[essayTypeId]
      let params = essayTypeObj?.parameters || []
      setResponses(params)

      if ( evaluationParamsShowAll ) {
        setEvaluationParamsShown(Object.values(ESSAY_PARAMETERS))
      }
      else {
        setEvaluationParamsShown(params)
      }
    }
  }, [essayTypeId, evaluationParamsShowAll, isActive])


  const onSelectEvaluationParameter = (item) => {
    if ( responses.map(p => p.title).includes(item.title) ) {
      setResponses(prevParams => prevParams.filter(param => param.title !== item.title))
    }
    else {
      setResponses(prevParams => [...prevParams, item])
    }
  }



  const handleSubmit = async (e) => {    
    e.preventDefault();

    if ( !await checkUserPermission(PAYWALL_TYPES.essay_evaluation) ) return;

    try {
      setActiveTab(tabs.evaluation)

      const results = await Promise.all(responses.map(async (param) => {
        const result = await axios.post(`${API}/evaluateEssay2`, { 
          essayText: essayText, 
          evaluationObj: param
        });
        return result?.data?.content;
      }));

      const filteredResults = results.filter(result => result !== null);
      console.log(filteredResults)

      setResponses(filteredResults)

      mixpanel.track(MIXPANEL_EVENTS.essay_evaluation, {
        user_id: user?._id,
        user_email: user?.email,
      });
    }
    catch (error) {
      console.error(error)
      setActiveTab(tabs.input)
    }
  };

  
  if ( !isActive ) return null;

  return (

    <form onSubmit={handleSubmit}>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem',  }}>

        <div style={{ position: 'relative', width: '100%', }}>

          <select style={{ ...textInputStyle, width: '100%', backgroundColor: 'var(--color-backgroundSecondary)', appearance: 'none' }}
            value={essayTypeId}
            onChange={(e) => setEssayTypeId(e.target.value)}
          >
            <option value="">Select essay type</option>
            {Object.values(ESSAY_TYPES).map((t, i) => ( <option key={i} value={t._id}>{t.title}</option> ))}
          </select>

          <ChevronDown size={24} style={{ position: 'absolute', right: '1rem', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />

        </div>

        <textarea style={{ ...textInputStyle, flex: null, display: "block", height: "20rem", fontFamily: "inherit", color: 'var(--color-text3)', lineHeight: '1.3' }}
          value={essayText}
          onChange={(e) => setEssayText(e.target.value)}
          placeholder="Paste your essay text here"
        />

        { responses.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: ".5rem",  }}>  
            { responses.map((item, index) => (
              <div key={index} style={{ padding: ".5rem", backgroundColor: "var(--color-background)", borderRadius: ".5rem", border: "1px solid var(--color-separatorOpaque)", fontSize: "0.9rem", color: "var(--color-text2)", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", boxShadow: "var(--borderShadow)" }}>
                {item.title}
                <X size={16} color='var(--color-text2)' strokeWidth={1.5} style={{ cursor: "pointer", marginLeft: ".5rem" }} onClick={() => onSelectEvaluationParameter(item)} />
              </div>
            ))}
          </div>
        )}

        <button style={{ padding: '1rem', backgroundColor: isSubmittable ? 'var(--color-primary)' : 'var(--color-primaryLight)', color: 'var(--color-textButton)', border: 'none', borderRadius: '30px', cursor: isSubmittable ? 'pointer' : 'not-allowed', marginTop: '1rem', fontSize: '1rem', width: '10rem', alignSelf: 'center' }}
          disabled={!isSubmittable}
          type="submit" 
          onMouseEnter={(e) => {
            if (isSubmittable) {
              const button = e.target;
              button.style.backgroundColor = 'var(--color-primaryLight)';
            }
          }}
          onMouseLeave={(e) => {
            if (isSubmittable) {
              const button = e.target;
              button.style.backgroundColor = 'var(--color-primary)';
            }
          }}
          >Analyze Essay
        </button>

      </div>
    </form>
  );  
}


export default Essay_EvaluationInput;



import React, { useState, useEffect, useRef, useContext } from 'react';
import { MessageCircle } from 'lucide-react';
import { OPENAI_CHAT_ROLES, API, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import axios from 'axios';
import { useBreakpoint } from '../misc/useBreakpoint';
import { CircularProgress } from '@mui/material';
import { sendSlackNotification } from '../misc/utils';
import { UserContext } from '../contexts/UserProvider';

const Tile = ({ item }) => {
  const isUser = item.role === OPENAI_CHAT_ROLES.user;

  return (
    <div style={{ display: 'flex', justifyContent: isUser ? 'flex-end' : 'flex-start',  }}
    >
      <div style={{ maxWidth: '80%', padding: '12px', borderRadius: '8px', backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text2)', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', lineHeight: '1.2',
      ...isUser && { backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', boxShadow: 'none', border: 'none', }
      }}>
        <p style={{ margin: '0', fontSize: '.9rem' }}>{item.content}</p>
      </div>
    </div>
  )
}


const Chat = ({ styleOverride = {}}) => {
  const { user } = useContext(UserContext);
  const { isMobileAndBelow } = useBreakpoint();
  const [ isActive, setIsActive ] = useState(false);
  const [messages, setMessages] = useState([{ content: "Hey, I'm here to answer any questions you have about School GOAT. How can I help you today?", role: OPENAI_CHAT_ROLES.assistant }]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const textInputRef = useRef(null);


  useEffect(() => {
    if (isActive) {
      textInputRef.current?.focus();
    }
  }, [isActive]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {

      let updatedMessages = [...messages, { content: newMessage, role: OPENAI_CHAT_ROLES.user }];
      setMessages(updatedMessages);
      setNewMessage("");
      await getData(updatedMessages);
    }
  };

  const getData = async (messages) => {
    try {
      setIsLoading(true);
      const result = await axios.post(`${API}/generateChat`, { messages: messages });
      const results = result?.data?.content;
      console.log(results)
      setMessages([...messages, { content: results, role: OPENAI_CHAT_ROLES.assistant }]);


      const lastMessage = messages[messages.length - 1].content;
      await sendSlackNotification(`${user.email} (chat)\n- ${lastMessage}\n> ${results}`, SLACK_WEBHOOK_CHANNELS.general);
    } 
    catch (error) {
      console.error(error);
      alert("Something went wrong. Please try again.");
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <>

        <button style={{ position: "fixed", zIndex: 1000,bottom: '1rem', right: isMobileAndBelow ? '1rem' : '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', height: '36px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'var(--color-text1)',color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)', gap: '4px', ...styleOverride,  }}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          onClick={() => setIsActive(!isActive)}
          >
          <MessageCircle size={15} />
          Questions
          </button>

      {isActive && (

<div style={{ position: 'fixed', bottom: '4rem', right: isMobileAndBelow ? '1rem' : '2rem', zIndex: 1000, display: 'flex', flexDirection: 'column', height: "80%", width: "90%", maxWidth: "500px", maxHeight: "700px", backgroundColor: "var(--color-backgroundSecondary)", borderRadius: "1rem", boxShadow: "var(--borderShadow)", border: "1px solid var(--color-separatorOpaque)" }}>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid var(--color-separatorOpaque)', paddingBottom: '.5rem', padding: '1rem' }}>
        <h3 style={{ fontSize: '1rem', fontWeight: 500, color: 'var(--color-text1)', margin: 0 }}>School GOAT</h3>
        <button style={{ color: 'var(--color-text3)', background: 'none', border: 'none', cursor: 'pointer', display: 'flex' }}
          onClick={() => setIsActive(false)}
        >
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', flex: 1, overflowY: 'auto', paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', paddingBottom: '0rem' }}>
          {messages.map((message, index) => <Tile key={index} item={message} /> )}
          {isLoading && <CircularProgress size={13} color={"grey"} style={{ marginLeft: '8px' }} />}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      <div style={{ padding: '1rem' }}>
        <form style={{ maxWidth: '896px', margin: '0 auto', display: 'flex', gap: '8px' }}
          onSubmit={sendMessage} 
        >
          <input style={{ flex: 1, padding: '8px 12px', border: '1px solid var(--color-separatorOpaque)', borderRadius: '4px', fontSize: isMobileAndBelow ? '16px' : '.9rem', outline: 'none' }}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            ref={textInputRef}
          />
          <button style={{ padding: '8px', backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', borderRadius: '4px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            type="submit" 
          >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="22" y1="2" x2="11" y2="13"></line>
              <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
            </svg>
          </button>
          </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
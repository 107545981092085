import './css/App.css';
import './css/Drawer.css';
import React, { useContext, useEffect, useState } from 'react';
import { Routes } from './navigation/routes';
import { UserContext, UserProvider } from './contexts/UserProvider';
import ErrorScreen from './screens/ErrorScreen';
import { createBrowserRouter, Navigate, redirect, RouterProvider } from "react-router-dom";
import ProtectedRoute from './navigation/ProtectedRoute';
import TermsScreen from './screens/TermsScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicy';
import { ThemeProvider } from '@mui/material';
import theme from './contexts/ThemeProvider';
import ReactGA from "react-ga4";
import LandingScreen from './screens/LandingScreen4';
import OptionsScreen from './screens/OptionsScreen';
import ReferralsScreen from './screens/ReferralsScreen';
import TermsPartnerProgramScreen from './screens/TermsPartnerProgramScreen';
import NotesFeedbackScreen from './screens/NotesFeedbackScreen';
import SolveScreen from './screens/SolveScreen2';
import EssayEvaluationScreen from './screens/EssayEvaluationScreen';
import ResourcesProfileScreen from './screens/ResourcesProfileScreen2';
import JoinScreen from './screens/JoinScreen';
import AIDetectionScreen from './screens/AIDetectionScreen';
// import CheckoutScreen from './screens/CheckoutScreen';
import EssayScreen from './screens/EssayScreen';
import NotesScreen from './screens/NotesScreen3';
import StudyScreen from './screens/StudyScreen';
import WithHomeHeader from './navigation/WithHomeHeader';
import WritingScreen from './screens/WritingScreen';
import CheckoutCompleteScreen from './screens/CheckoutCompleteScreen';

ReactGA.initialize("G-54F08M4G3Z");

const App = () => {

  const router = createBrowserRouter([

    { path: '/', element: <LandingScreen />, errorElement: <ErrorScreen /> },
    
    {
      path: Routes.APP, 
      element: <ProtectedRoute />,
      errorElement: <ErrorScreen />,
      children: [
        { index: true, element: <Navigate to={Routes.NOTES} replace /> },

        { element: <WithHomeHeader />, children: [          
          { path: Routes.NOTES, element: <NotesScreen /> },
          { path: Routes.STUDY, element: <StudyScreen /> },
          { path: Routes.SOLVE, element: <SolveScreen /> },
          { path: Routes.WRITING, element: <WritingScreen /> },
          { path: Routes.OPTIONS, element: <OptionsScreen /> },
        ]},

        { path: Routes.ESSAY, element: <EssayScreen /> },
        { path: Routes.ESSAY_EVALUATION, element: <EssayEvaluationScreen /> },
        { path: Routes.AI_DETECTION, element: <AIDetectionScreen /> },
                
        
        { path: Routes.REFERRALS, element: <ReferralsScreen /> },
        
        
        { path: Routes.RESOURCES_PROFILE, element: <ResourcesProfileScreen /> },
        { path: Routes.JOIN, element: <JoinScreen /> },
        

        // { path: Routes.CHECKOUT_COMPLETE, element: <CheckoutCompleteScreen /> },

        // { index: true, element: <Navigate to={Routes.HOME} replace /> },
        // { path: Routes.ESSAY_OUTLINE, element: <EssayOutlineScreen /> },
        // { path: Routes.SHORTCUT_READING, element: <ShortcutReadingScreen /> },
        // { path: Routes.HOME, element: <AppHomeScreen />  },
        // { path: Routes.NOTES_IN_CLASS, element: <NotesInClassScreen /> },
        // { path: Routes.UPLOAD_FILES, element: <NotesConvertScreen /> },
        // { path: Routes.FLASHCARDS, element: <FlashcardsScreen /> },
        // { path: Routes.QUIZ, element: <QuizScreen /> },
        // { path: Routes.MEMBERSHIP, element: <MembershipScreen /> },
        // { path: Routes.TEACH, element: <TeachScreen /> },

      ]
    },
    { path: Routes.PRIVACY, element: <PrivacyPolicyScreen /> },
    { path: Routes.TERMS, element: <TermsScreen /> },
    { path: Routes.TERMS_PARTNER_PROGRAM, element: <TermsPartnerProgramScreen /> },
    { path: Routes.NOTES_FEEDBACK, element: <NotesFeedbackScreen /> },
    { path: '*', element: <Navigate to="/" replace /> }

    // { path: 'home', element: <AppHomeScreen /> },
    // { path: Routes.CHECKOUT, element: <CheckoutScreen /> },
    // { path: '/emailtest', element: <EmailTest />, errorElement: <ErrorScreen /> },    

  ]);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;




    // {
    //   path: '/writeassist', 
    //   element: <JennyScreen />,
    //   errorElement: <ErrorScreen />,
    // },
    // {
    //   path: '/maninfinance', 
    //   element: <DataScreen />,
    //   errorElement: <ErrorScreen />,
    // },
'use client';
import React, { useState } from 'react';
import { Drawer } from 'vaul';
import { OPENAI_CHAT_ROLES } from '../misc/constants';

const SolverDemoModal = ({ open, setOpen, handleSubmit }) => {

  const demoImage = "https://academicweapon.s3.us-east-1.amazonaws.com/admin/Solver_Demo.png"


  const onSubmit = () => {
    setOpen(false)

    const newMessage = {
      role: OPENAI_CHAT_ROLES.user,
      content: [{ type: "image_url", image_url: { url: demoImage } }]
    }

    handleSubmit(newMessage)
  }

  return (
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: '450px', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '32px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Handle style={{ marginBottom: '2rem' }}/>

            <Drawer.Title className="modalTitle"
              id="drawer-title"
              >Solver Demo
            </Drawer.Title>
            <Drawer.Description className="modalDescription" 
              id="drawer-description"
              >School GOAT will solve any problem for you. Just take a screenshot of it and put it into the solver. This is an example screenshot we took of a homework problem. Click "Go!" to watch the magic!
            </Drawer.Description>

            <img style={{width: '100%', borderRadius: '8px', marginTop: '1rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)'}}
              alt="Demo of the solver feature"
              src={demoImage} 
            />
            <button className="modalButton"
              onClick={onSubmit}
              >Go!
            </button>
          </div>

        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default SolverDemoModal;

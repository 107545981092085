import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { API, dbRefs, RESOURCES_UPLOAD_METHODS, S3_URL_PREFIX, PAYWALL_TYPES, RESOURCE_SOURCE_TYPES, RESOURCE_GENERATE_TYPES } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader2';
import { getTextFromResource, initResourceObj, uploadResourceToS3 } from '../misc/utils';
import Resource_Quiz from '../components/Resource_Quiz';
import { ChevronDown, Edit, FileText, Plus, PlusIcon, X } from 'lucide-react';
import SelectResourceModal from '../modals/SelectResourceModal';
import ContentCreate from '../components/ContentCreate3';
import { LoadingComponent } from '../components/Resource_Components';
import Subheader from '../components/Subheader';
import Resource_Flashcards from '../components/Resource_Flashcards';
import Resource_Notes from '../components/Resource_Notes';
import CustomSelect from '../components/CustomSelect';
import { useBreakpoint } from '../misc/useBreakpoint';

const tabs = {
  quiz: { _id: RESOURCE_GENERATE_TYPES.quiz, title: 'Quiz', shortTitle: 'Quiz' },
  flashcards: { _id: RESOURCE_GENERATE_TYPES.flashcards, title: 'Flashcards', shortTitle: 'Cards' },
  notes: { _id: RESOURCE_GENERATE_TYPES.notes, title: 'Study Guide', shortTitle: 'Notes' },
}

const StudyScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext)
  const { isMobileAndBelow } = useBreakpoint()
  const [ data, setData ] = useState(null)
  const [ contentCreateModalOpen, setContentCreateModalOpen ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ activeTab, setActiveTab ] = useState(tabs.quiz)

  useEffect(() => {
    getData()
  }, [])

  const getData = async (resource_id, tab) => {
    let results = null;

    try {
      if ( resource_id ) {
        const result = await axios.get(`${API}/generalFindOne`, { params: { queryObj: JSON.stringify({ _id: resource_id }), dbRef: dbRefs.resources }})
        results = result?.data
      }
      else if ( tab?._id === tabs.quiz._id || !tab ) {
        const result = await axios.get(`${API}/generalFindOne`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['quiz.content']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, limit: 1 }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }
      else if ( tab?._id === tabs.flashcards._id ) {
        const result = await axios.get(`${API}/generalFindOne`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['flashcards.content']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, limit: 1 }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }
      else if ( tab?._id === tabs.notes._id ) {
        const result = await axios.get(`${API}/generalFindOne`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['notes.urlText']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, limit: 1 }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }

      setData(results)
      console.log('results', results)
    }
    catch (error) {
      console.log('error', error)
      setError(true)
    }
  }

  const getDataForSelect = async () => {
    let results = null;

    try {
      if ( activeTab._id === tabs.quiz._id ) {
        const result = await axios.get(`${API}/generalFind`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['quiz.content']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }
      else if ( activeTab._id === tabs.flashcards._id ) {
        const result = await axios.get(`${API}/generalFind`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['flashcards.content']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }
      else if ( activeTab._id === tabs.notes._id ) {
        const result = await axios.get(`${API}/generalFind`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['notes.urlText']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }

      return results
    }
    catch (error) {
      setError(true)
    }
  }

  const handleCreateContent = async ({ 
    resourceSourceType, 
    resourceGenerateType, 
    value,
  }) => {      

    try {

      if ( resourceSourceType !== RESOURCES_UPLOAD_METHODS.existingResource && !await checkUserPermission(resourceGenerateType) ) {
        setContentCreateModalOpen(false)
        return;
      }
      
      setLoading(true)
      // setContentCreateModalOpen(false)

      let text = ''
      let topic = ''
      let doc = null

      if ( resourceSourceType === RESOURCES_UPLOAD_METHODS.existingResource ) {
        doc = value
        text = await getTextFromResource(value)
      }

      else if ( resourceSourceType === RESOURCES_UPLOAD_METHODS.file ) {
        doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: value, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        text = sourceContent?.data?.content
      }

      else if ( resourceSourceType === RESOURCES_UPLOAD_METHODS.text ) {
        doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: value, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        text = sourceContent?.data?.content
      }

      else if ( resourceSourceType === RESOURCES_UPLOAD_METHODS.topic ) {
        doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: value, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        const sourceContent = await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
        topic = sourceContent?.data?.content
      }

      switch (resourceGenerateType) {
        case RESOURCE_GENERATE_TYPES.notes:
          await axios.post(`${API}/generateNotes`, { file_id: doc._id });
          break;
        case RESOURCE_GENERATE_TYPES.flashcards:
          await axios.post(`${API}/generateFlashcards`, { text: text, topic: topic, resource_id: doc._id });
          break;
        case RESOURCE_GENERATE_TYPES.quiz:
          await axios.post(`${API}/generateQuiz`, { text: text, topic: topic, resource_id: doc._id });
          break;
        case RESOURCE_GENERATE_TYPES.teach:
          await axios.post(`${API}/generateTeach`, { text: text, topic: topic, resource_id: doc._id });
          break;
      }

      await getData(doc._id, null) 
      setContentCreateModalOpen(false)
      setActiveTab(tabs[Object.keys(tabs).find(key => tabs[key]._id === resourceGenerateType)])
    } 
    catch (error) {
      setError(true)
    }
    finally {
      setLoading(false)
    }
  } 

  const handleTabChange = async (tab) => {
    try {
      setActiveTab(tab)
      await getData(null, tab)
    }
    catch (error) {
      setError(true)
    }
  }

  return (
    <div id="wrapper">
      <div id="main" >
      <div className="inner" >

          { loading ? 
            <LoadingComponent loading={loading} title="Generating your materials" subtitle="This may take ~10 seconds" />

            : error ?
              <p style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>An error occurred. Please try again.</p>  

            : contentCreateModalOpen ?
              <ContentCreate
                isActive={contentCreateModalOpen}
                setIsActive={setContentCreateModalOpen}
                onSubmit={handleCreateContent}
              />
            :
            <div style={{ display: 'flex', flexDirection: 'column',  }}>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <Subheader 
                  tabs={tabs} 
                  activeTab={activeTab} 
                  setActiveTab={handleTabChange} 
                  containerStyle={{ marginBottom: 0 }} 
                  tabContainerStyle={ isMobileAndBelow ? { padding: '8px' } : {} }
                />

                <div style={{ paddingLeft: '.75rem', paddingRight: '1rem', backgroundColor: 'var(--color-text1)', borderRadius: '.5rem', color: 'var(--color-textButton)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '.5rem', height: '100%' }}
                  onClick={() => setContentCreateModalOpen(true)}
                  onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                  onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                >
                  <PlusIcon size={20} color='var(--color-textButton)' strokeWidth={1.5} />
                  <span style={{ fontSize: '1rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',  }}
                    >{'Create new'}
                  </span>
                  
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>

                <CustomSelect
                  value={data}
                  onChange={(value) => setData(value)}
                  getDisplayField={(item) => item?.title}
                  getIdField={(item) => item?._id}
                  getData={getDataForSelect}
                />


              </div>

              <Resource_Quiz isActive={activeTab._id === tabs.quiz._id } data={data} />
              <Resource_Flashcards isActive={activeTab._id === tabs.flashcards._id} data={data} />
              <Resource_Notes isActive={activeTab._id === tabs.notes._id} data={data} />

            </div>
          }
 
        </div>
      </div>
    </div>
  );  
}


export default StudyScreen;

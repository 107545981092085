'use client';
import { Drawer } from 'vaul';
import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, S3_URL_PREFIX, RESOURCES_UPLOAD_METHODS, SEARCH_PARAMS, RESOURCE_GENERATE_TYPES, PAYWALL_TYPES } from '../misc/constants';
import { initResourceObj, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import ResourceProcessingModal from '../modals/ResourceProcessingModal';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';

const TextUploader = ({  }) => {
  const { isPhoneAndBelow } = useBreakpoint();
  const { user, checkUserPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const textareaRef = useRef(null);
  const [openResourceProcessingModal, setOpenResourceProcessingModal] = useState(false);
  const [ isUploading, setIsUploading ] = useState(false)

  // useEffect(() => {
  //   if (textareaRef.current) {
  //     textareaRef.current.focus();
  //   }
  // }, []);


  const handleClose = () => {
    setFile('');
  };

  const uploadFile = async () => {
    try {
      setIsUploading(true)

      if ( !await checkUserPermission(PAYWALL_TYPES.notes) ) {
        handleClose();
        setIsUploading(false)
        return;
      }
      
      const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      await uploadResourceToS3({ resource_id: doc._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories  })
      handleClose();
      navigate(`/app/resources/${doc._id}?${SEARCH_PARAMS.tab}=${RESOURCE_GENERATE_TYPES.notes}&${SEARCH_PARAMS.generate}=true`)
    } 
    catch (error) {
      console.error('Error uploading file:', error);
      alert(error.message);
    }
    finally {
      setIsUploading(false)
    }
  };

  return (
    <>
      <textarea ref={textareaRef} style={{  display: 'flex', flexDirection: 'column', gap: '1rem', height: '340px', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: '1rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', alignItems: 'center', justifyContent: 'center', resize: 'none', fontSize: isPhoneAndBelow ? '16px' : '1rem', color: 'var(--color-text3)', lineHeight: '1.4', outline: 'none' }} 
        placeholder="Paste text from your class materials (readings, notes, etc.). We'll convert it to structured, easy to study, notes..." 
        value={file} 
        onChange={(e) => setFile(e.target.value)} 
        aria-label="Text to convert" 
      />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', gap: '1rem', position: 'relative' }}>
        <button style={{backgroundColor: 'var(--color-text1)', color: 'white', border: 'none', borderRadius: '.5rem', padding: '1rem 2rem', fontSize: '1rem', fontWeight: '600', cursor: 'pointer', transition: 'all 0.2s ease-in-out', opacity: file ? 1 : 0}}
          onClick={uploadFile}
          disabled={!file || isUploading}
          onMouseEnter={(e) => { if (!isUploading && file) e.target.style.opacity = '0.7' }}
          onMouseLeave={(e) => { if (!isUploading && file) e.target.style.opacity = '1' }}
          >
            <span style={{opacity: isUploading ? 0 : 1}}>Convert to Notes</span>
        </button>

        { isUploading && (
          <div style={{ display: 'flex', position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <div style={{ width: '20px', height: '20px', border: '3px solid var(--color-text1)', borderTop: '3px solid var(--color-textButton)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
            <style>{`@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}`}</style>
          </div>
        )}

      </div>
    </>
    
  );
};

export default TextUploader;


                {/* { isUploading ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem' }}>
                  <div style={{ width: '40px', height: '40px', border: '3px solid var(--color-separatorOpaque)', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
                </div>
                <style>
                  {`
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                  `}
                </style>
              </>
            ) : (
              <div style={buttonContainerStyle}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button 
                  onClick={uploadFile} 
                  disabled={!file} 
                  variant="contained"
                  aria-label="Convert to Notes"
                  aria-disabled={!file}
                >
                  Upload
                </Button>
              </div>
            )} */}
              
        // <ResourceProcessingModal open={openResourceProcessingModal} setOpen={setOpenResourceProcessingModal} />

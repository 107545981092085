import React, { useState, useContext, useEffect, useRef } from 'react';
import { Check, Star, ChevronDown, ChevronUp, CheckCircle2, User, File, TrendingUp, Clock, CircleCheckBig, X } from 'lucide-react';
import { UserContext } from '../contexts/UserProvider';
import axios from 'axios';
import { API, dbRefs, PAYWALL_EVENTS, PAYWALL_TYPES } from '../misc/constants';
import { createCheckoutSession_v3, logPaywallAction } from '../misc/utils';
import { useBreakpoint } from '../misc/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import Subheader from '../components/Subheader';
import Chat from '../components/Chat';

const tabs = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
}

const products = [
  {
    _id: '1',
    name: 'Solver',
    description: 'For those who just want the solver',
    features: [
      'Assignment solver',
      // 'Eligible for referral program',
    ],
    prices: [
      {
        _id: 'price_1QRFw8LkURBDGvXaC9d7MvIW',
        frequency: tabs.weekly,
        basePrice: 1.99,
      },
      {
        _id: 'price_1QRFw8LkURBDGvXaOW3AcR1K',
        frequency: tabs.monthly,
        basePrice: 4.99,
      },
      {
        _id: 'price_1QRFw8LkURBDGvXaL1i1M65t',
        frequency: tabs.yearly,
        basePrice: 49.00,
      }
    ]
  },
  {
    _id: '2',
    name: 'Standard',
    description: 'Our most popular plan',
    features: [
      'Access to ALL tools',
      'Download your notes',
      'Standard usage rates',
      'Standard support',
    ],
    isHighlighted: true,
    prices: [
      {
        _id: 'price_1QRFwzLkURBDGvXa0K22m6ns',
        frequency: tabs.weekly,
        basePrice: 3.99,
      },
      {
        _id: 'price_1QRFwVLkURBDGvXa2BfNPl1j',
        frequency: tabs.monthly,
        basePrice: 9.99,
      },
      {
        _id: 'price_1QSXc1LkURBDGvXaAjCE37zW',
        frequency: tabs.yearly,
        basePrice: 99.00,
      }
    ]
  },
  {
    _id: '3',
    name: 'Unlimited',
    description: 'Our most powerful plan',
    features: [
      'Everything in Standard, plus:',
      'Unlimited usage',
      'Priority support',
    ],
    prices: [
      {
        _id: 'price_1QRFxlLkURBDGvXaBCNul5Ff',
        frequency: tabs.weekly,
        basePrice: 5.99,
      },
      {
        _id: 'price_1QRFxlLkURBDGvXaOqtpB6Av',
        frequency: tabs.monthly,
        basePrice: 14.99,
      },
      {
        _id: 'price_1QRFxlLkURBDGvXa0DfWJ3NX',
        frequency: tabs.yearly,
        basePrice: 149.00,
      }
    ]
  }
];


const Tile_Plan = ({ item, index, handleClick, activeTab }) => {

  const plan = item.prices.find(price => price.frequency === activeTab);
  console.log(item)

  if (!plan) return null;

  const isHighlighted = item.isHighlighted;

  const weeklyPrice = item.prices.find(price => price.frequency === tabs.weekly)?.basePrice;
  const annualizedWeeklyPrice = weeklyPrice * 52;

  const annualizedPlanPrice = plan.basePrice * (plan.frequency === tabs.weekly ? 52 : plan.frequency === tabs.monthly ? 12 : 1);
  // const percentageSaved = ((annualizedWeeklyPrice - annualizedPlanPrice) / annualizedWeeklyPrice * 100).toFixed(0);
  const priceSubstring = plan.frequency === tabs.weekly ? '/week' : plan.frequency === tabs.monthly ? '/month' : '/year';
  const weeklyPlanPrice = annualizedPlanPrice / 52;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexShrink: 0, flexBasis: '320px', background: 'white', borderRadius: '.5rem', padding: '1.7rem', boxShadow: 'var(--borderShadow)', border: isHighlighted ? '2px solid var(--color-black)' : '1px solid var(--color-separatorOpaque)', position: 'relative', 
        // margin: plan.title !== 'Standard' ? '1.5rem 0' : 0,
      }}
    >
      <div style={{ }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
          <h3 style={{ fontSize: '1.3rem', fontWeight: 'bold' }}
            >{item.name}
          </h3>
          {/* <div style={{ fontSize: '.8rem', fontWeight: 500, color: 'var(--color-primary)', marginLeft: '8px', background: '#EEE7FE', padding: '6px', borderRadius: '.3rem', opacity: percentageSaved && percentageSaved > 0 ? 1 : 0 }}
            >{percentageSaved && percentageSaved > 0 ? `Save ${percentageSaved}%` : 'l'}
          </div>  */}
        </div>
        <p style={{ color: 'var(--color-text3)', marginBottom: '16px' }}
          >{item.description}
        </p>
        <div style={{ fontSize: '2.45rem', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start' }}
          >${Math.floor(plan.basePrice)}{plan.basePrice % 1 > 0 && <sup style={{ fontSize: '.8rem', position: 'relative', top: '0.3rem', }}>{(plan.basePrice % 1).toFixed(2).substring(2)}</sup>}<span style={{ fontSize: '1rem', color: 'var(--color-text1)', fontWeight: 'normal', alignSelf: 'flex-end', marginBottom: '4px' }}>{priceSubstring}</span>
        </div>

        <div style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '4px' }}>
          Typical price: <span style={{ textDecoration: 'line-through' }}>${(plan.basePrice * 1.5).toFixed(2)}</span>
        </div>
      </div>

      <ul style={{ marginTop: '1rem' }}>
        {item.features.map((feature, featureIndex) => (
          <li key={featureIndex} style={{ display: 'flex', alignItems: 'center', marginTop: '.8rem' }}>
            <Check style={{ width: '1.25rem', height: '1.25rem', color: 'var(--color-text4)', marginRight: '.5rem', strokeWidth: 2 }} />
            <span style={{ fontSize: '1rem', color: 'var(--color-text3)' }}
              >{feature}
            </span>
          </li>
        ))}
      </ul>

      <div style={{ flex: 1, display: 'flex', marginTop: '1.5rem' }}/>

      <button style={{ width: '100%', padding: '12px 2px', background: '#000', color: 'white', borderRadius: '.5rem', fontWeight: '600', border: 'none', cursor: 'pointer', fontSize: '1rem', marginTop: 'auto',}}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-text3)'}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-text1)'}
        onClick={() => handleClick(item, plan)}
          >Sign Up
        </button>

      <div style={{ fontSize: '12px', color: 'var(--color-text3)', fontWeight: 'normal', marginTop: '.5rem', alignSelf: "center", textAlign: "center" }}
        >{ plan.frequency == tabs.weekly ? "Billed weekly" : `Only $${weeklyPlanPrice.toFixed(2)} per week` }
      </div>

      
    </div>
  )
}




const JoinScreen = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const buttonInProgressRef = useRef(false);
  const [ activeTab, setActiveTab ] = useState(tabs.monthly);


  const handlePlanClick = async ( item, plan ) => {
    if (buttonInProgressRef.current) return;
    buttonInProgressRef.current = true;
    
    try {
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.PLANS_CHECKOUT, type: item.name, });
      await createCheckoutSession_v3({ 
        priceId: plan._id, 
        email: user.email,
      });
    } 
    catch (error) {
      console.error('Error creating checkout session:', error);
    }
    finally {
      buttonInProgressRef.current = false;
    }
  }

  const handleDecline = async () => {
    if (buttonInProgressRef.current) return;
    buttonInProgressRef.current = true;

    try {
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.PLANS_DECLINED });
      navigate(-1);
    }
    catch (error) {
      console.error('Error logging paywall decline:', error);
    }
    finally {
      buttonInProgressRef.current = false;
    }
  }

  return (
    <div id="wrapper">
      <div id="main">
        <div className="inner" >

          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1rem', cursor: 'pointer', marginLeft: "auto" }}
            onClick={handleDecline}
          >
            <X style={{ width: '24px', height: '24px', color: 'var(--color-text3)', strokeWidth: 1.5 }} />
          </div>

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", paddingBottom: "20px" }}>

            <div style={{ textAlign: 'center', marginBottom: '2rem',  }}>
              <h1 style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '16px', color: 'var(--color-text1)' }}
                >What is your academic performance worth to you?
              </h1>
              <p style={{ fontSize: '1.25rem', color: 'var(--color-text3)', lineHeight: '1.5' }}
                >For just a few dollars a week, you can use our powerful tools to maximize your performance
              </p>
            </div>



            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            </div>

            <div style={{ fontSize: '.8rem', fontWeight: 500, color: 'var(--color-primary)', marginLeft: '8px', background: '#EEE7FE', padding: '6px', borderRadius: '.3rem', opacity: 1 }}
              ><strong>Cyber Monday Sale:</strong> 33% off all plans today only
            </div>



            <div style={{ position: 'relative', width: '100%', marginBottom: '3.5rem' }}>

              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', flexWrap: 'wrap', marginTop: '1rem' }}>
                { products.map((product, index) => (
                  <Tile_Plan key={`product-${index}`} item={product} index={index} handleClick={handlePlanClick} activeTab={activeTab} />
                )) }
              </div>
            </div>
          </div>

          <Chat styleOverride={{  }} />

        </div>
      </div>
    </div>
  );
};

export default JoinScreen;

import React, { useContext, useEffect, useState, useCallback } from 'react';
import { API, dbRefs, RESOURCES_UPLOAD_METHODS, S3_URL_PREFIX, PAYWALL_TYPES, SEARCH_PARAMS, RESOURCE_GENERATE_TYPES } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { initResourceObj, uploadResourceToS3 } from '../misc/utils';
import { Mic, BookOpen, Text, Upload, Play, FileText, Youtube } from 'lucide-react';
import Subheader from '../components/Subheader';
import { useBreakpoint } from '../misc/useBreakpoint';
import FileUploader from '../components/FileUploader5';
import TextUploader from '../components/TextUploader2';
import AudioRecorder from '../components/AudioRecorder';
import { useNavigate } from 'react-router-dom';
import ResourcesTable from '../components/ResourcesTable2';

const NEW_USER_ID = 'newuserexample'

const tabs = { takeNotes: 'Take notes', yourNotes: 'Your notes', };

const subTabs = {
  inClass: { _id: RESOURCES_UPLOAD_METHODS.recording, title: 'In class', icon: <Mic />,  },
  file: { _id: RESOURCES_UPLOAD_METHODS.file, title: 'File', icon: <FileText />, },
  text: { _id: RESOURCES_UPLOAD_METHODS.text, title: 'Text', icon: <Text />, },
  // youtube: { title: 'Youtube', icon: <Youtube />, },
}


const NotesScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext)
  const navigate = useNavigate()
  
  // TABS
  const [ activeTab, setActiveTab ] = useState(tabs.takeNotes);
  const [ activeSubTab, setActiveSubTab ] = useState(subTabs.inClass.title);

  // TOOLS
  const [ triggerRecording, setTriggerRecording ] = useState(false);

  // TABLE
  const [ data, setData ] = useState([])
  const [ dataCount, setDataCount ] = useState(0)
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ itemsPerPage ] = useState(20)
  


  // ACTIONS

  const handleRecordingClick = async () => {
    try {
      if ( !await checkUserPermission(PAYWALL_TYPES.resources_recording) ) return;
      setTriggerRecording(true)
    }
    catch (err) {
      console.error('Error recording:', err);
    }
  }

  useEffect(() => {
    if ( activeTab === tabs.yourNotes ) {
      getData()
    }
  }, [currentPage, activeTab])

  const getData = async () => {

    try {
    let results = []
    
    if ( user ) {
      const [docsResult, countResult] = await Promise.all([
        axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ user_id: user?._id, 'notes.urlText': { $exists: true } }),
          sortObj: JSON.stringify({ date: -1 }),
          currentPage: currentPage,
          pageSize: itemsPerPage,
          dbRef: dbRefs.resources
        }}),
        axios.get(API + '/generalCount', { params: {
          matchObj: JSON.stringify({ user_id: user?._id, 'notes.urlText': { $exists: true } }),
          dbRef: dbRefs.resources
        }})
      ]);

      const docsResults = docsResult.data;
      const countResults = countResult.data;

      if ( docsResults.length > 0 ) {
        setData(docsResults)
        setDataCount(countResults)
      }
      else {
        let result = await axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ user_id: NEW_USER_ID }),
          sortObj: JSON.stringify({ date: -1 }),
          dbRef: dbRefs.resources
        }})

        results = result.data
        setData(results)
        setDataCount(results.length)
      }
    }
    else {
      // setData(sampleData)
      // setDataCount(sampleData.length)
      }
    }
    catch (err) {
      console.error('Error getting resources:', err);
    }
  }

  const handleRowClick = (e, item) => {
    navigate(`/app/resources/${item._id}?${SEARCH_PARAMS.tab}=${RESOURCE_GENERATE_TYPES.notes}`)
  };


  return (
    <div id="wrapper">
      <div id="main" style={{ paddingBottom: '0' }}>
        <div className="inner" >

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '0rem' }}>
            <Subheader 
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs}
            />
          </div>

          { activeTab === tabs.takeNotes && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%',  }}>

                <div style={{ display: 'flex', gap: '.5rem', marginBottom: '.5rem' }}>
                  { Object.values(subTabs).map(tab => {
                    const isActive = tab.title === activeSubTab;
                    return (                    
                      <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', background: 'none', fontSize: '1rem', color: 'var(--color-text3)', padding: '8px 12px', cursor: 'pointer', transition: 'all 0.2s', borderRadius: '.5rem', border: '1px solid transparent', 
                        ...(isActive && { 
                          // color: 'var(--color-text1)', backgroundColor: '#F4F4F5' 
                          color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)'
                        
                        })
                      }}
                        key={tab.title}
                        onClick={() => setActiveSubTab(tab.title)}
                        >
                          {React.cloneElement(tab.icon, { size: 16, color: isActive ? 'var(--color-text1)' : 'var(--color-text3)', style: { marginRight: '6px' } })}
                          <span>{tab.title}</span>
                      </button>
                    )
                  })}
                </div>


                { activeSubTab === subTabs.inClass.title && (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', height: '100%', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: '1rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', alignItems: 'center', justifyContent: 'center', height: "340px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                      <h4 style={{fontSize: '1.4rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}
                        >Take notes in class
                      </h4>
                      <p style={{ fontSize: '1.1rem', color: 'var(--color-text3)', lineHeight: '1.3', textAlign: 'center', marginTop: '.5rem' }}
                        >School GOAT will listen & take notes for you.<br/>Just let it run in the background during your lecture.
                      </p>
                    </div>

                    <button style={{backgroundColor: 'var(--color-text1)', color: 'white', border: 'none', borderRadius: '.5rem', padding: '1rem 2rem', fontSize: '1rem', fontWeight: '600', cursor: 'pointer', marginTop: '1rem', transition: 'all 0.2s ease-in-out'}}
                      onClick={handleRecordingClick}
                      onMouseEnter={(e) => e.target.style.opacity = '0.7'}
                      onMouseLeave={(e) => e.target.style.opacity = '1'}
                      >Start Taking Notes
                    </button>
                  </div>
                )}

                { activeSubTab === subTabs.file.title && (
                  <FileUploader />
                )}

                { activeSubTab === subTabs.text.title && (
                  <TextUploader />
                )}


              </div>
          )}

          { activeTab === tabs.yourNotes && (
            <ResourcesTable
              data={data} 
              setData={setData} 
              handleRowClick={handleRowClick} 
              dataCount={dataCount} 
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
            />
          )}
            
        </div>
      </div>

      <AudioRecorder triggerRecording={triggerRecording} setTriggerRecording={setTriggerRecording} />

    </div>
  );  
}


export default NotesScreen;


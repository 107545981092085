import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader2';
import Essay_Evaluation from '../components/Essay_Evaluation';
import Essay_EvaluationInput from '../components/Essay_EvaluationInput2';
import { FileText } from 'lucide-react';
import Subheader from '../components/Subheader';

const EssayEvaluationScreen = () => {
  const { user, } = useContext(UserContext)
  const tabs = { input: "Input Essay", evaluation: "Evaluation" }
  const [ activeTab, setActiveTab ] = useState(tabs.input);
  const [ responses, setResponses ] = useState([]);

  return (
    <div id="wrapper">          
      
      <ScreenHeader 
        title="Essay Evaluation"
        showBackButton={true}
        icon={<FileText />}
      />

      <div id="main" style={{ backgroundColor: "var(--color-backgroundSecondary)" }}>
        <div className="inner" >

          <Subheader activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />

          <Essay_EvaluationInput 
              isActive={activeTab === tabs.input} 
              responses={responses} 
              setResponses={setResponses} 
              tabs={tabs}
              setActiveTab={setActiveTab}
            />

            <Essay_Evaluation 
              isActive={activeTab === tabs.evaluation} 
              responses={responses} 
              tabs={tabs}
              setActiveTab={setActiveTab}
            />
          
        </div>
      </div>
    </div>
  );  
}


export default EssayEvaluationScreen;





import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import { Routes } from './routes';
import { useState } from 'react';

import AbandonedCheckoutModal from '../modals/AbandonedCheckoutModal';
import { Zap, Mic, FileCheck, BookOpen, SquarePen, Search, Cog, Settings2, Check } from 'lucide-react';
import SchoolGoat from '../assets/schoolgoat.webp';
import { useBreakpoint } from '../misc/useBreakpoint';
import { SEARCH_PARAMS } from '../misc/constants';
import CheckoutModal from '../modals/CheckoutModal';

const tabs = [
  { title: "Notes", icon: <Mic />, route: Routes.NOTES },
  { title: "Answer", icon: <FileCheck />, route: Routes.SOLVE },
  { title: "Study", icon: <BookOpen />, route: Routes.STUDY },
  { title: "Writing", icon: <SquarePen />, route: Routes.WRITING },
  // { title: "Edit", icon: <Settings2 />, route: Routes.ESSAY_EVALUATION },
  { title: "Other", icon: <Cog />, route: Routes.OPTIONS },
  // { title: "Upgrade", icon: <Rocket />, route: Routes.OPTIONS },
]



const WithHomeHeader = ({ children }) => {
  const { user, userLoaded, setShowCheckoutModal } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileAndBelow, isPhoneAndBelow } = useBreakpoint();
  const [ openAbandonedCheckoutModal, setOpenAbandonedCheckoutModal ] = useState(false);
  const [ activeTab, setActiveTab ] = useState('');

  useEffect(() => {
    // Handle checkout param
    // const searchParams = new URLSearchParams(location.search);
    // const openCheckout = searchParams.get(SEARCH_PARAMS.checkout);
    // if (openCheckout) {
    //   setShowCheckoutModal(true);
    //   searchParams.delete(SEARCH_PARAMS.checkout);
    //   navigate({
    //     search: searchParams.toString()
    //   }, { replace: true });
    // }

    // Set active tab based on current path
    const path = location.pathname.split('/').pop();
    const newActiveTab = tabs.find(tab => tab.route === path) || tabs[0];
    setActiveTab(newActiveTab);
  }, [location]);

  const handleTabClick = (tab) => {
    navigate(`/app/${tab.route}`);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden', position: 'relative', backgroundColor: 'var(--color-backgroundSecondary)' }}>
      
      <nav style={{ display: 'flex', padding: '0rem 2rem',  borderBottom: '1px solid var(--color-separatorOpaque)', alignItems: 'center', justifyContent: 'space-between', width: '100%', }}>

        {!isMobileAndBelow && (
          <img src={SchoolGoat} alt="School Goat" style={{ height: '3rem', width: '3rem', cursor: 'pointer' }} onClick={() => handleTabClick(tabs[0])} />
        )}

        <div style={{ display: 'flex', gap: '.5rem', flex: 1, alignItems: 'center', justifyContent: 'center', paddingTop: '.75rem', }}>

          { tabs.map(tab => {
            const isActive = tab.title === activeTab.title;
            
            return (
              <button style={{ border: 'none', background: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px', color: 'var(--color-text3)', width: "4rem", paddingBottom: '.2rem', cursor: 'pointer', transition: 'all 0.2s', borderBottom: '2px solid transparent',
                ...(isActive && { color: 'var(--color-text1)', borderBottom: '2px solid var(--color-text1)' })
               }}
                key={tab.title}
                onClick={() => handleTabClick(tab)}
              >
                {React.cloneElement(tab.icon, { size: 20, color: isActive ? 'var(--color-text1)' : 'var(--color-text3)', strokeWidth: 1.25 })}
                <div style={{ fontSize: '0.75rem' }}>{tab.title}</div>
              </button>
            )
          })}

        </div>

        {!isMobileAndBelow && (

          !user?.isMember ? (
            <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', height: '36px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'var(--color-text1)',color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)', gap: '4px' }}
              onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
              onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
              // onClick={() => setShowCheckoutModal(true)}
              onClick={() => navigate(Routes.JOIN)}
            >
              <Zap size={15} />
              Upgrade
            </button>
          )

          : (           
            <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', height: '36px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'var(--color-backgroundSecondary)',color: 'var(--color-text3)', 
              border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', gap: '4px',
              cursor: "default"
            }}
            >
              <Check size={15} />
              Member
            </button>
          )




        )}
  
      </nav>
      
        <Outlet />
        <AbandonedCheckoutModal open={openAbandonedCheckoutModal} setOpen={setOpenAbandonedCheckoutModal} />
        
    </div>
  )
};

export default WithHomeHeader;






import { Button, IconButton } from '@mui/material';
import { format } from 'date-fns';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, MoreVertical, FileText, File, Mic, Ellipsis, FileUp, MessageSquareText, MessageCircleMore, TextCursorInput, Zap, FileCheck, BookOpenCheck, Rabbit, Text } from 'lucide-react';
import axios from 'axios';
import { API, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import ResourceInfoModal from '../modals/ResourceInfoModal';

const NEW_USER_ID = 'newuserexample'
const SECONDARY_COLUMN_WIDTH = '13rem'
const SECONDARY_COLUMN_FLEX = .5
const TERTIARY_COLUMN_WIDTH = '40px'


const ResourcesTable = ({ isActive = true, data = [], dataCount = 0, setData, handleRowClick, currentPage, setCurrentPage, itemsPerPage }) => {
  const { isMobileAndBelow,  } = useBreakpoint();
  const totalPages = Math.ceil(dataCount / itemsPerPage);
  const [resourceInfoModalId, setResourceInfoModalId] = useState(null);
  const currentPageDisplayed = currentPage + 1

  const iconDirectory = {
    [RESOURCES_UPLOAD_METHODS.text]: <Text />,
    [RESOURCES_UPLOAD_METHODS.file]: <FileText />,
    [RESOURCES_UPLOAD_METHODS.recording]: <Mic />,
  }

  const onPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleMoreVerticalClick = (e, item) => {
    e.stopPropagation();
    if ( item?.user_id === NEW_USER_ID ) {
      alert('This is an example file for new users. You can view it, but you cannot edit it. Upload your own files!')
      return
    }
    else {
      setResourceInfoModalId(item._id);
    }
  }


  if ( !isActive ) return null

  return (
    <>
      <div style={{ backgroundColor: 'var(--color-backgroundSecondary)',  overflow: 'hidden', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: "var(--borderShadow)", marginBottom: '3rem',
        ...isMobileAndBelow && { padding: 0, border: 'none', borderRadius: 0, boxShadow: 'none' } }}
        role="region"
        aria-label="Notes list"
      >
        { !isMobileAndBelow &&
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "1rem",  color: 'var(--color-text3)', fontWeight: "500", textTransform: "uppercase", fontSize: ".9rem", borderBottom: "1px solid var(--color-separator)", textAlign: "left",  }}>
            {/* <div style={{ width: "40px", }}>
              
            </div> */}
            <div style={{ flex: 1, }}>
              Your Notes
            </div>
            <div style={{ flex: SECONDARY_COLUMN_FLEX }}>
              Class
            </div>
            <div style={{ flex: SECONDARY_COLUMN_FLEX }}>
              Date
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: TERTIARY_COLUMN_WIDTH }}>
              </div>
          </div>
        }

        { data && data.length > 0 && data.map((item, index) => (
          <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', padding: isMobileAndBelow ? "1rem" : ".5rem 1rem", fontSize: '1rem', 
            borderBottom: index === data.length - 1 ? 'none' : '1px solid var(--color-separator)', cursor: 'pointer', transition: 'background-color 0.3s ease',  }}
            key={index}
            onClick={(e) => handleRowClick(e, item)}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", flex: 1, minWidth: 0, maxWidth: "100%" }}>

              <div style={{ display: 'flex', alignItems: 'center', flex: 1}}>
                { !isMobileAndBelow && (
                  <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: '35px' }}>
                    {React.cloneElement(iconDirectory[item.uploadMethod], { size: 17, color: 'var(--color-text3)', style: { verticalAlign: 'middle', strokeWidth: 1 } })}
                  </div>
                )}
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "100%", fontSize: '.9rem', color: 'var(--color-text1)' }}>
                  {item.title || "Document"}
                </span>

                { !item?.notes?.urlText && (
                  <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginLeft: '.5rem' }}>
                    <Rabbit size={16} color='var(--color-text4)' strokeWidth={1} />
                  </span>
                )}
              </div>

              { isMobileAndBelow &&
                <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.2rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "100%" }}
                  >{format(new Date(item.date), "MMM d, yyyy")}  •  {item.category}
                </span>
              }
            </div>

            { !isMobileAndBelow &&
            <>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, flex: SECONDARY_COLUMN_FLEX }}>
                <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', }}
                  >{item.category} 
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", lineHeight: "1.3", minWidth: 0, flex: SECONDARY_COLUMN_FLEX }}>
                <span style={{ fontSize: '.9rem', color: 'var(--color-text4)',  }}
                  >{format(new Date(item.date), "MMM d, yyyy")} 
                </span>
              </div>
            </>
            }
            <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, width: TERTIARY_COLUMN_WIDTH }}>
              <IconButton style={{ color: 'var(--color-text3)', borderRadius: '50%', transition: 'background-color 0.3s ease', marginLeft: 'auto',   }}
                aria-label="View notes"
                onClick={(e) => handleMoreVerticalClick(e, item)}
              >
                <Ellipsis size={20} color='var(--color-text3)' strokeWidth={1} />
              </IconButton>
            </div>
          </div>
        ))}
      <nav aria-label="Pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', padding: "1rem", position: 'sticky', bottom: 0, left: 0, right: 0, backgroundColor: 'var(--color-backgroundSecondary)', borderTop: '1px solid var(--color-separatorOpaque)', marginTop: "auto" }}>
          <button
            style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPageDisplayed === 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPageDisplayed === 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
            onClick={onPreviousPage}
            disabled={currentPageDisplayed === 1}
            aria-label="Go to previous page"
            aria-disabled={currentPageDisplayed === 1}
          >
            ←
          </button>
          <span style={{ color: 'var(--color-text3)', fontSize: '14px' }} aria-live="polite" aria-atomic="true">
            Page {currentPageDisplayed} of {totalPages} | {dataCount} items
          </span>
          <button
            style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === totalPages - 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
            onClick={onNextPage}
            disabled={currentPageDisplayed === totalPages}
            aria-label="Go to next page"
            aria-disabled={currentPageDisplayed === totalPages}
          >
            →
          </button>
        </nav>


      </div>




      <ResourceInfoModal 
        resourceInfoModalId={resourceInfoModalId} 
        setResourceInfoModalId={setResourceInfoModalId} 
        setData={setData}
      />
    </>
  );
};

export default ResourcesTable;

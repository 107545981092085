import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { API, RESOURCES_UPLOAD_METHODS, S3_URL_PREFIX, PAYWALL_TYPES, audioMimeTypes, dbRefs, recordingIntervalDuration } from '../misc/constants';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { formatTime, initResourceObj, uploadResourceToS3 } from '../misc/utils';
import { AudioRecorder_CustomButton } from '../components/AudioRecorderComponents';
import { Trash, X, Play, Pause, Check, Fullscreen, Command, Mic, FileText } from 'lucide-react';
import { IconButton, useMediaQuery } from '@mui/material';
import MicrophonePermissionDeniedModal from '../modals/MicrophonePermissionDenied';
import AudioUploadZeroBytesModal from '../modals/AudioUploadZeroBytesModal';
import ScreenHeader from '../components/ScreenHeader2';
import { useBreakpoint } from '../misc/useBreakpoint';
import ResourceProcessingModal from '../modals/ResourceProcessingModal';
import { sendSlackNotification } from '../misc/utils';
import { SLACK_WEBHOOK_CHANNELS } from '../misc/constants';


const RecordingIndicator = () => {
  const bars = 5;

  const barStyle = (index) => ({
    width: '10px',
    backgroundColor: '#3b82f6',
    borderRadius: '9999px',
    animation: `waveAnim 1.8s ${index * 0.2}s ease-in-out infinite`
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px', height: "80px" }}>
        {[...Array(bars)].map((_, i) => (
          <div key={i} style={{ display: 'flex', alignItems: 'center', height: '100%'}}>
            <div style={barStyle(i)} />
          </div>
        ))}
      </div>
      <style>{` @keyframes pulse {0%, 100% {opacity: 1;} 50% {opacity: 0.5;}} @keyframes waveAnim {0%, 100% {height: 30%;} 50% {height: 90%;}}`}</style>
    </div>
  );
};



const AudioRecorder = ({ triggerRecording, setTriggerRecording,  }) => {
  const { user, checkUserPermission } = useContext(UserContext);
  const { isTabletAndBelow, isMobileAndBelow } = useBreakpoint();
  const recordingStates = {
    recording: 'recording',
    paused: 'paused',
    inactive: 'inactive'
  }
  // const [ triggerRecording, setTriggerRecording ] = useState(false);
  const [recordingState, setRecordingState] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [microphonePermission, setMicrophonePermission] = useState(false);
  const [openMicrophonePermissionDeniedModal, setOpenMicrophonePermissionDeniedModal] = useState(false);
  const [openAudioUploadZeroBytesModal, setOpenAudioUploadZeroBytesModal] = useState(false);
  const [openResourceProcessingModal, setOpenResourceProcessingModal] = useState(false);
  const mediaRecorderRef = useRef(null);
  const fileIdRef = useRef(null);
  const mimeTypeRef = useRef(null);
  const recordingNumberRef = useRef(0);
  const recordingIntervalRef = useRef(null);
  const elapsedTimeIntervalRef = useRef(null);
  const lastUpdateTimeRef = useRef(null);
  const completedRecordingRef = useRef(false);
  const discardRecordingRef = useRef(false);

  useEffect(() => {
    if (triggerRecording) {
      console.log("Start recording");
      startRecording();
    }

    return () => resetAllStates();
  }, [triggerRecording]);

  useEffect(() => {
    if (recordingState === recordingStates.recording && mediaRecorderRef.current) {
      startTimer();

      if ( mediaRecorderRef?.current?.state === recordingStates.paused ) {
        mediaRecorderRef.current.resume();
        console.log("Resumed recording");
      }
      else if ( mediaRecorderRef?.current?.state === recordingStates.inactive ) {
        mediaRecorderRef.current.start();
        console.log("Restarted recording");
      }
    } 
    else if (recordingState === recordingStates.paused && mediaRecorderRef.current) {
      mediaRecorderRef.current.pause();
      stopTimer();
    }
    else {
      stopTimer();
    }
  }, [recordingState]);

  const startRecording = async () => {
    if (!mediaRecorderRef.current) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        const supportedMimeType = Object.keys(audioMimeTypes).find(mimeType => MediaRecorder.isTypeSupported(mimeType));
        mimeTypeRef.current = supportedMimeType;
        const newMediaRecorder = new MediaRecorder(stream, { audioBitsPerSecond: 32000, mimeType: supportedMimeType });
        mediaRecorderRef.current = newMediaRecorder;
        mediaRecorderRef.current.ondataavailable = handleRecorderOnDataAvailable;
        mediaRecorderRef.current.onstop = handleRecorderOnStop;
        setMicrophonePermission(true);
        
        fileIdRef.current = uuidv4();
        await initResourceObj({ resource_id: fileIdRef.current, user, uploadMethod: RESOURCES_UPLOAD_METHODS.recording })
        console.log("fileIdRef.current", fileIdRef.current)
        setRecordingState(recordingStates.recording);
      } 
      catch (err) {
        console.error("Error initializing media recorder:", err);
        setTriggerRecording(false);
        setOpenMicrophonePermissionDeniedModal(true);
      }
    } 
    else if (!microphonePermission) {
      console.error("Microphone permission denied");
      setTriggerRecording(false);
      setOpenMicrophonePermissionDeniedModal(true);
    }
  };

  const handleRecorderOnDataAvailable = (e) => {

    if ( discardRecordingRef.current ) {
      setTriggerRecording(false);
      return;
    }
  
    if ( e.data.size > 0 ) {
      const blob = new Blob([e.data], { type: mimeTypeRef.current });
      processingPipeline(blob);
      recordingNumberRef.current += 1;
    }
    else if ( e.data.size === 0 && completedRecordingRef.current === false ) {
      setOpenAudioUploadZeroBytesModal(true);
    }
  };

  const handleRecorderOnStop = () => {
    if ( discardRecordingRef.current ) {
      setTriggerRecording(false);
      return;
    }
  
    if (!completedRecordingRef.current) {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== recordingStates.recording) {
        mediaRecorderRef.current.start();
        console.log("Restarting recording");
      }
    } 
  };

  const completeRecording = () => {
    completedRecordingRef.current = true;

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      mediaRecorderRef.current.stop();
    }

    setRecordingState(recordingStates.inactive);
    console.log("Completed recording");
  };

  const discardRecording = async () => {
    try {
      discardRecordingRef.current = true;
      await axios.delete(`${API}/generalDeleteOne`, {
        data: {
          matchObj: { _id: fileIdRef.current },
          dbRef: dbRefs.resources,
        }
      });
    } 
    catch (err) {
      console.error("Error discarding recording:", err);
    } 
    finally {
      completeRecording();
    }
  };

  const startTimer = () => {
    lastUpdateTimeRef.current = Date.now();
    
    elapsedTimeIntervalRef.current = setInterval(() => {
      setElapsedTime(prevTime => prevTime + 1);
    }, 1000);


    recordingIntervalRef.current = setInterval(() => {
      if (mediaRecorderRef.current.state === recordingStates.recording) {
        mediaRecorderRef.current.stop();
      }
    }, recordingIntervalDuration);

  };

  const stopTimer = () => {
    if (elapsedTimeIntervalRef.current) {
      clearInterval(elapsedTimeIntervalRef.current);
      elapsedTimeIntervalRef.current = null;
    }
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
      recordingIntervalRef.current = null;
    }
  };

  const resetAllStates = () => {
    console.log('Resetting all states');
    
    completedRecordingRef.current = false;
    mediaRecorderRef.current = null;
    fileIdRef.current = null;
    mimeTypeRef.current = null;
    recordingNumberRef.current = 0;
    lastUpdateTimeRef.current = null;
    discardRecordingRef.current = false;

    clearInterval(elapsedTimeIntervalRef.current);
    clearInterval(recordingIntervalRef.current);
    setElapsedTime(0);
    setRecordingState(null);
    setMicrophonePermission(false);
  };

  const processingPipeline = async (dataBlob, attempt = 0) => {
    const MAX_RETRIES = 2;
    const INITIAL_DELAY = 1000; // 1 second

    try {
      if (dataBlob.size === 0) {
        console.warn("Received empty audio blob");
        return;
      }
  
      await uploadResourceToS3({ 
        resource_id: fileIdRef.current, 
        file: { 
          data: dataBlob, 
          name: `audio-${recordingNumberRef.current}${audioMimeTypes[mimeTypeRef.current]}`,
          type: mimeTypeRef.current
        }, 
        uploadMethod: RESOURCES_UPLOAD_METHODS.recording 
      })
  
      // If this is the final clip, run additional processing
      if (completedRecordingRef.current && !discardRecordingRef.current) {

        let result = await axios.post(`${API}/transcribeRecording`, { file_id: fileIdRef.current, })
        console.log("transcribeRecording result", result)
        setTriggerRecording(false);
        setOpenResourceProcessingModal(true);
      }
  
    } 
    catch (err) {
      if (attempt < MAX_RETRIES) {
        const delay = INITIAL_DELAY * Math.pow(2, attempt);
        console.log(`Retry attempt ${attempt + 1} after ${delay}ms`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return processingPipeline(dataBlob, attempt + 1);
      }
  
      const fullMessage = `Error processing and uploading audio after ${MAX_RETRIES} attempts: ${err}`;
      await sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues);
      console.error(fullMessage);
      throw err;
    }
  };




  return (

    <>

          <MicrophonePermissionDeniedModal open={openMicrophonePermissionDeniedModal} setOpen={setOpenMicrophonePermissionDeniedModal} />
          <AudioUploadZeroBytesModal open={openAudioUploadZeroBytesModal} setOpen={setOpenAudioUploadZeroBytesModal} />
          <ResourceProcessingModal open={openResourceProcessingModal} setOpen={setOpenResourceProcessingModal} />


        { triggerRecording && (


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '2rem', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.9)', backdropFilter: 'blur(12px)', WebkitBackdropFilter: 'blur(12px)', zIndex: 9999, }}
            role="dialog"
            aria-modal="true"
            aria-label="Recording class notes"
            aria-description="Records class notes in real time. Do not close this page until your class is finished."
          >
            
            <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <span style={{ color: 'var(--color-white)', fontSize: '1.5rem',  }}>
                  {formatTime(elapsedTime)}
                </span>
              </div>
              <button style={{ cursor: 'pointer', aspectRatio: 1, border: "none", borderRadius: "50%", padding: "10px", display: "flex", alignItems: "center", justifyContent: "center", aspectRatio: 1, height: "50px", width: "50px",
              backgroundColor: 'transparent', transition: 'background-color 0.2s ease', }}
                onMouseEnter={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.3)'}
                onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                onClick={discardRecording}
                aria-label="Discard recording and close"
              >
                <X size={30} color={'var(--color-white)'} />
              </button>
            </header>

              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>

                { recordingState === recordingStates.recording && (
                  <>
                    <RecordingIndicator />
                    <h1 style={{ fontWeight: 'bold', color: 'var(--color-white)', fontSize: '2.5rem' }}>
                      Taking notes now
                    </h1>
                    <span style={{ color: 'var(--color-white)', fontSize: '1rem', marginTop: '10px' }}>
                      Do not close this page until your class is finished
                    </span>
                  </>
                  
                )}

                { recordingState === recordingStates.paused && (
                  <>
                    <h1 style={{ fontWeight: 'bold', color: 'var(--color-white)', fontSize: '2.5rem' }}>
                      Paused
                    </h1>
                  </>
                )}
                                
              </div>


              <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignSelf: 'center', alignItems: 'center' }}
                aria-label="Control recording"
                aria-description="Controls the recording of class notes"
                role="group"
              >

                {recordingState === recordingStates.recording && (
                  <AudioRecorder_CustomButton onClick={() => setRecordingState(recordingStates.paused)} text="Pause" icon={<Pause style={{ marginRight: "10px" }} size={20} color={"var(--color-text3)"} />} />
                )}

                {recordingState === recordingStates.paused && (
                  <>
                    <AudioRecorder_CustomButton 
                      onClick={discardRecording} 
                      text="Discard" 
                      color={"red"} 
                      style={{ color: 'var(--color-white)' }} 
                      icon={<Trash style={{ marginRight: "10px" }} size={20} color={"var(--color-white)"} />} 
                    />
                    <AudioRecorder_CustomButton 
                      onClick={() => setRecordingState(recordingStates.recording)} 
                      text="Resume" 
                      icon={<Play style={{ marginRight: "10px" }} size={20} color={"var(--color-text3)"} />} 
                    />
                    <AudioRecorder_CustomButton 
                      onClick={completeRecording} 
                      text="Finish" 
                      color={"#007aff"} 
                      style={{ color: 'var(--color-white)' }} 
                      icon={<Check style={{ marginRight: "10px" }} size={20} color={"var(--color-white)"} />} 
                    />
                  </>
                )}
              </div>
          </div>


        )}
    </>
  );
};

export default AudioRecorder;

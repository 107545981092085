import React, { useState, useRef, useEffect, } from 'react';
import { Lightbulb, Book, PenTool, Layout, MessageSquare, Check } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import { ChevronDown, ChevronUp, CheckCircle, XCircle, Wrench, ArrowLeft } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';

const riskLevels = {
  a: {
    value: .9,
    label: 'A',
    color: '#10b981',
    colorLight: '#d1fae5',
  },
  b: {
    value: .8,
    label: 'B',
    color: '#84cc16',
    colorLight: '#ecfccb',
  },
  c: {
    value: .7,
    label: 'C',
    color: '#eab308',
    colorLight: '#fef9c3',
  },
  d: {
    value: .6,
    label: 'D',
    color: '#f97316',
    colorLight: '#ffedd5',
  },
  f: {
    value: 0,
    label: 'F',
    color: '#ef4444',
    colorLight: '#fee2e2',
  },
}

const getRiskLevel = (value) => {
  if (value >= riskLevels.a.value) return riskLevels.a;
  if (value >= riskLevels.b.value) return riskLevels.b;
  if (value >= riskLevels.c.value) return riskLevels.c;
  if (value >= riskLevels.d.value) return riskLevels.d;
  return riskLevels.f;
}

const dummyMetrics = [
  { title: 'Technical', grade: 65, icon: Book },
  { title: 'Potential', grade: 95, icon: Lightbulb },
  { title: 'Creativity', grade: 82, icon: PenTool },
  { title: 'Structure', grade: 91, icon: Layout },
  { title: 'Clarity', grade: 92, icon: MessageSquare },
  { title: 'Grammar', grade: 50, icon: Check },
  { title: 'Grammar2', grade: 50, icon: Check }
];

const ParameterScores = ({ data }) => {
  const { isMobileAndBelow } = useBreakpoint();
  const scores = data;

  const maxScore = 100;
  const avgScore = Math.round(scores.reduce((acc, curr) => acc + curr.grade, 0) / scores.length);
  const riskLevel = getRiskLevel(avgScore/100);

  return (
    <div style={{ width: '100%', padding: '1.5rem', borderRadius: '.5rem', backgroundColor: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)' }}>
     <div style={{ marginBottom: '1.5rem' }}>
        <h2 style={{ fontSize: '1.2rem', fontWeight: 'normal', margin: '0 0 8px 0' }}
          >Your Score
        </h2>
        <div style={{ fontSize: '3rem', fontWeight: 'bold', margin: '0 0 4px 0' }}
          >{avgScore}
        </div>
        <div style={{ color: 'var(--color-text3)', margin: 0, }}
          >Based on a 10 point scale, <span style={{ color: riskLevel.color, backgroundColor: riskLevel.colorLight, padding: '3px 6px', borderRadius: '4px' }}>you would earn a { riskLevel.label === riskLevels.a.label ? 'n' : '' } {riskLevel.label}</span>
        </div>
      </div>
      
      
      <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', gap: '8px', height: '240px' }}>
        {scores.map((item, index) => {

          const riskLevel = getRiskLevel(item.grade/100);

          return (
            <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, minWidth: '0px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px', marginBottom: '4px', fontSize: '14px', fontWeight: '500', backgroundColor: riskLevel.colorLight, padding: '4px 8px', borderRadius: '4px', color: riskLevel.color }}
                >{item.grade}
                { riskLevel.label == riskLevels.a.label && <CheckCircle size={12} color={riskLevel.color} />}
              </div>
              <div style={{ width: '100%', backgroundColor: 'black', height: `${(item.grade / maxScore) * 180}px`, borderRadius: '2px', transition: 'opacity 0.3s', cursor: 'pointer' }}
                onMouseEnter={(e) => e.target.style.opacity = '0.8'}
                onMouseLeave={(e) => e.target.style.opacity = '1'}
              />
              { !isMobileAndBelow && (
                <div style={{ marginTop: '8px', fontSize: '.8rem', color: 'var(--color-text3)', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}
                  >{item.title}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  );
};


const Essay_Evaluation = ({ isActive, responses, tabs, setActiveTab }) => {
  const { isPhoneAndBelow } = useBreakpoint()
  const [ expanded, setExpanded ] = useState(false);

  useEffect(() => {
    if ( !isActive ) {
      setExpanded(false);
    }
  }, [isActive]);

  if (!Array.isArray(responses) || responses.length === 0) {
    responses = dummyMetrics;
  }

  if ( !isActive ) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>


      <ParameterScores data={responses} />

      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', gap: '1rem', alignItems: 'start' }}>
        {responses.map((item, index) => {
          const riskLevel = getRiskLevel(item.grade/100);
          const isExpanded = expanded === index;

          return (
          <div style={{ display: 'flex', flexDirection: 'column', padding: '1.2rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: "var(--borderShadow)", borderRadius: '.5rem', cursor: 'pointer', transition: 'background-color 0.2s', height: 'fit-content' }}
            key={index}
            onClick={() => setExpanded((prev) => prev === index ? null : index)}
            onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-background)'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pointerEvents: 'none' }}>
              <h3 style={{ fontSize: '1rem', fontWeight: 500, }}
                >{item.title}
              </h3>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <span style={{ fontSize: '.9rem', color: riskLevel.color, backgroundColor: riskLevel.colorLight, padding: '4px 8px', borderRadius: '4px' }}
                  >{item.grade}%
                </span>
                <ChevronDown size={20} style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} color={'var(--color-text3)'} />
              </div>
            </div>
              <div style={{ 
                maxHeight: isExpanded ? '500px' : '0',
                opacity: isExpanded ? 1 : 0,
                overflow: 'hidden',
                transition: 'all 0.3s ease-in-out',
                flexDirection: 'column', 
                gap: '.9rem',
                display: 'flex',
                marginTop: isExpanded ? '.7rem' : '0',
                pointerEvents: 'none',
              }}>
                <p style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: 1.3, }}
                  ><span style={{ fontWeight: 600, textDecoration: 'underline' }}>Explanation:</span> {item.explanation}
                </p>
                <p style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: 1.3 }}
                  ><span style={{ fontWeight: 600, textDecoration: 'underline' }}>Advice:</span> {item.actionableAdvice}
                </p>
              </div>
            </div>
          )
        })}
        </div>

    </div>
   
  );
};

export default Essay_Evaluation;

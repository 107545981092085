import React, { useMemo, useState, useCallback, useEffect, useRef, useContext } from 'react';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { createEditor, Node, Text, Path, Transforms, Range } from 'slate';
import axios from 'axios';
import { API, dbRefs, MIXPANEL_EVENTS } from '../misc/constants';
import { debounce } from 'lodash';
import { BookCheck, ChevronDown, SquarePen } from 'lucide-react';
import ScreenHeader from '../components/ScreenHeader2';
import { UserContext } from '../contexts/UserProvider';
import SelectEssayModal from '../modals/SelectEssayModal';
import { v4 as uuidv4 } from 'uuid';
import { useBreakpoint } from '../misc/useBreakpoint';
import { CircularProgress } from '@mui/material';
import mixpanel from 'mixpanel-browser';

const suggestionLoadingIdentifier = "****"
const EDITOR_INITIAL_VALUE = [{ type: 'paragraph', children: [{ text: '' }], }]

const createEssayObj = async (user, setItem) => {
  try {
    const result = await axios.get(`${API}/generalCount`, { params: { 
      matchObj: JSON.stringify({ user_id: user?._id }),
      dbRef: dbRefs.essays
    }})
    const count = result.data

    const title = `Essay ${count + 1}`

    const essayObj = {
      _id: uuidv4(),
      user_id: user?._id,
      user_email: user?.email,
      date: new Date(),
      updatedAt: new Date(),
      title: title,
      content: [{ type: 'paragraph', children: [{ text: '' }], }],
    }

    await axios.post(`${API}/generalInsertOne`, { doc: essayObj, dbRef: dbRefs.essays });
    setItem(essayObj)
  }
  catch (error) {
    throw error
  }
}

const initializeEssay = async (user, setItem) => {
  try {
    const result = await axios.get(`${API}/generalFindOne`, { params: { 
      queryObj: JSON.stringify({ user_id: user?._id }),
      optionsObj: JSON.stringify({ sort: { updatedAt: -1 }, limit: 1 }),
      dbRef: dbRefs.essays
    }})
    const results = result?.data
    if ( results?._id ) {
      setItem(results)
      console.log('essay found', results)
    }
    else {
      await createEssayObj(user, setItem)
    }
  }
  catch (error) {
    console.error(error)
  }
}

const saveEssay = async (content, item_id) => {
  try {
    await axios.put(`${API}/generalUpdateOne`, {
      matchObj: { _id: item_id },
      updateObj: { $set: { content: content, updatedAt: new Date() }},
      dbRef: dbRefs.essays,
    })
  }
  catch (error) {
    console.error(error)
  }
}



const EssayScreen = () => {
  const { user } = useContext(UserContext);
  const { isPhoneAndBelow } = useBreakpoint();
  const [editor] = useState(() => withReact(createEditor()));
  const [ item, setItem ] = useState(null);
  const [ initialValue, setInitialValue ] = useState(EDITOR_INITIAL_VALUE);
  const [suggestion, setSuggestion] = useState('');
  const [ openSelectEssayModal, setOpenSelectEssayModal ] = useState(false);
  const latestRequestIdRef = useRef(0);
  const [ contentToSave, setContentToSave ] = useState(null);
  const currentItemIdRef = useRef(null);
  
  useEffect(() => {
    // 1. First update initialValue and refs when item changes
    if (item?.content) {
      setInitialValue(item.content);
      currentItemIdRef.current = item._id;
      setContentToSave(null); // Clear any pending saves
    } 
    else {
      setInitialValue(EDITOR_INITIAL_VALUE);
      currentItemIdRef.current = null;
    }
  
    // 2. Then reset editor state after initialValue is set
    if (item?._id) {
      try {
        // Reset editor state completely
        editor.children = item.content || EDITOR_INITIAL_VALUE;
        editor.selection = null;
        editor.operations = [];
        
        // Small delay to ensure editor state is updated
        setTimeout(() => {
          if (!ReactEditor.isFocused(editor)) {
            ReactEditor.focus(editor);
          }
        }, 100);
      } 
      catch (error) {
        console.warn('Editor reset/focus failed:', error);
      }
    }
    
    // Cleanup function runs before next effect or unmount
    return () => {
      try {
        editor.selection = null;
        editor.operations = [];
      } catch (error) {
        console.warn('Editor cleanup failed:', error);
      }
    };
  }, [item, editor]); // Only depend on item and editor

  // Initialize essay
  useEffect(() => {
    initializeEssay(user, setItem)
  }, [])

  // Debounced auto-save
  useEffect(() => {
    if (!contentToSave || contentToSave === '') return;
    
    const timeoutId = setTimeout(async () => {
      try {
        if (item?._id && currentItemIdRef.current === item._id && contentToSave) {
          await saveEssay(contentToSave, item?._id);
          console.log('saving')
        }
      } 
      catch (error) {
        console.error(error)
      }
    }, 5000); 

    return () => clearTimeout(timeoutId);
  }, [contentToSave, item?._id]);




  const fetchSuggestion = useCallback(async () => {
    const requestId = ++latestRequestIdRef.current;
    try {
      const { selection } = editor;
      if (!selection || !Range.isCollapsed(selection)) return;

      const { path, offset } = selection.anchor;
      const node = Node.get(editor, path);
      const text = Node.string(node);

      const textAfterCursor = text.slice(offset).trim();
      const textUpToCursor = text.slice(0, offset);
      const textBeforeCursor = editor.children.slice(0, path[0]).map((n) => Node.string(n)).join('\n') + textUpToCursor;
      const lastCharacter = textUpToCursor.slice(-1);

      if (
        !textAfterCursor &&
        (textUpToCursor === '' || lastCharacter === ' ') &&
        textBeforeCursor.trim().length > 0
      ) {
        console.log('Fetching suggestion...');
        const response = await axios.post(`${API}/generateSuggestion`, { text: textBeforeCursor });
        const results = response.data.response ? response.data.response + ' ' : '';
        // const results = "this is a suggestion"
        if (requestId === latestRequestIdRef.current) {
          setSuggestion(results);
          mixpanel.track(MIXPANEL_EVENTS.essay_suggestion, { user_id: user?._id, user_email: user?.email });
        }
      } 
      else {
        if (requestId === latestRequestIdRef.current) {
          setSuggestion('');
        }
      }
    } 
    catch (error) {
      console.error('API call failed:', error);
      if (requestId === latestRequestIdRef.current) {
        setSuggestion('');
      }
    }
  }, [editor]);

  const fetchSuggestionDebounced = useMemo(() => debounce(fetchSuggestion, 500), [fetchSuggestion]);

  useEffect(() => {
    return () => {
      fetchSuggestionDebounced.cancel();
    };
  }, [fetchSuggestionDebounced]);

  // Handle changes in the editor
  const handleChange = useCallback(() => {
    setSuggestion(''); 
    fetchSuggestionDebounced();

     // Check for content changes
    const hasContentChange = editor.operations.some(op => {
      const operations = [ 'insert_text', 'remove_text', 'split_node', 'merge_node', 'set_node' ]
      return operations.includes(op.type)
    });

    if (hasContentChange) {
      setContentToSave(editor.children);
    }
  }, [fetchSuggestionDebounced, editor]);

  const handleKeyDown = (event) => {
    try {
      const { selection } = editor;
  
      if (event.key === 'Tab' && event.shiftKey) {
        if (suggestion && selection) {
          event.preventDefault();
          setSuggestion(suggestionLoadingIdentifier);
          fetchSuggestion();
        }
      }
      else if (event.key === 'Tab') {
        if (suggestion && selection) {
          event.preventDefault();
          Transforms.insertText(editor, suggestion);
          setSuggestion('');
        }
      } 
      else if (event.key === 'Escape') {
        setSuggestion('');
      }
    } catch (error) {
      console.error('Error in handleKeyDown:', error);
    }
  };
  
  // Handle mouse down events to dismiss suggestions
  const handleMouseDown = () => {
    if (suggestion) {
      setSuggestion('');
    }
  };

  const decorate = useCallback(([node, path]) => {
    if (!Text.isText(node)) return [];

    const ranges = [];

    if (suggestion && editor.selection) {
      const { anchor } = editor.selection;

      if (Path.equals(path, anchor.path)) {
        // If the node is empty, set the range at offset 0
        const isEmpty = node.text === '';
        const suggestionOffset = isEmpty ? 0 : anchor.offset;

        ranges.push({
          anchor: { path, offset: suggestionOffset },
          focus: { path, offset: suggestionOffset },
          suggestion,
        });
      }
    }

    return ranges;
  }, [editor.selection, suggestion]);

  return (
    <div id="wrapper">
    
      <ScreenHeader 
        title={"Write Essay"} 
        showBackButton={true} 
        icon={<SquarePen />} 
        actions={[
          <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', height: '36px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'var(--color-backgroundSecondary)',color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', cursor: 'pointer', transition: 'opacity 0.2s', 
              // boxShadow: 'var(--borderShadow)' 
            }}
            onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
            onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
            onClick={() => setOpenSelectEssayModal(true)}
            >{item?.title}
            <ChevronDown style={{ marginLeft: '8px' }} size={16} color='var(--color-text3)' />
          </button>,
          <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', height: '36px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'var(--color-text1)',color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',  }}
            onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
            onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
            onClick={async () => await createEssayObj(user, setItem)}
          >+ New
          </button>
        ]}
      />

      <div id="main">
        <div className="inner">
        
        <Slate 
            key={item?._id} // Add this key prop
            editor={editor} 
            initialValue={initialValue} 
            onChange={handleChange}
          >
            <Editable
              style={{ padding: '0 24px', outline: 'none', borderRadius: '0px', height: '100%', position: 'relative', overflow: 'auto', backgroundColor: 'white', lineHeight: '1.4' }}
              renderLeaf={(props) => <Leaf {...props} />}
              decorate={decorate}
              onMouseDown={handleMouseDown}
              onKeyDown={handleKeyDown}
              placeholder="Start writing. When you pause (after a space), I'll offer suggestions..."
            />
          </Slate>
        </div>
      </div>

      
        <div style={{ position: 'sticky', bottom: 0, left: 0, right: 0, marginTop: "auto", backgroundColor: 'var(--color-backgroundSecondary)', borderTop: '1px solid var(--color-separatorOpaque)', padding: '12px', display: 'flex', justifyContent: 'center', gap: '24px', fontSize: '0.875rem', color: 'var(--color-text3)' }}>

        { !isPhoneAndBelow ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <kbd style={{ padding: '2px 6px', backgroundColor: 'var(--color-background)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '4px', fontSize: '0.75rem' }}
                >Tab
              </kbd>
              <span>Accept suggestion</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'center',  }}>
                <kbd style={{ padding: '2px 6px', backgroundColor: 'var(--color-background)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '4px', fontSize: '0.75rem' }}
                  >Shift
                </kbd>
                <span style={{ margin: '0 2px' }}>+</span>
                <kbd style={{ padding: '2px 6px', backgroundColor: 'var(--color-background)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '4px', fontSize: '0.75rem' }}
                  >Tab
                </kbd>
              </div>
              <span>New suggestion</span>
            </div>
          </>
        ) : 
          <span style={{ fontSize: '0.875rem', color: 'var(--color-text3)' }}>Best experience on computers / tablets</span>
        }
        </div>

      <SelectEssayModal 
        open={openSelectEssayModal} 
        setOpen={setOpenSelectEssayModal} 
        currentItemId={currentItemIdRef.current}
        onSelect={async (item) => {
          await saveEssay(editor.children, currentItemIdRef.current)
          setItem(item)
        }} 
      />
    </div>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  const isEmpty = leaf.text === '';
  return (
    <span {...attributes}>
      {leaf.suggestion && isEmpty && (
        <SuggestionDisplay suggestion={leaf.suggestion} />
      )}
      {children}
      {leaf.suggestion && !isEmpty && (
        <SuggestionDisplay suggestion={leaf.suggestion} />
      )}
    </span>
  );
};

const SuggestionDisplay = ({ suggestion }) => {
  if (suggestion === suggestionLoadingIdentifier) {
    return (
      <span style={{ color: '#a1a1a1', pointerEvents: 'none' }}>
        <CircularProgress size={13} color={"grey"} />
      </span>
    ) 
  }

  return (
    <span style={{ color: '#a1a1a1', whiteSpace: 'pre-wrap', wordBreak: 'break-word', display: 'inline', }}
      contentEditable={false}
      suppressContentEditableWarning={true}
    >
      {suggestion}
    </span>
  );
};


export default EssayScreen;

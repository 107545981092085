'use client';
import { useContext, useState, useRef } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { API, dbRefs, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import { FcGoogle } from 'react-icons/fc';
import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithRedirect, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { sendSlackNotification, validateEmail } from '../misc/utils';
import { Routes } from '../navigation/routes';

const AuthModal = ({ open, setOpen }) => {
  const { user } = useContext(UserContext)
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleEmailChange = (e) => { setEmail(e.target.value); };
  const handlePasswordChange = (e) => { setPassword(e.target.value); };
  const buttonIsLoading = useRef(false);
  
  const closePopup = () => {
    setOpen(false);
    setEmail('');
    setPassword('');
    setError('');
  };

  const auth_Email = async (e) => {
    e.preventDefault();
  
    if (buttonIsLoading.current) { return; }
    buttonIsLoading.current = true;
  
    try {
      if (!email) {
        buttonIsLoading.current = false;
        alert('Email is required');
        return;
      }
  
      const sanitizedEmail = email.toLowerCase().trim();
  
      if (!validateEmail(sanitizedEmail)) {
        buttonIsLoading.current = false;
        alert(`The email you entered is not permitted. It may be invalid or not allowed by our system. Please use a different email. Email ryan@schoolgoat.com if you need help or believe this is an error.`);
        await sendSlackNotification(`Invalid Email Sign In Blocked:\nattempted: ${sanitizedEmail}`, SLACK_WEBHOOK_CHANNELS.signups);
        return;
      }
  
      const result = await axios.get(`${API}/generalCount`, { params: { 
        matchObj: JSON.stringify({ email: sanitizedEmail }),
        dbRef: dbRefs.users
      }});
  
      if (result.data > 0) {
        await signInWithEmailAndPassword(auth, sanitizedEmail, password);         // Email exists in our system, attempt sign in
      } 
      else {
        await createUserWithEmailAndPassword(auth, sanitizedEmail, password);     // New email, create account
      }
      
      closePopup();
  
    } 
    catch (error) {
      console.error('Auth error:', error);
      
      switch (error.code) {
        case 'auth/wrong-password':
          alert('Invalid password. Please try again.');
          break;
        case 'auth/too-many-requests':
          alert('Too many failed attempts. Please try again later.');
          break;
        case 'auth/email-already-in-use':
          alert('An account already exists with this email. Please try signing in instead.');
          break;
        case 'auth/weak-password':
          alert('Password should be at least 6 characters long.');
          break;
        case 'auth/invalid-credential':
          alert('Invalid email or password. Please try again.');
          break;
        default:
          alert(error.message || 'An error occurred during authentication. Please try again.');
      }
    } 
    finally {
      buttonIsLoading.current = false;
    }
  };

  const auth_Google = async () => {
    if (buttonIsLoading.current) { return; }
    buttonIsLoading.current = true;

    try {
      await signInWithPopup(auth, provider);
      closePopup()
    } 
    catch (error) {
      alert("Error signing in with Google");
    }
    finally {
      buttonIsLoading.current = false;
    }
  };


  return (
    <Drawer.Root open={open} onOpenChange={closePopup} repositionInputs={false}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Handle />
            <Drawer.Title style={{ fontWeight: 600, color: 'var(--color-text)', marginTop: '2rem', fontSize: '1.25rem', marginBottom: '1rem' }}
              id="drawer-title"
              >Login / Signup
            </Drawer.Title>

            <input className="modalFormInput"
              type="email" 
              id="email" 
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
            />
            <input className="modalFormInput" style={{marginTop: "0.5rem"}}
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
            />
            <button className="modalButton"
              onClick={auth_Email}
              >Continue
            </button>

            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
              <p style={{ color: 'var(--color-text3)', marginBottom: '0.5rem' }}>-- Or --</p>
              <button className="modalButton"
                style={{ backgroundColor: 'white', color: 'var(--color-text)', border: '1px solid var(--color-separatorOpaque)', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }}
                onClick={auth_Google}
              >
                <FcGoogle size={20} />
                Continue with Google
              </button>

              <p style={{ color: 'var(--color-text3)', marginTop: '1.5rem', fontSize: '.8rem' }}
                >By clicking Continue or Continue with Google, you agree to our <a href="#" style={{ color: 'var(--color-primary)', textDecoration: 'underline' }} onClick={() => window.open(Routes.TERMS, '_blank')}
                  >Terms of Service
                </a> and <a href="#" style={{ color: 'var(--color-primary)', textDecoration: 'underline' }} onClick={() => window.open(Routes.PRIVACY, '_blank')}
                  >Privacy Policy
                </a>
              </p>


            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default AuthModal;
import React from 'react';
import { SEARCH_PARAMS } from '../misc/constants';
import { useSearchParams } from 'react-router-dom';

const buttonStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '.5rem', background: 'var(--color-primary)', border: 'none', cursor: 'pointer', padding: '1rem 1.8rem', borderRadius: '30px', color: 'var(--color-textButton)', fontSize: "1rem" }

export const GenerateComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onSubmit = () => {
    setSearchParams({
      ...Object.fromEntries(searchParams), // Keep existing params
    [SEARCH_PARAMS.generate]: 'true'
    });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '20rem', padding: '1rem', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '1rem', boxShadow: 'var(--borderShadow)' }}>
      <button style={{...buttonStyle, alignSelf: 'center', transition: 'background-color 0.3s ease'}} 
        onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-primaryLight)'}
        onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-primary)'}
        onClick={onSubmit}
      >
        Create New
      </button>
    </div>
  )
}


export const LoadingComponent = ({ loading, title, subtitle, }) => {
  if ( !loading ) return null
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '20rem', padding: '1rem', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '1rem', boxShadow: 'var(--borderShadow)' }}>
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
      <div style={{ width: '40px', height: '40px', border: '3px solid var(--color-separatorOpaque)', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
    </div>
    <style>{`@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }`}</style>

      <p style={{ fontSize: '1rem', color: 'var(--color-text3)', fontWeight: '500', marginTop: '1rem', lineHeight: '1.5' }} 
        >{title}
      </p>
      <p style={{ fontSize: '.85rem', color: 'var(--color-text4)', fontWeight: '400', lineHeight: '1.5' }}
        >{subtitle}
      </p>
    </div>
  )
}
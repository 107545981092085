import React, { useState, useContext, useEffect } from 'react';
import CustomSelect from './CustomSelect';
import { Upload, X } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { API, dbRefs, RESOURCE_GENERATE_TYPES, RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';

const contentTypes = {
  quiz: { _id: RESOURCE_GENERATE_TYPES.quiz, title: 'Practice Test' },
  flashcards: { _id: RESOURCE_GENERATE_TYPES.flashcards, title: 'Flashcards' },
  notes: { _id: RESOURCE_GENERATE_TYPES.notes, title: 'Study Guide' },
}

const creationMethods = {
  topic: { _id: RESOURCES_UPLOAD_METHODS.topic, title: "Custom topic" },
  select: { _id: RESOURCES_UPLOAD_METHODS.existingResource, title: "My notes" },
  upload: { _id: RESOURCES_UPLOAD_METHODS.file, title: "New file" },
  text: { _id: RESOURCES_UPLOAD_METHODS.text, title: "Paste text from source material" },
}

const pasteTextPlaceholder = `Pages of text from textbooks, articles, or other materials...`

const TextInput = ({ value, onChange, placeholder }) => (
  <textarea 
    value={value || ''}
    onChange={onChange}
    style={{ width: '100%', minHeight: '100px', padding: '.75rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', fontSize: '.9rem', resize: 'none', lineHeight: '1.2' }} 
    placeholder={placeholder} 
  />
);

const FileUpload = ({ value, onChange }) => (
  <div style={{ position: 'relative', width: '100%', cursor: 'pointer' }}
  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
  >
    <input style={{ opacity: 0, position: 'absolute', width: '100%', height: '100%', cursor: 'pointer' }}
      type="file"
      id="file-upload"
      onChange={onChange}
      accept=".pdf"
    />
    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px', width: '100%', padding: '.5rem 1rem', backgroundColor: 'white', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', fontSize: '1rem', cursor: 'pointer', boxShadow: 'var(--borderShadow)' }}
      
    >
      <span style={{ fontSize: '1rem', color: 'var(--color-text1)' }}>{value ? `${value.name}` : 'Select File (PDF)'}</span>
      <Upload size={20} strokeWidth={1.5} color="var(--color-text3)" />
    </button>
  </div>
);


const ContentCreate = ({ isActive, setIsActive, onSubmit }) => {
  const { user } = useContext(UserContext);
  const { isMobileAndBelow } = useBreakpoint();
  const [contentType, setContentType] = useState(contentTypes.quiz)  
  const [ creationMethod, setCreationMethod ] = useState(null)
  const [ creationMethodValue, setCreationMethodValue ] = useState(null)


  const getDataForSelect = async () => {
    let results = null;

    try {
      if ( contentType._id === RESOURCE_GENERATE_TYPES.quiz ) {
        const result = await axios.get(`${API}/generalFind`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['quiz.content']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }
      else if ( contentType._id === RESOURCE_GENERATE_TYPES.flashcards ) {
        const result = await axios.get(`${API}/generalFind`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['flashcards.content']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }
      else if ( contentType._id === RESOURCE_GENERATE_TYPES.notes ) {
        const result = await axios.get(`${API}/generalFind`, { params: { 
          queryObj: JSON.stringify({ user_id: user?._id, ['notes.urlText']: { $exists: true } }),
          optionsObj: JSON.stringify({ sort: { date: -1 }, }),
          dbRef: dbRefs.resources
        }})
        results = result?.data 
      }

      return results
    }
    catch (error) {
      alert('An error occurred. Please try again.')
    }
  }

  useEffect(() => {
    setCreationMethodValue(null)
  }, [creationMethod])


  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '600px', margin: '0 auto', padding: isMobileAndBelow ? '1.5rem' : '2.5rem', height: "100%", borderRadius: '0.5rem', backgroundColor: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)' }}>
      <div style={{ position: 'absolute', top: '0rem', right: '1rem', cursor: 'pointer' }}
        onClick={() => setIsActive(false)}
        onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
        onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
      >
        <X size={24} style={{ color: 'var(--color-text3)' }} />
      </div>
      
      <div style={{ marginBottom: '24px', display: 'flex', flexDirection: 'column', gap: '0.75rem', borderBottom: '1px solid var(--color-separatorOpaque)', paddingBottom: '.75rem' }}>
        <h2 style={{ fontSize: '1.5rem', fontWeight: '600', margin: '0' }}
          >Create Study Material
        </h2>
        {/* <p style={{ fontSize: '1rem', color: 'var(--color-text3)' }}>
          Create custom study materials tailored to your needs
        </p> */}
      </div>

      <div style={{ marginBottom: '32px' }}>
        <label style={{ display: 'block', fontSize: '16px', fontWeight: '500', marginBottom: '12px' }}
          >What do you want to create?
        </label>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {Object.values(contentTypes).map((item) => (
            <div key={item._id} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <input style={{ marginBottom: '2px' }}
                type="radio" 
                id={item._id}
                name="material-type"
                checked={contentType._id === item._id}
                onChange={() => setContentType(item)}
              />
              <label htmlFor={item._id}>{item.title}</label>
            </div>
          ))}
        </div>
      </div>

      <div style={{ marginBottom: '32px' }}>
        <label style={{ display: 'block', fontSize: '16px', fontWeight: '500', marginBottom: '12px' }}
          >What should it be based on?
        </label>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {Object.values(creationMethods).map((item) => {
            const isSelected = creationMethod?._id === item._id
            
            return (
              <div key={item._id}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <input style={{ marginBottom: '2px' }}
                    type="radio" 
                    id={item._id} 
                    name="creation-method" 
                    checked={isSelected}
                    onChange={() => setCreationMethod(item)}
                    />
                  <label htmlFor={item._id}>{item.title}</label>
                </div>

                { isSelected && (
                  <div style={{ marginLeft: '24px', marginTop: '8px' }}>
                    { item._id === RESOURCES_UPLOAD_METHODS.topic && <TextInput value={creationMethodValue} onChange={(e) => setCreationMethodValue(e.target.value)} placeholder="Example: The causes of World War II" /> }
                    { item._id === RESOURCES_UPLOAD_METHODS.existingResource && <CustomSelect value={creationMethodValue} onChange={(value) => setCreationMethodValue(value)} getDisplayField={(item) => item?.title} getIdField={(item) => item?._id} getData={getDataForSelect} /> }
                    { item._id === RESOURCES_UPLOAD_METHODS.file && <FileUpload value={creationMethodValue} onChange={(e) => setCreationMethodValue(e.target.files[0])} /> }
                    { item._id === RESOURCES_UPLOAD_METHODS.text && <TextInput value={creationMethodValue} onChange={(e) => setCreationMethodValue(e.target.value)} placeholder={pasteTextPlaceholder} /> }
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>

      <button style={{ width: '100%', padding: '12px', backgroundColor: 'var(--color-text1)', color: 'white', border: 'none', borderRadius: '.5rem', fontSize: '1rem', fontWeight: '500', cursor: 'pointer', transition: 'all 0.2s', marginTop: 'auto', opacity: creationMethodValue ? 1 : 0.5 }}
        // onClick={() => setIsActive(false)}
        onClick={() => onSubmit({ 
          resourceGenerateType: contentType._id, 
          resourceSourceType: creationMethod._id, 
          value: creationMethodValue 
        })}
        onMouseEnter={(e) => { if ( creationMethodValue ) e.currentTarget.style.opacity = '0.7' }}
        onMouseLeave={(e) => { if ( creationMethodValue ) e.currentTarget.style.opacity = '1' }}
        disabled={!creationMethodValue}
      >
        Create Study Material
      </button>
    </div>
  );
};

export default ContentCreate;

'use client';
import { Drawer } from 'vaul';
import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, S3_URL_PREFIX, RESOURCES_UPLOAD_METHODS, SEARCH_PARAMS, RESOURCE_GENERATE_TYPES, PAYWALL_TYPES, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import { initResourceObj, sendSlackNotification, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { Typography } from '@mui/material';
import ResourceProcessingModal from '../modals/ResourceProcessingModal';
import { useNavigate } from 'react-router-dom';
import { Upload } from 'lucide-react';

const FileUploader = ({ open, setOpen, }) => {
  const { user, checkUserPermission } = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [ isUploading, setIsUploading ] = useState(false)
  const [openResourceProcessingModal, setOpenResourceProcessingModal] = useState(false);

  const fileInputRef = useRef(null);
  
  const validFileTypes = ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg', 'audio/m4a', 'audio/aac', 'audio/x-m4a', 'audio/mp4', 'audio/x-wav'];
  const validExtensions = ['.mp3', '.wav', '.ogg', '.m4a', '.aac', '.pdf'];

  const getFileExtension = (file) => {
    let extension = file.name.split('.').pop();
    let fullExtension = `.${extension}`
    return fullExtension
  }

  useEffect(() => {
    if ( file ) {
      checkFileTypeAndSize(file)
      getFileExtension(file)
    }
  }, [file])


  const checkFileTypeAndSize = (file) => {
    if (
      !validFileTypes.includes(file.type) && 
      !validExtensions.some(ext => file.name.toLowerCase().endsWith(ext))
    ) {
      setFile(null)
      alert(`Invalid file type. Please select one of the following: ${validExtensions.join(', ')}. Contact support if you need more file types.`);
    }

    else if (file.size > 100 * 1024 * 1024) {
      setFile(null)
      alert('File is too large (max 100 MB). Let us know if you need more storage.');
    }
  }
 
  const handleClose = () => {
    setFile(null);

    setIsUploading(false)
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.contains(e.relatedTarget)) return;
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const removeFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };
  

  const uploadFile = async () => {

    setIsUploading(true)

    if (!file) {
      alert('Please select a file first!');
      setIsUploading(false)
      return;
    }

    try {

      if ( !await checkUserPermission(PAYWALL_TYPES.notes) ) {
        handleClose();
        setIsUploading(false)
        return;
      }

      setIsUploading(true)
      const extension = getFileExtension(file)

      if ( extension === '.pdf' ) {
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        await uploadResourceToS3({ resource_id: doc._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        await axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories })
        navigate(`/app/resources/${doc._id}?${SEARCH_PARAMS.tab}=${RESOURCE_GENERATE_TYPES.notes}&${SEARCH_PARAMS.generate}=true`)
        handleClose();
        // setOpenResourceProcessingModal(true);
      }
      else {
        const doc = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        const resource = await uploadResourceToS3({ resource_id: doc._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
        axios.post(`${API}/generateSourceContent`, { resource_id: doc._id, userCategories: user.categories })
        handleClose();
        setOpenResourceProcessingModal(true);
      }
    } 
    catch (error) {
      alert(error.message);
      try {

        const fullMessage = `Error uploading file: ${error.message}\nuser: ${user.email}\nfile: ${file.name}\nfile type: ${file.type}\nfile size: ${file.size}\nUpload method: ${RESOURCES_UPLOAD_METHODS.file}`;

        await sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues);
      } catch (error) {
        console.error('Error sending Slack notification:', error);
      }
    }
    finally {
      setIsUploading(false)
    }
  };


  const dropZoneStyle = {
    
  };

  const fileInfoStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <>
      <div style={{ ...dropZoneStyle, display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: '1rem', alignItems: 'center', justifyContent: 'center',border: `1px solid ${isDragging ? 'blue' : 'var(--color-separatorOpaque)'}`, boxShadow: 'var(--borderShadow)',backgroundColor: isDragging || file ? 'rgba(0, 0, 255, 0.1)' : 'transparent', transition: 'all 0.3s', height: "340px" }}
        tabIndex="0"
        role="button"
        aria-label={file ? `Selected file: ${file.name}. Click to change file or drop a new file.` : "Drop a file here or click to select"}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        // onClick={() => fileInputRef.current.click()}
        onKeyPress={(e) => { if (e.key === 'Enter' || e.key === ' ') { fileInputRef.current.click() } }}
      >
          
        {file ? (
          <div style={fileInfoStyle}>
            <p>{file.name}</p>
            <IconButton onClick={(e) => { e.stopPropagation(); removeFile(); }}
              aria-label="Remove file"
            >
              <CloseIcon />
            </IconButton>
          </div>
              
        ) : (
          <>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
              <h4 style={{fontSize: '1.4rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}
                >Convert class files to notes
              </h4>
              <p style={{ fontSize: '1.1rem', color: 'var(--color-text3)', lineHeight: '1.3', textAlign: 'center', marginTop: '.5rem' }}
                >Good for articles, recorded lectures, notes, etc.<br/>Supports PDFs and Audio (mp3, wav, ogg, m4a, aac)
              </p>
            </div>

            <button style={{backgroundColor: 'var(--color-text1)', color: 'white', border: 'none', borderRadius: '.5rem', padding: '1rem 2rem', fontSize: '1rem', fontWeight: '600', cursor: 'pointer', marginTop: '2rem', transition: 'all 0.2s ease-in-out'}}
              onClick={() => fileInputRef.current.click()}
              onMouseEnter={(e) => e.target.style.opacity = '0.7'}
              onMouseLeave={(e) => e.target.style.opacity = '1'}
              >Select file
            </button>

            <input
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
              aria-label="Select file"
              accept=".pdf,.mp3,.wav,.ogg,.m4a,.aac"
            /> 
          </>
        )}
      </div>

    
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', gap: '1rem', position: 'relative' }}>
        <button style={{backgroundColor: 'var(--color-text1)', color: 'white', border: 'none', borderRadius: '.5rem', padding: '1rem 2rem', fontSize: '1rem', fontWeight: '600', cursor: 'pointer', transition: 'all 0.2s ease-in-out', opacity: file ? 1 : 0}}
          onClick={uploadFile}
          disabled={!file || isUploading}
          onMouseEnter={(e) => { if (!isUploading && file) e.target.style.opacity = '0.7' }}
          onMouseLeave={(e) => { if (!isUploading && file) e.target.style.opacity = '1' }}
          >
            <span style={{opacity: isUploading ? 0 : 1}}>Convert to Notes</span>
        </button>

        { isUploading && (
          <div style={{ display: 'flex', position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
            <div style={{ width: '20px', height: '20px', border: '3px solid var(--color-text1)', borderTop: '3px solid var(--color-textButton)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
            <style>{`@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}`}</style>
          </div>
        )}

      </div>

      <ResourceProcessingModal open={openResourceProcessingModal} setOpen={setOpenResourceProcessingModal} />
    </>
  );
};

export default FileUploader;










// const validateAndGetAudioDuration = (file) => {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open('GET', URL.createObjectURL(file), true);
//     xhr.responseType = 'arraybuffer';
//     xhr.onload = function(e) {
//       if (this.status == 200) {
//         const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//         audioContext.decodeAudioData(this.response, 
//           (buffer) => {
//             // setValidationProgress(100);
//             resolve({
//               duration: buffer.duration,
//               isValid: true
//             });
//           },
//           (err) => {
//             reject(new Error('Unable to decode audio data. The file may be corrupted.'));
//           }
//         );
//       }
//     };

//     xhr.onerror = () => {
//       reject(new Error('Error occurred while reading the file.'));
//     };

//     xhr.send();
//   });
// };

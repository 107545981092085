import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { GenerateComponent } from './Resource_Components';
import { API, RESOURCES_UPLOAD_METHODS, SOURCE_FILE_TYPES } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import { PAYWALL_TYPES } from '../misc/constants';
import { ArrowLeft, FileDown, ArrowDownToLine, Loader2 } from 'lucide-react';

const Button_Padding = "10px 15px"
const Button_Style = { padding: Button_Padding, cursor: 'pointer', borderRadius: '.75rem', display: 'inline-flex', alignItems: 'center', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'transparent', transition: 'all 0.3s', fontSize: '14px', fontWeight: '500', color: 'var(--color-backgroundTertiary)' }



const Resource_Notes = ({ isActive = true, data = {}, }) => {
  const { } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint()
  const [ content, setContent ] = useState('')

  ///////////////////////// GET DATA /////////////////////////
  useEffect(() => {
    if ( isActive ) {
      getData()
    }
  }, [data, isActive])

  const getData = async () => {

    const resourceProcessingMessage = "Your notes are being processed. Please check back later!"

    if ( data?.notes?.urlText ) {
      try {
        let response = await axios.get(data.notes.urlText); 
        let text = response.data
        let html = convertNotesTextToHTML(text)
        let fullHtml = convertNotesFullToHTML(data, html, )
        setContent(fullHtml); 
      } 
      catch (error) {
        console.error('Error fetching text content:', error);
        setContent(resourceProcessingMessage);
      }
    }
    else if ( data?.notes?.submitted || data?.uploadType === RESOURCES_UPLOAD_METHODS.recording || data?.sources?.[0]?.type === SOURCE_FILE_TYPES.audio ) {
      setContent(resourceProcessingMessage)
    }
  }

  const convertNotesTextToHTML = (text) => {
    const lines = text.trim().split('\n');
    let htmlContent = '';
    let listStack = [];
    let isFirstHeader = true;
  
    lines.forEach((line, index) => {
      // If the line is empty, skip it
      if (line.trim() === '') {
        return;
      }
  
      const currentLevel = countIndentationLevel(line);
      line = line.replace(/^\s*([*-])\s*/, '');
  
      if (currentLevel > 0) {
        line = line.replace(/\.$/, '');
        // Bullet point
        while (listStack.length > currentLevel) {
          htmlContent += '</li></ul>';
          listStack.pop();
        }
        if (listStack.length < currentLevel) {
          htmlContent += '<ul>';
          listStack.push(currentLevel);
        } else if (listStack.length > 0) {
          htmlContent += '</li>';
        }
        htmlContent += `<li style="margin-left: ${1 * currentLevel}rem;">${escapeHTML(line)}`;
      } else {
        // Header
        while (listStack.length > 0) {
          htmlContent += '</li></ul>';
          listStack.pop();
        }
        if (!isFirstHeader) {
          htmlContent += '<br>'; // Add line break before header, except for the first one
        }
        line = line.replace(/^#+\s*/, '');
        htmlContent += `<h3>${escapeHTML(line)}</h3>`;
        isFirstHeader = false;
      }
    });
  
    // Close any remaining open lists
    while (listStack.length > 0) {
      htmlContent += '</li></ul>';
      listStack.pop();
    }
  
    return htmlContent;
  };

  const convertNotesFullToHTML = (file, htmlContent, ) => {
    let effectiveFileName = formatFileName(file?.title);
    let dateObj = new Date(file?.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    // <div style="font-size: 14px; margin: 2px 0;">${userName}</div>
  
    const fullHTML = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${effectiveFileName}</title>
        <style>
          body { font-family: Arial, sans-serif; ; }
          h1 { font-weight: bold; font-size: 1.4rem; text-align: center; margin-bottom: 0px; margin-top: 10px; line-height: 1; }
          h3 { font-weight: bold; font-size: 1rem; margin-bottom: 0px; margin-top: 10px; text-align: left; line-height: 1.3; }
          ul { padding-left: 20px; margin-top: 5px;  }
          li { margin-bottom: 5px; font-size: .9rem; list-style-type: disc; text-align: left; line-height: 1.2; }
          .skip-link { position: absolute; top: -40px; left: 0; background: #000; color: white; padding: 8px; z-index: 100; }
          .skip-link:focus { top: 0; }
        </style>
      </head>
      <body>
      
        <header style="text-align: center; font-size: 20px; margin-bottom: 30px; margin-top: 0px; border-bottom: 1px solid #ddd; padding-bottom: 10px;">
          <h1>${effectiveFileName}</h1>
          <p style="font-size: .9rem; margin: .5rem;">${dateObj}</p>
        </header>

        <main id="main-content" role="document" aria-label="${effectiveFileName}">
          ${htmlContent}
        </main>
      </body>
    </html>
  `;
  
    return fullHTML;
  };

  function formatFileName(fileName) {
    return fileName.replace(/-[^-]*$/, '');
  }

  const countIndentationLevel = (line) => {
    const match = line.match(/^\s*([*-])/);
    return match ? 1 + Math.floor((match[0].length - 1) / 2) : 0;
  };
  
  const escapeHTML = (str) => {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      .replace(/\//g, '&#x2F;')
      .replace(/\\/g, '&#x5C;')
      .replace(/`/g, '&#x60;')
      .replace(/=/g, '&#x3D;')
      .replace(/-/g, '&#x2D;')
      .replace(/\^/g, '&#x5E;')
      .replace(/~/g, '&#x7E;')
      .replace(/\|/g, '&#x7C;');
  };

  if ( !isActive ) return null
  // if ( !content || content.length === 0 ) return <GenerateComponent />

  return (
    <div style={{ backgroundColor: "var(--color-backgroundSecondary)",  border: "1px solid var(--color-separatorOpaque)", borderRadius: "1rem", minHeight: "700px",padding: isPhoneAndBelow ? "1rem" : "3rem", boxShadow: 'var(--borderShadow)', position: 'relative' }}
      role="document"
      aria-label="Notes file content"
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );  
}


export default Resource_Notes;

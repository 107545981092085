import React, { useContext, useEffect, useRef, useState } from 'react';
import { API, dbRefs, PAYWALL_TYPES, RESOURCE_GENERATE_TYPES, SEARCH_PARAMS, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ArrowDownToLine, Book } from 'lucide-react';
import Subheader from '../components/Subheader';
import Resource_Notes from '../components/Resource_Notes';
import Resource_Quiz from '../components/Resource_Quiz';
import Resource_Flashcards from '../components/Resource_Flashcards';
import Resource_Teach from '../components/Resource_Teach';
import { Routes } from '../navigation/routes';
import { getTextFromResource } from '../misc/utils';
import { LoadingComponent } from '../components/Resource_Components';

const Button_Padding = "10px 15px"
const Button_Style = { padding: Button_Padding, cursor: 'pointer', borderRadius: '20px', display: 'inline-flex', alignItems: 'center', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'transparent', transition: 'all 0.3s', fontSize: '14px', fontWeight: '500', color: 'var(--color-backgroundTertiary)' }

const tabs = {
  notes: "Notes",
  quiz: "Quiz",
  flashcards: "Flashcards",
  teach: "Teach",
}

const ResourcesProfileScreen = () => {
  const { resource_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user, setShowPaywallType } = useContext(UserContext);
  const [data, setData] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [loading, setLoading] = useState(false);
  const isGenerating = useRef(false);

  // Get tab from URL or default to notes
  const activeTab = searchParams.get(SEARCH_PARAMS.tab) || tabs.notes;

  // Update tab via URL instead of state
  const setActiveTab = (tab) => {
    setSearchParams({ ...Object.fromEntries(searchParams), tab });
  };

  ///////////////////////// GET DATA /////////////////////////
  useEffect(() => {
    getData();
  }, [resource_id, refreshData]);

  const getData = async () => {
    try {
      let result = await axios.get(API + '/generalFindOne', { params: { queryObj: JSON.stringify({ _id: resource_id }), dbRef: dbRefs.resources } });
      setData(result?.data);
    } catch (error) {
      console.error('Error fetching text content:', error);
    }
  };

  useEffect(() => {
    const shouldGenerate = searchParams.get(SEARCH_PARAMS.generate) === 'true';
    const currentTab = searchParams.get(SEARCH_PARAMS.tab);
  
    if (shouldGenerate && currentTab && !isGenerating.current) {
      isGenerating.current = true;
      generateContent(currentTab).finally(() => {
        isGenerating.current = false;
      });
      // Clear generate flag but keep the tab
      setSearchParams({ tab: currentTab }, { replace: true });
    }
  }, [searchParams.get(SEARCH_PARAMS.generate)]);

  const generateContent = async (tabType) => {
    try {
      setLoading(true);
      let text = ''
      
      switch (tabType) {
        case RESOURCE_GENERATE_TYPES.notes:
          await axios.post(`${API}/generateNotes`, { file_id: resource_id });
          break;
        case RESOURCE_GENERATE_TYPES.flashcards:
          text = await getTextFromResource(data)
          await axios.post(`${API}/generateFlashcards`, { text: text, topic: '', resource_id: resource_id });
          break;
        case RESOURCE_GENERATE_TYPES.quiz:
          text = await getTextFromResource(data)
          await axios.post(`${API}/generateQuiz`, { text: text, topic: '', resource_id: resource_id });
          break;
        case RESOURCE_GENERATE_TYPES.teach:
          text = await getTextFromResource(data)
          await axios.post(`${API}/generateTeach`, { text: text, topic: '', resource_id: resource_id });
          break;
      }

      setRefreshData(prev => !prev);
    } 
    catch (error) {
      console.error(`Error generating ${tabType}:`, error);
    } 
    finally {
      setLoading(false);
    }
  };


  /////////////////////// COMPONENTS /////////////////////////
  
  const DownloadButtons = ({ }) => {
    const [ loading, setLoading ] = useState(false)
    const notesTypes = { pdf: "PDF", docx: "DOCX" }

    const handleDownload = async (type, url) => {
      try {
        setLoading(type)
        let newUrl = ''

      if ( user?.isMember ) {
        if ( type === notesTypes.pdf ) {
          const result = await axios.post(API + '/generateNotesPdf', { resource_id: data._id, resource_title: data.title, notes_urlText: data.notes.urlText })
          newUrl = result.data.url
          setData({ ...data, notes: { ...data.notes, urlPdf: newUrl } })

        }
        else if ( type === notesTypes.docx ) {
          const result = await axios.post(API + '/generateNotesDocx', { resource_id: data._id, resource_title: data.title, notes_urlText: data.notes.urlText })
          newUrl = result.data.url
          setData({ ...data, notes: { ...data.notes, urlDocx: newUrl } })
        }
        return window.open(newUrl, '_blank');
      } 
      else {
        setShowPaywallType(PAYWALL_TYPES.MEMBERS_ONLY_FEATURE)
      }
      }
      catch (error) {
        alert('Error downloading notes. Please try again.')
        console.error('Error downloading notes:', error);
      }
      finally {
        setLoading(false)
      }
    };

    const loadingSpinner = (type) => {
      return loading == type ? (
        <div style={{ width: '16px', height: '16px', border: '2px solid currentColor', borderTopColor: 'transparent', borderRadius: '50%', animation: 'loading-spinner 1s linear infinite', }} />
      ) : <ArrowDownToLine size={16} />
    }

    if ( data?.notes?.urlText ) return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} role="group" aria-label="Download options">

        <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', height: '36px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'var(--color-text1)',color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)', gap: '5px' }}
              onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
              onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          onClick={async () => await handleDownload(notesTypes.pdf, data?.notes?.urlPdf)}
          aria-label="Download PDF"
        >
          {loadingSpinner(notesTypes.pdf)}
          PDF
        </button>

        <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', height: '36px', paddingLeft: '1rem', paddingRight: '1rem', backgroundColor: 'var(--color-text1)',color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)', gap: '5px' }}
              onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
              onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          onClick={async () => await handleDownload(notesTypes.docx, data?.notes?.urlDocx)}
          aria-label="Download DOCX"
        >
          {loadingSpinner(notesTypes.docx)}
          Docx
        </button>

      </div>
    );
    else return null
  };


  return (
    <div id="wrapper">          

      <ScreenHeader 
        title={"File Details"} 
        showBackButton={true} 
        onBackClick={() => navigate(`${Routes.APP}/${Routes.NOTES}`)}
        // icon={<Book />} 
        // leftChildren={<BackButton />} 
        rightContent={<DownloadButtons />} 
      />

      <main id="main">
        <div className="inner">
          
          {/* <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} /> */}

          <LoadingComponent loading={loading} title={`Generating your Notes`} subtitle={`This may take ~30 seconds`} />

          { !loading && (
            <>            
              <Resource_Notes isActive={true} data={data} setRefreshData={setRefreshData} />
              {/* <Resource_Quiz isActive={activeTab === tabs.quiz} data={data} setData={setData} setRefreshData={setRefreshData} />
              <Resource_Flashcards isActive={activeTab === tabs.flashcards} data={data} setData={setData} setRefreshData={setRefreshData} />
              <Resource_Teach isActive={activeTab === tabs.teach} data={data} setData={setData} setRefreshData={setRefreshData} /> */}
            </>
          )}

        </div>
      </main>
    </div>
  );  
};


export default ResourcesProfileScreen;


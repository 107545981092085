import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import { Routes } from './routes';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import FreeTrialPopup from '../modals/FreeTrialPopup3';
import NewUserModal from '../modals/NewUserModal';
import CheckoutModal from '../modals/CheckoutModal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ProtectedRoute = ({ children }) => {
  const { user, userLoaded } = useContext(UserContext);

  if ( !userLoaded ) return null
  else if ( !user ) return <Navigate to={Routes.LANDING} />
  return (
    <Elements stripe={stripePromise}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden', position: 'relative', backgroundColor: 'var(--color-backgroundSecondary)' }}>
        <Outlet />
        <FreeTrialPopup />
        <NewUserModal />
        {/* <AbandonedCheckoutModal open={openAbandonedCheckoutModal} setOpen={setOpenAbandonedCheckoutModal} /> */}
        {/* <CheckoutModal /> */}
      </div>
    </Elements>
  )
};

export default ProtectedRoute;




// else if ( !user?.isMember ) return <OnboardingScreen />



//////////////////// API & S3 ////////////////////
export const API = process.env.REACT_APP_BACKEND_API
export const S3_URL_PREFIX = `https://academicweapon.s3.amazonaws.com/`;
export const REFERRAL_CODE_USED_KEY = 'referralCodeUsed';


//////////////////// FREE TRIAL ////////////////////
export const FREE_TRIAL_DURATION = 4 * 24 * 60 * 60 * 1000; // 4 days

//////////////////// NAVIGATION & URL SCHEME ////////////////////
export const SEARCH_PARAMS = {
  _id: "_id",
  ref: "ref",
  checkout_abandoned: "checkout_abandoned",
  generate: "generate",
  tab: "tab",
  checkout: "checkout",
}

export const LOCAL_STORAGE_PARAMS = {
  _id: "_id",
  referralCodeUsed: "referralCodeUsed",
}

export const SESSION_STORAGE_PARAMS = {
  stripeCheckoutPending: "stripeCheckoutPending",
}

///////////////////////// MONGO /////////////////////////
export const dbRefs = {
  users: "USERS",
  files: "FILES",
  quizzes: "QUIZZES",
  flashcards: "FLASHCARDS",
  messages: "MESSAGES",
  resources: "RESOURCES",
  essays: "ESSAYS",
};


//////////////////// SLACK ////////////////////
export const SLACK_WEBHOOK_CHANNELS = {
  issues: "issues",
  general: "general",
  stripe: "stripe",
  messages: "messages",
  resources: "resources",
  signups: "signups",
  paywall: "paywall",
}

//////////////////// RESOURCES ////////////////////
export const RESOURCES_UPLOAD_METHODS = {
  existingResource: "existingResource",
  file: "file",
  text: "text",
  recording: "recording",
  topic: "topic",
}
export const SOURCE_FILE_TYPES = {
  pdf: {
    extensions: ['pdf'],
    label: 'pdf',
    isAllowed: true,
  },
  ppt: {
    extensions: ['ppt', 'pptx', 'pptm'],
    label: 'ppt',
    isAllowed: false,
  },
  doc: {
    extensions: ['doc', 'docx', 'docm'],
    label: 'doc',
    isAllowed: false,
  },
  txt: {
    extensions: ['txt', 'rtf', 'md', 'csv'],
    label: 'txt',
    isAllowed: false,
  },
  audio: {
    extensions: ['mp3', 'wav', 'ogg', 'm4a', 'aac', 'wma', 'flac'],
    label: 'audio',
    isAllowed: true,
  }
};

export const RESOURCE_GENERATE_TYPES = {
  notes: "notes",
  flashcards: "flashcards",
  quiz: "quiz",
  teach: "teach",
}


///////////////////////// AUDIO RECORDING /////////////////////////
export const FREE_MINUTES = 60;
export const recordingIntervalDuration = parseInt(process.env.REACT_APP_RECORDING_INTERVAL_DURATION)
export const audioMimeTypes = {
  'audio/webm': '.webm',
  'audio/wav': '.wav',
  'audio/ogg': '.ogg',
  'audio/ogg; codecs=opus': '.opus',
  'audio/mp4': '.m4a',
  'audio/m4a': '.m4a',
  'audio/aac': '.aac',
  'audio/mp3': '.mp3',
  'audio/mpeg': '.mp3',
  'audio/flac': '.flac'
};


///////////////////////// STRIPE /////////////////////////
export const PRICING_CONFIG_URL = "https://academicweapon.s3.us-east-1.amazonaws.com/admin/PricingConfig.json"
export const STRIPE_BILLING_PORTAL_URL = "https://billing.stripe.com/p/login/eVa15GeYedHs7AI5kk" // live
// export const STRIPE_BILLING_PORTAL_URL = "https://billing.stripe.com/p/login/test_bIY5od6oqa3zgDK000" // test
export const STRIPE_PRICE_OBJ = {
  // annual: "price_1Pma9OLkURBDGvXahFL5lhZl",   // EN
  // monthly: "price_1OssCALkURBDGvXaWifzcJw1", // EN
  annual: "price_1QKkUcLkURBDGvXapQDkT1jb",  
  monthly: "price_1QKkVqLkURBDGvXaMAFV6m6E",  
  standard: "price_1Q06x6LkURBDGvXaymUfjgjG",
}
export const STRIPE_PROMOTION_CODE = "promo_1QDPuILkURBDGvXaKNpjCfHJ"
export const stripeSubscriptionStates = {
  trialing: "trialing",
  active: "active",
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  past_due: "past_due",
  canceled: "canceled",
  unpaid: "unpaid",
  paused: "paused",
  free_trial: "free_trial",
  duplicate_account: "duplicate_account",
}

export const PAYWALL_SCEHMA_VERSION = 1

export const PAYWALL_EVENTS = {

  PAYWALL_SHOWN: "paywall_shown",
  DECLINED: "declined",                
  ACCEPTED: "accepted",                // NEW

  PLANS_SHOWN: "plans_shown",          // NEW
  PLANS_CHECKOUT: "plans_checkout",    // NEW
  PLANS_DECLINED: "plans_declined",    // NEW

  CHECKOUT: "checkout",
  
  DECLINED_REASON: "declined_reason",
  
  CHECKOUT_ABANDONED: "checkout_abandoned",
  CHECKOUT_ABANDONED_REASON: "checkout_abandoned_reason",
  CHECKOUT_ABANDONED_SKIPPED: "checkout_abandoned_skipped",

  // OLD
  DEAL_CHECKOUT: "deal_checkout",
  DEAL_DECLINED: "deal_declined",
  FEEDBACK_SUBMITTED: "feedback_submitted",
  FEEDBACK_SKIPPED: "feedback_skipped",


}

export const PAYWALL_TYPES = {
  solver: "solver",
  resources: "resources",
  
  file_uploader: "file_uploader",                           // new
  text_uploader: "text_uploader",                           // new
  teach: RESOURCE_GENERATE_TYPES.teach,                     // new
  quiz: RESOURCE_GENERATE_TYPES.quiz,                       // new
  flashcards: RESOURCE_GENERATE_TYPES.flashcards,           // new
  notes: RESOURCE_GENERATE_TYPES.notes,                       // new
  ai_detection: "ai_detection",                             // new


  resources_recording: "resources_recording",
  essay_evaluation: "essay_evaluation",
  TRIAL_ENDED: "trial_ended",
  DUPLICATE_ACCOUNT: "duplicate_account",
  MEMBERS_ONLY_FEATURE: "members_only_feature",
  MEMBERSHIP: "membership",
}


///////////////////////// DESIGN /////////////////////////

export const COLOR_ARRAY = [
  // "#AF52DE", 
  "#5656ff",
  // "#8b5cf6",
"#FF3B30", "#34C759", "#30B0C7", "#007aff", "#FF9500", "#FFCC00", "#00C7BE", "#32ADE6", "#5856D6", "#FF2D55"]

///////////////////////// MIXPANEL /////////////////////////
export const MIXPANEL_EVENTS = {
  solver: "Solver", // Solver         
  user_created: "User Created",
  essay_evaluation: "Essay Evaluation", //
  flashcard_next: "Flashcard Next",
  quiz_next: "Quiz Next",
  user_signed_in: "User Signed In",
  user_created: "User Created",
  essay_suggestion: "Essay Suggestion",
  essay_ai_detection: "Essay AI Detection",
}

///////////////////////// OPENAI /////////////////////////
export const OPENAI_CHAT_ROLES = { assistant: 'assistant', user: 'user', system: 'system' }

'use client';
import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { API, dbRefs } from '../misc/constants';
import { format } from 'date-fns';
import { ArrowRight } from 'lucide-react';


const Tile = ({ item, index, onSelect, currentItemId }) => {

  const isCurrentItem = item?._id === currentItemId
  return (
    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '.7rem .5rem', borderBottom: '1px solid var(--color-separatorOpaque)', cursor: 'pointer' }}
      onClick={() => onSelect(item)}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
    >
      <div style={{ flex: 1, color: 'var(--color-text1)', minWidth: 0, marginRight: '1rem' }}>
        <div style={{ display: "flex", alignItems: "center", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <span style={{ color: 'var(--color-text1)' }}>
            {item.title || 'Untitled'}
          </span>

          { isCurrentItem && (
            <span style={{ display: "flex", alignItems: "center", justifyContent: "center", color: 'var(--color-text4)', fontSize: '.8rem', backgroundColor: 'var(--color-backgroundTertiary)', padding: '2px 4px', borderRadius: '4px', marginLeft: '4px' }}>
              Current
            </span>
          )}

        </div>
        <span style={{ color: 'var(--color-text4)', fontSize: '.8rem', marginTop: '5px' }}
          >{format(new Date(item.date), 'MMM-dd')}
        </span>
      </div>
      <div style={{ display: "flex", flexShrink: 0, alignItems: 'center', justifyContent: 'center' }}>
        <ArrowRight size={15} style={{ color: 'var(--color-text3)' }} />
      </div>
    </div>
  )
}


const SelectEssayModal = ({ open, setOpen, onSelect, currentItemId }) => {
  const { user, } = useContext(UserContext)
  const [ data, setData ] = useState([])

  useEffect(() => {
    if ( open ) {
      getData()
    }
  }, [open])


  const getData = async () => {
    try {
      let result = await axios.get(API + '/generalAggregation', { params: {
        matchObj: JSON.stringify({ user_id: user?._id }),
        sortObj: JSON.stringify({ updatedAt: -1 }),
        dbRef: dbRefs.essays
      }})
      let results = result?.data
      // Sort current item to top
      results = results.sort((a, b) => {
        if (a._id === currentItemId) return -1;
        if (b._id === currentItemId) return 1;
        return 0;
      });
      setData(results)
    }
    catch (error) {
      console.error(error)
    }
  }

  const handleSelect = (item) => {
    setOpen(false)
    onSelect(item)
  }

  return (
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="dialog"
          aria-modal="true"
          aria-description="Select an essay to edit"
        >
          <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Handle />
            <Drawer.Title className="modalTitle" style={{ marginTop: '2rem', }}
              id="drawer-title"
              >Select Essay
            </Drawer.Title>

            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              {data.map((item, index) => (
                <Tile key={index} item={item} index={index} onSelect={handleSelect} currentItemId={currentItemId} />
              ))}
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default SelectEssayModal;

import { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';


const CustomSelect = ({ 
  value, 
  onChange, 
  getDisplayField = (item) => item, 
  getData = async () => [], 
  getIdField = (item) => item 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ data, setData ] = useState([])
  const selectRef = useRef(null);
  const selectedBackgroundColor = "#eff6ff"
  const selectedTextColor = "#2563eb"

  useEffect(() => {
    const fetchData = async () => {
      const results = await getData()
      setData(results)
    }
    fetchData()
  }, [isOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={selectRef} style={{ display: 'flex', flex: 1,  backgroundColor: 'var(--color-backgroundSecondary)',  borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: "var(--borderShadow)", position: 'relative'  }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, padding: '.5rem 1rem', color: 'var(--color-text1)', cursor: 'pointer',  minWidth: 0, overflow: 'hidden', borderRadius: '.5rem' }}
        role="combobox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        onClick={() => setIsOpen(!isOpen)}
        onMouseOver={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-background)'; }}
        onMouseOut={(e) => { e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'; }}
      >
        <span>{getDisplayField(value) || 'Select'}</span>
        <ChevronDown size={20} color='var(--color-text3)' strokeWidth={1.5} style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} />
      </div>
      
      {isOpen  && (
        <div style={{ position: 'absolute', top: 'calc(100% + 5px)', left: '0', width: '100%', backgroundColor: 'white', borderRadius: '6px', border: '1px solid #e2e8f0', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', zIndex: 1000, maxHeight: '215px', overflowY: 'auto', minHeight: '100px' }}
          role="listbox"
        >
          {data.map((option, index) => {

            const displayField = getDisplayField(option)
            const valueId = getIdField(value)
            const isSelected = valueId === getIdField(option)
            
            return (
              <div style={{ padding: '.5rem 1rem',cursor: 'pointer', fontSize: '.9rem', transition: 'background-color 0.2s',color: 'var(--color-text2)',
                ...(isSelected && { backgroundColor: selectedBackgroundColor, color: selectedTextColor }) 
              }}
                key={index}
                role="option"
                aria-selected={isSelected}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = selectedBackgroundColor }
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = isSelected ? selectedBackgroundColor : 'var(--color-backgroundSecondary)' }
              >
                {displayField}
              </div>
            )
          })}
        </div>
      )}
    </div>
  );
};

export default CustomSelect
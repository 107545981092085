import React, { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { logPaywallAction, sendSlackNotification } from '../misc/utils';
import { PAYWALL_EVENTS, SESSION_STORAGE_PARAMS, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';

const PAYWALL_RESPONSE_TYPES = {
  payment_methods: { _id: "payment_methods", label: "Payment method", subText: "The payment method I use wasn't offered", question: "What payment method would you need?" },
  technical_issues: { _id: "technical_issues", label: "Technical issues", subText: "The checkout wasn't working", question: "What was the issue?" },  
  questions: { _id: "questions", label: "Question", subText: "I have questions before subscribing", question: "What is your question? We'll get back to you ASAP." },  
  price: { _id: "price", label: "Price", subText: "The price is too high", question: "What would you pay?" },
  other: { _id: "other", label: "Other", subText: "Something else not listed", question: "Please tell us more..." },
}

const AbandonedCheckoutModal = ({ open, setOpen}) => {
  const { user, } = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  const [paywallResponse, setPaywallResponse] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [height, setHeight] = useState('auto');
  const contentRef = useRef(null);

  useEffect(() => {
    const checkStripeReturn = () => {
      const isPending = sessionStorage.getItem(SESSION_STORAGE_PARAMS.stripeCheckoutPending);
      if (isPending === 'true') {
        setOpen(true);
        sessionStorage.removeItem(SESSION_STORAGE_PARAMS.stripeCheckoutPending);
      }
    };

    window.addEventListener('popstate', checkStripeReturn);
    window.addEventListener('pageshow', checkStripeReturn);
    checkStripeReturn();

    return () => {
      window.removeEventListener('popstate', checkStripeReturn);
      window.removeEventListener('pageshow', checkStripeReturn);
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.offsetHeight;
      setHeight(`${height}px`);
    }
  }, [isVisible, paywallResponse]);

  useEffect(() => {
    setTextInput('');
  }, [paywallResponse]);

  useEffect(() => {
    if (open) {
      setTimeout(() => { setIsVisible(true) }, 50);
    }
    else {
      setIsVisible(false);
      setTextInput('');
      setPaywallResponse(null);
    }
  }, [open]);

  useEffect(() => {
    if (user?._id && open) {
      initPaywall();
    }
  }, [user?._id, open]);

  const initPaywall = async () => {
    try {
      // setTimeout(() => { setIsVisible(true) }, 50);
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.CHECKOUT_ABANDONED, });
    }
    catch (error) {
      console.error('Error with paywall init:', error);
    }
  }

  const handleFeedbackSubmitted = async () => {
    try {

      // if ( !paywallResponse ) {
      //   alert('Please select an option before submitting!')
      //   return
      // }

      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.CHECKOUT_ABANDONED_REASON, type: 'other', context: { message: textInput } });

      if (textInput) {
        await sendSlackNotification(`${user.email}\nType: Checkout abandoned feedback\nMessage: ${textInput}`, SLACK_WEBHOOK_CHANNELS.messages);
      }
      setOpen(false);
    }
    catch (error) {
      console.error('Error with features submit:', error);
    }
  }

  const handleFeedbackSkipped = async () => {
    try {
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.CHECKOUT_ABANDONED_SKIPPED, });
      setOpen(false);
    }
    catch (error) {
      console.error('Error with skip:', error);
    }
  }

  const buttonStyles = { width: '100%', height: '44px', borderRadius: '8px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' };


  if (!open) return null;
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '100%', minHeight: '100vh', backgroundColor: 'rgba(248, 250, 252, 0.8)', backdropFilter: 'blur(16px)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px', zIndex: 1000, opacity: isVisible ? 1 : 0, transition: 'opacity 300ms ease-in-out' }}>

      <div style={{ overflow: 'hidden', height: height, transition: 'height 300ms ease-out', width: "90%", maxWidth: "600px" }}>
        <div style={{ width: '100%', maxWidth: '448px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)', border: '1px solid #e5e7eb', transform: isVisible ? 'translateY(0)' : 'translateY(20px)', opacity: isVisible ? 1 : 0, transition: 'transform 600ms ease-out, opacity 600ms ease-out',   }}
        ref={contentRef}
        >

          <div style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '16px', }}>
            <div style={{ marginBottom: '8px' }}>
              <h3 style={{ fontSize: '1.25rem', fontWeight: '600', color: 'var(--color-text1)', marginBottom: '8px' }}
                >Why didn't you complete checkout?
              </h3>
              <p style={{ fontSize: '0.95rem', color: '#64748b' }}>
                We'd love to know!
              </p>
            </div>

            <textarea style={{ width: '100%', minHeight: '5rem', padding: '.5rem', borderRadius: '8px', border: '1px solid #e5e7eb', resize: 'vertical', fontSize: '0.85rem', lineHeight: '1.25' }}
              placeholder="Tell us more about why you're passing..."
              value={textInput} 
              onChange={e => setTextInput(e.target.value)}
            />

            {/* {Object.values(PAYWALL_RESPONSE_TYPES).map(option => (
              <div key={option._id}>
              <button style={{ width: '100%', backgroundColor: 'white', borderRadius: '8px', cursor: 'pointer', color: '#475569', fontSize: '0.95rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                key={option._id}
                onClick={() => setPaywallResponse(paywallResponse?._id === option._id ? null : option)}
              >
                <input style={{ width: '16px', height: '16px', marginRight: '.75rem', marginLeft: 0, }}
                  type="radio" 
                  name="feedback-option"
                  value={option._id}
                  checked={paywallResponse?._id === option._id}
                  readOnly={true}
                />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '2px', backgroundColor: "transparent", }}>
                  <p style={{ margin: 0, fontWeight: '600', color: 'var(--color-text1)', fontSize: '1rem' }}>{option.label}</p>
                  <p style={{ margin: 0, fontSize: '0.85rem', color: 'var(--color-text4)' }}>{option.subText}</p>
                </div>
              </button>
              

              { paywallResponse?._id === option._id &&
                <div style={{ paddingTop: '10px' }}>
                  <textarea style={{ width: '100%', minHeight: '5rem', padding: '.5rem', borderRadius: '8px', border: '1px solid #e5e7eb', resize: 'vertical', fontSize: '0.85rem', lineHeight: '1.25' }}
                    placeholder={option.question}
                    value={textInput}
                    onChange={e => setTextInput(e.target.value)}
                  />
                </div>
              }
              
              </div>


            ))} */}
          </div>

          <div style={{ padding: '0 24px 24px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <button 
              onClick={handleFeedbackSubmitted}
              disabled={!textInput}
              style={{ ...buttonStyles, backgroundColor: 'var(--color-text1)', color: 'white', border: 'none', fontSize: '1rem', fontWeight: 600, opacity: textInput ? 1 : 0.5 }}
              onMouseEnter={e => {
                if (textInput) {
                  e.target.style.backgroundColor = '#1f1f1f'
                }
              }}
              onMouseLeave={e => {
                if (textInput) {
                  e.target.style.backgroundColor = 'var(--color-text1)'
                }
              }}
              >Submit Feedback
            </button>
            {/* <button 
              onClick={handleFeedbackSkipped}
              style={{ ...buttonStyles, backgroundColor: 'white', border: '1px solid #e5e7eb', fontSize: '1rem', color: 'var(--color-text1)' }}
              onMouseEnter={e => e.target.style.backgroundColor = '#f9fafb'}
              onMouseLeave={e => e.target.style.backgroundColor = 'white'}
              >Skip
            </button> */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default AbandonedCheckoutModal;
import React, { useContext, useState } from 'react';
import { STRIPE_BILLING_PORTAL_URL } from '../misc/constants';
import { UserContext } from '../contexts/UserProvider';
import ScreenHeader from '../components/ScreenHeader2';
import { Crown, MessageCircle, HeartHandshake, LogOut, ArrowRight, CreditCard, Settings } from 'lucide-react';
import ContactModal from '../modals/ContactModal2';
import FeedbackModal from '../modals/FeedbackModal2';
import { Routes } from '../navigation/routes';
import { useNavigate } from 'react-router-dom';


const Tile = ({ item, index }) => {
  if ( item.isShown ) {
    return (
      <li key={index}>
        <button style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", borderBottom: `1px solid var(--color-separatorOpaque)`, padding: "1.5rem", cursor: "pointer", textAlign: "left", color: `var(--color-text3)`, fontSize: "1rem"}}
          onClick={item.onClick}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
        >
          <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <item.icon size={20} style={{ marginRight: '20px', color: 'var(--color-text3)' }} aria-hidden="true" />
            {item.text}
          </span>
          <ArrowRight size={'1.3rem'} style={{ marginRight: '15px', color: 'var(--color-text3)' }} aria-hidden="true" />
        </button>
      </li>
    )
  }
  return null;
}


const OptionsScreen = () => {
  const { user, logout, setShowCheckoutModal } = useContext(UserContext);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const navigate = useNavigate();

  const data = [
    // { icon: Crown, text: 'Get Full Access', onClick: () => setShowCheckoutModal(true), isShown: !user?.isMember },
    { icon: Crown, text: 'Get Full Access', onClick: () => navigate(Routes.JOIN), isShown: !user?.isMember },
    { icon: MessageCircle, text: 'Contact Us', onClick: () => setOpenContactModal(true), isShown: true },
    { icon: HeartHandshake, text: 'Give Feedback', onClick: () => setOpenFeedbackModal(true), isShown: true },
    { icon: CreditCard, text: 'Billing', onClick: () => window.open(STRIPE_BILLING_PORTAL_URL, '_blank'), isShown: user?.isMember },
    { icon: LogOut, text: 'Log out', onClick: () => logout(), isShown: true },
  ];

  return (
    <div id="wrapper">

      {/* <ScreenHeader title="Options" icon={<Settings />} showBackButton={true} /> */}

      <main id="main" >
        <div className="inner" style={{ maxWidth: "900px", width: "100%" }}>
          <nav aria-label="Options menu">
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {data.map((item, index) => <Tile key={index} item={item} index={index} />)}
            </ul>
          </nav>

          <ContactModal open={openContactModal} setOpen={setOpenContactModal} />
          <FeedbackModal open={openFeedbackModal} setOpen={setOpenFeedbackModal} />
        </div>
      </main>
    </div>
  );
};

export default OptionsScreen;
import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { API, FREE_MINUTES, FREE_TRIAL_DURATION, REFERRAL_CODE_USED_KEY, dbRefs, stripeSubscriptionStates, SLACK_WEBHOOK_CHANNELS, PAYWALL_TYPES, MIXPANEL_EVENTS, RESOURCES_UPLOAD_METHODS, } from '../misc/constants';
import axios from 'axios';
import { generateReferralCode, sendSlackNotification, validateEmail } from '../misc/utils';
import mixpanel from 'mixpanel-browser';

// Create the context
export const UserContext = createContext(null);
const isActiveSubscription = (userData) => userData?.stripeSubscription?.status ? (userData?.stripeSubscription?.status === stripeSubscriptionStates.active || userData?.stripeSubscription?.status === stripeSubscriptionStates.trialing) : false;


export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [ userLoaded, setUserLoaded ] = useState(false)
  const [ showPaywallType, setShowPaywallType ] = useState(false)
  const [ showCheckoutModal, setShowCheckoutModal ] = useState(false)
  const auth = getAuth();
  const authChangeInProgress = useRef(false);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        await handleAuthStateChange(firebaseUser);
      } 
      catch (err) {
        console.error(err);
      } 
      finally {
        setUserLoaded(true);
      }
    });
    
    return () => unsubscribe();
  }, [auth]);

  //////////////////// HANDLE AUTH STATE CHANGE ////////////////////

  const handleAuthStateChange = async (firebaseUser) => {
    if (authChangeInProgress.current) return;
    authChangeInProgress.current = true;
    
    try {
      if (!firebaseUser) {
        setUser(null);
        return;
      }

      const localStorage_id = localStorage.getItem('_id');
      const isUniqueAccount = await checkUserForUniqueAccount(firebaseUser, localStorage_id);
      await getUserData(firebaseUser, localStorage_id, isUniqueAccount);

      if (!isUniqueAccount) {
        setShowPaywallType(PAYWALL_TYPES.DUPLICATE_ACCOUNT);
      }
    } 
    catch (err) {
      console.error(err);
      alert("An error occurred while fetching user data. Try refreshing the page and logging in again.")
      setUser(null);
    } 
    finally {
      authChangeInProgress.current = false;
    }
  };


  const checkUserForUniqueAccount = async (firebaseUser, localStorage_id) => {
    try {

      if ( !localStorage_id ) { return true; }
      else if ( localStorage_id === firebaseUser.uid ) { return true; }
      else if ( localStorage_id !== firebaseUser.uid ) {

        let result = await axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ _id: { $in: [firebaseUser.uid, localStorage_id] }, }),
          dbRef: dbRefs.users
        }})
        
        let results = result.data
        let hasActiveMembership = results.some((k) => isActiveSubscription(k))

        if ( !hasActiveMembership ) {
          await axios.put(`${API}/generalUpdateOne`, {
            matchObj: { email: firebaseUser.email },
            updateObj: { $set: { ['stripeSubscription.status']: stripeSubscriptionStates.duplicate_account, }},
            dbRef: dbRefs.users,
          })
          localStorage.removeItem('_id');
          return false;
        }
      }

      return true;
    }
    catch (err) {
      console.error(err);
      return true;
    }
  }

  
  //////////////////// GET USER DATA ////////////////////


  const getUserData = async (firebaseUser, localStorage_id, isUniqueAccount = true ) => {
    
    if ( !localStorage_id ) { localStorage.setItem('_id', firebaseUser.uid); }

    try {
      let result = await axios.put(`${API}/generalFindOneAndUpdate`, { 
        matchObj: { _id: firebaseUser.uid }, 
        updateObj: { $inc: { authCount: 1 }, $set: { authLastDate: Date.now() } },
        dbRef: dbRefs.users 
      })
      let results = result.data

      if ( results?._id ) {
        const userObj = { ...results, isMember: isActiveSubscription(results) };
        setUser(userObj);
        mixpanelIdentifyUser(userObj, MIXPANEL_EVENTS.user_signed_in)
        await cleanUserData(userObj, isUniqueAccount);
      }
      else {
        await createUser(firebaseUser, isUniqueAccount)
        console.log("new user: ", firebaseUser.uid)
      }
      
    }
    catch (err) {
      console.error(err);
      throw new Error("Failed to fetch user data"); // Propagate to handleAuthStateChange
    }
  }

  const createUser = async (firebaseUser, isUniqueAccount = true) => {
    const referralCode = generateReferralCode();
    const referralCodeUsed = localStorage.getItem(REFERRAL_CODE_USED_KEY) || '';
    const statusEffective = isUniqueAccount ? stripeSubscriptionStates.free_trial : stripeSubscriptionStates.duplicate_account;

    try {

      let authData = {};
      try {
        const authResponse = await fetch('https://ipapi.co/json/');
        authData = await authResponse.json();
      }
      catch (err) {
        console.error('Error fetching IP data:', err);
      }

      let userDoc = {
        _id: firebaseUser.uid,
        email: firebaseUser.email,
        emailVerified: firebaseUser.emailVerified,
        createdAt: firebaseUser.metadata.createdAt,
        categories: [],
        stripeSubscription: {
          status: statusEffective,
          plan: { }
        },
        referralCode: referralCode,
        referralCodeUsed: referralCodeUsed,
        emailSentCount: 0,
        termsAccepted: Date.now(),
        authCount: 1,
        authLastDate: Date.now(),
        authData: authData,
      }

      await Promise.all([
        axios.post(`${API}/generalInsertOne`, { doc: userDoc, dbRef: dbRefs.users }),
        sendSlackNotification(`New user created\nEmail: ${userDoc?.email}\nUnique: ${isUniqueAccount}`, SLACK_WEBHOOK_CHANNELS.signups),
      ]);

      setUser({ ...userDoc, isFirstTimeUser: true, isMember: false });
      mixpanelIdentifyUser(userDoc, MIXPANEL_EVENTS.user_created)
      localStorage.removeItem(REFERRAL_CODE_USED_KEY);
    }
    catch (err) {
      console.error(err);
      throw new Error("Failed to create user"); // Propagate to handleAuthStateChange
    }
  }

  const cleanUserData = async (userObj, ) => {
    if ( !userObj ) { return; }

    if ( !userObj.authData ) {

      try {
        const authResponse = await fetch('https://ipapi.co/json/');
        const authData = await authResponse.json();        
        await axios.put(`${API}/generalUpdateOne`, {
          matchObj: { _id: userObj._id },
          updateObj: { $set: { authData: authData } },
          dbRef: dbRefs.users,
        })
      }
      catch (err) {
        console.error('Error fetching IP data:', err);
      }
    }
  }


  const mixpanelIdentifyUser = useCallback((userObj, event = MIXPANEL_EVENTS.user_signed_in) => {
    mixpanel.identify(userObj._id);
    mixpanel.people.set({ $email: userObj?.email, $created: userObj?.createdAt, 'Subscription Status': userObj?.stripeSubscription?.status });
    mixpanel.track(event, { email: userObj?.email, createdAt: userObj?.createdAt });
  }, []);

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      setUser(null);
      mixpanel.reset()
    } 
    catch (error) {
      console.error(error);
    }
  }, [auth]);


  const checkUserPermission = async (trialCreditType = '') => {

    try {

      if ( !user ) { return false; }
    
      const userStatus = user?.stripeSubscription?.status;

      if ( user?.isMember ) {
        return true;
      }
      
      if ( userStatus === stripeSubscriptionStates.active ) {
        return true;
      }

      if ( userStatus === stripeSubscriptionStates.trialing) {
        return true;
      }

      if ( userStatus === stripeSubscriptionStates.free_trial ) {

        // let hasCredits = await checkCredits(user?._id, trialCreditType);
        let result = await axios.get(API + '/trialCreditsCheck', { params: { user_id: user?._id, type: trialCreditType }})
        let hasCredits = result?.data?.hasCredits
        console.log(result)

        if ( !hasCredits ) {
          let paywallType = PAYWALL_TYPES[trialCreditType];
          setShowPaywallType(paywallType);
        }
        
        return hasCredits;
      }

      if ( userStatus === stripeSubscriptionStates.duplicate_account ) {
        setShowPaywallType(PAYWALL_TYPES.DUPLICATE_ACCOUNT);
        return false;
      }

      alert(`You do not currently have access to use this feature. Your account status is: ${userStatus}. If this has to do with billing, please check your billing portal (via Options > Billing). If you believe this is an error, email ryan@schoolgoat.com for help.`)
      return false
    }
    catch (err) {
      console.error(err);
      alert("An error occurred. Please try refreshing the page and trying again. If this persists, contact us via the Options menu.")
      return false;
    }
  };

  return (
      <UserContext.Provider value={{ 
          user, setUser,
          userLoaded, setUserLoaded,
          showPaywallType, setShowPaywallType,
          checkUserPermission,
          logout,
          showCheckoutModal, setShowCheckoutModal,
        }}>
          {children}
      </UserContext.Provider>
  );
};










// const checkCredits = async (user_id, type) => {

//   // SOLVER
//   if ( type === PAYWALL_TYPES.solver ) {

//     const [totalResult, recentResult] = await Promise.all([
//       axios.get(`${API}/generalCount`, { params: { 
//         matchObj: JSON.stringify({ user_id: user_id }),
//         dbRef: dbRefs.messages
//       }}),
//       axios.get(`${API}/generalCount`, { params: { 
//         matchObj: JSON.stringify({ user_id: user_id, date: { $gte: new Date(Date.now() - 24 * 60 * 60 * 1000) } }),
//         dbRef: dbRefs.messages
//       }})
//     ]);

//     let totalCount = totalResult.data
//     let recentCount = recentResult.data

//     if ( totalCount < 0 ) {
//       return true;
//     }
//     else if ( recentCount < 0 ) {
//       return true;
//     }
//     return false;
//   }

//   // RESOURCES
//   else if ( type === PAYWALL_TYPES.resources ) {
//     let result = await axios.get(`${API}/generalCount`, { params: { 
//       matchObj: JSON.stringify({ user_id: user_id, uploadMethod: { $ne: RESOURCES_UPLOAD_METHODS.recording } }),
//       dbRef: dbRefs.resources
//     }})
//     let count = result.data
//     return count < 3;
//   }

//   // RESOURCES RECORDING
//   else if ( type === PAYWALL_TYPES.resources_recording ) {
//     let result = await axios.get(API + '/generalAggregation', { params: {
//       matchObj: JSON.stringify({ user_id: user_id, uploadMethod: RESOURCES_UPLOAD_METHODS.recording }),
//       projectObj: JSON.stringify({ _id: 0, date: 1, sources: 1 }),
//       dbRef: dbRefs.resources
//     }})
//     let results = result.data
//     let totalSources = results.reduce((acc, curr) => acc + (Array.isArray(curr.sources) ? curr.sources.length : 0), 0);
//     return totalSources < 12;
//   }

//   return true;
// }





    // // Check if it's a sign-in with email link operation
    // if (isSignInWithEmailLink(auth, window.location.href)) {
    //   let storedEmail = window.localStorage.getItem('emailForSignIn');
    //   if (!storedEmail) {
    //     storedEmail = window.prompt('Please provide your email for confirmation');
    //   }
  
    //   signInWithEmailLink(auth, storedEmail, window.location.href)
    //     .then((result) => {
    //       window.localStorage.removeItem('emailForSignIn');
    //     console.log(result)        
  
    //       if (result?._tokenResponse?.isNewUser) {
    //         createUser(result.user);
    //         console.log("new user: ", result.user.uid);
    //       } else {
    //         console.log("Existing user signing in: ", result.user.uid);
    //         getUserData(result.user);
    //       }
  
    //     })
    //     .catch((error) => {
    //       console.error("Error signing in: ", error.message);
    //     });
  
    //   // Return early as we don't need to set up onAuthStateChanged
    //   return;
    // }
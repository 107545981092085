import { format } from 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import { stripeSubscriptionStates } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';

const convertSubscriptionStatus = (status) => {
  if (status === stripeSubscriptionStates.active) {
    return "Paid member"
  } 
  else {
    return "Free trial"
  } 
}

const convertPaidStatus = (status) => {
  if (status === true) {
    return "Paid"
  } 
  else return "---"
}


const ReferralsComponent = ({ data, setData }) => {
  const { isDesktop } = useBreakpoint();
  const tableStyle = { width: '100%', borderCollapse: 'collapse' };
  const headerStyle = { padding: '1rem', fontSize: '1rem', color: 'var(--color-text3)', fontWeight: '600', borderBottom: '1px solid var(--color-separatorOpaque)', textAlign: 'center' };
  const infoHeaderStyle = { ...headerStyle, width: '40%', textAlign: 'left', padding: "20px" };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', minHeight: '400px' }}
      role="region"
      aria-label="Referrals list"
    >
      <table style={tableStyle} >
        <thead>
          <tr>
            <th scope="col" style={infoHeaderStyle}>Info</th>
            {isDesktop && <th scope="col" style={headerStyle}>Date</th>}
            <th scope="col" style={headerStyle}>Status</th>
            <th scope="col" style={headerStyle}>Payment</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item._id} style={{ borderBottom: index < data.length - 1 ? "1px solid #eee" : "none", fontSize: '16px', verticalAlign: 'middle' }}>
              <td style={{ textAlign: 'left', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px', fontSize: '1rem' }}>
                {item.email}
              </td>
              {isDesktop && 
                <td style={{ fontSize: '.9rem' }}>
                  {format(new Date(parseInt(item.createdAt)), "MMM d, yyyy")}
                </td>
              }
              <td>
                <span style={{ backgroundColor: 'var(--color-primaryLight)', color: 'var(--color-primary)', padding: '4px 8px', fontSize: '.9rem', borderRadius: '12px', fontWeight: '500' }} aria-label={`Status: ${convertSubscriptionStatus(item?.stripeSubscription?.status)}`}>
                  {convertSubscriptionStatus(item?.stripeSubscription?.status)}
                </span>
              </td>
              <td style={{ fontSize: '.9rem', textAlign: 'center' }} aria-label={`Payment: ${convertPaidStatus(item?.referralCodeUsedPaid)}`}>
                {convertPaidStatus(item?.referralCodeUsedPaid)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReferralsComponent;









        {/* <select style={{ marginRight: '10px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', fontSize: "1rem", color: `var(--color-text3)` }}
          value={typeFilter}
          onChange={(e) => setTypeFilter(e.target.value)}
          
        >
          <option value="">Type</option>
          <option value="Document">Document</option>
          <option value="Image">Image</option>
          <option value="Video">Video</option>
        </select> */}





